import React, { useContext } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
// @mui/icons-material
import Menu from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import { RootStoreContext } from "mobX/RootStore";

export default function Header(props) {
	const store = useContext(RootStoreContext);
	const { tenantStore } = store;

	const useStyles = makeStyles(
		styles({
			customPrimaryColor: tenantStore.primary1Color,
			customSecondaryColor: tenantStore.primary2Color,
		}),
	);

	const [mobileOpen, setMobileOpen] = React.useState(false);
	const classes = useStyles();
	React.useEffect(() => {
		if (props.changeColorOnScroll) {
			window.addEventListener("scroll", headerColorChange);
		}
		return function cleanup() {
			if (props.changeColorOnScroll) {
				window.removeEventListener("scroll", headerColorChange);
			}
		};
	});
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const headerColorChange = () => {
		const { color, changeColorOnScroll } = props;

		const windowsScrollTop = window.pageYOffset;
		if (
			windowsScrollTop > changeColorOnScroll.height &&
			document.body.getElementsByTagName("header")[0]?.classList
		) {
			document.body
				.getElementsByTagName("header")[0]
				.classList.remove(classes[color]);
			document.body
				.getElementsByTagName("header")[0]
				.classList.add(classes[changeColorOnScroll.color]);
		} else if (document.body.getElementsByTagName("header")[0]?.classList) {
			document.body
				.getElementsByTagName("header")[0]
				.classList.add(classes[color]);
			document.body
				.getElementsByTagName("header")[0]
				.classList.remove(classes[changeColorOnScroll.color]);
		}
	};
	const { color, links, brand, fixed, absolute } = props;
	const appBarClasses = classNames({
		[classes.appBar]: true,
		[classes[color]]: color,
		[classes.absolute]: absolute,
		[classes.fixed]: fixed,
	});
	return (
		<AppBar className={appBarClasses}>
			<Toolbar className={classes.container}>
				<Button className={classes.title}>
					<Link to="/">{brand}</Link>
				</Button>
				<Hidden lgDown implementation="css" className={classes.hidden}>
					<div className={classes.collapse}>{links}</div>
				</Hidden>
				<Hidden lgUp>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerToggle}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
			<Hidden lgUp implementation="js">
				<Drawer
					variant="temporary"
					anchor={"right"}
					open={mobileOpen}
					classes={{
						paper: classes.drawerPaper,
					}}
					onClose={handleDrawerToggle}
				>
					<IconButton
						color="inherit"
						aria-label="close drawer"
						onClick={handleDrawerToggle}
						className={classes.closeButtonDrawer}
					>
						<Close />
					</IconButton>
					<div className={classes.appResponsive}>{links}</div>
				</Drawer>
			</Hidden>
		</AppBar>
	);
}

Header.defaultProp = {
	color: "white",
};

Header.propTypes = {
	color: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"transparent",
		"white",
		"rose",
		"dark",
	]),
	links: PropTypes.node,
	brand: PropTypes.string,
	fixed: PropTypes.bool,
	absolute: PropTypes.bool,
	// this will cause the sidebar to change the color from
	// props.color (see above) to changeColorOnScroll.color
	// when the window.pageYOffset is heigher or equal to
	// changeColorOnScroll.height and then when it is smaller than
	// changeColorOnScroll.height change it back to
	// props.color (see above)
	changeColorOnScroll: PropTypes.shape({
		height: PropTypes.number.isRequired,
		color: PropTypes.oneOf([
			"primary",
			"info",
			"success",
			"warning",
			"danger",
			"transparent",
			"white",
			"rose",
			"dark",
		]).isRequired,
	}),
};
