import React from "react";

import withStyles from "@mui/styles/withStyles";
import AddIcon from "@mui/icons-material/Add";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Switch from "@mui/material/Switch";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";

// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";
import CustomInputAdapter from "../components/CustomInputAdapter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Field } from "react-final-form-html5-validation";
import arrayMutators from "final-form-arrays";
import FormControlLabel from "@mui/material/FormControlLabel";
import { validateEmail } from "../../../utils/Utils";

const style = (theme) => ({
	...modalStyle(theme),
	modalRootExample: {
		"& > div:first-child": {
			display: "none",
		},
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	removeButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
});

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

const SwitchAdapter = ({ input, label }) => (
	<FormControlLabel
		control={
			<Switch
				name={input.name}
				checked={input.value}
				onChange={input.onChange}
			/>
		}
		label={label}
	/>
);

class LocationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scrollingModal: false,
		};
	}

	onSubmit = async (values) => {
		const { createClub } = this.props;

		createClub(values);
		this.handleClose("scrollingModal");
	};

	handleClickOpen(modal) {
		const x = [];
		x[modal] = true;
		this.setState(x);
	}

	handleClose(modal) {
		const x = [];
		x[modal] = false;
		this.setState(x);
	}

	checkClubLimit = () => {
		const clubLimit = this.props.clubLimit;
		const count = this.props.clubCount;
		if (clubLimit === undefined) {
			return true;
		}
		if (clubLimit === null) {
			return false;
		}
		return count >= clubLimit;
	};

	render() {
		const { classes, location } = this.props;

		return (
			<div>
				{this.checkClubLimit() && (
					<Alert variant="filled" severity="error">
						<AlertTitle>Club-Limit erreicht</AlertTitle>
						{`Mit Deiner aktuellen Lizenz kannst Du höchstens ${this.props.clubLimit || 0} Vereine erstellen.`}
					</Alert>
				)}
				<Button
					color="primary"
					fullWidth
					onClick={() => this.handleClickOpen("scrollingModal")}
					disabled={this.checkClubLimit()}
				>
					<AddIcon /> Neuen Club erstellen
				</Button>
				{this.state.scrollingModal && (
					<Dialog
						classes={{
							root: `${classes.modalRoot} ${classes.modalRootExample}`,
							paper: `${classes.modal} ${classes.modalLarge}`,
						}}
						open={this.state.scrollingModal}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleClose("scrollingModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<Form
							onSubmit={this.onSubmit}
							initialValues={location}
							mutators={{ ...arrayMutators }}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								values,
							}) => (
								<form onSubmit={handleSubmit}>
									<DialogTitle
										id="classic-modal-slide-title"
										className={classes.modalHeader}
									>
										<Button
											simple
											className={classes.modalCloseButton}
											key="close"
											aria-label="Close"
											onClick={() => this.handleClose("scrollingModal")}
										>
											{" "}
											<Close className={classes.modalClose} />
										</Button>
										<h4 className={classes.modalTitle}>
											{values.name ? values.name : "Neuer Club"}
										</h4>
									</DialogTitle>
									<DialogContent
										id="classic-modal-slide-description"
										className={classes.modalBody}
									>
										<GridContainer>
											<GridItem xs={12} sm={12} md={12} lg={12}>
												<h4>Club</h4>
											</GridItem>
											<GridItem>
												<Field
													name="name"
													component={CustomInputAdapter}
													type="text"
													placeholder="Name"
													label="Name"
												/>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<Field
													name="username"
													component={CustomInputAdapter}
													type="text"
													placeholder="Benutzername"
													label="Benutzername"
													validate={(value) => !value && "Eingabe erforderlich"}
												/>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<Field
													name="email"
													component={CustomInputAdapter}
													type="text"
													placeholder="E-Mail"
													label="E-Mail"
													validate={(value) => {
														if (!value) {
															return "Eingabe erforderlich";
														} else if (!validateEmail(value)) {
															return "Ungültige E-Mail";
														}
													}}
												/>
											</GridItem>
										</GridContainer>
									</DialogContent>
									<DialogActions className={classes.modalFooter}>
										<Button
											onClick={form.reset}
											disabled={submitting || pristine}
											color="secondary"
										>
											Zurücksetzen
										</Button>
										<Button color="primary" type="submit" disabled={submitting}>
											Speichern
										</Button>
									</DialogActions>
								</form>
							)}
						/>
					</Dialog>
				)}
			</div>
		);
	}
}

LocationModal.propTypes = {
	classes: PropTypes.object,
	createClub: PropTypes.func,
	location: PropTypes.object,
};

SwitchAdapter.propTypes = {
	input: PropTypes.object,
	label: PropTypes.string,
};

CustomInputAdapter.propTypes = {
	input: PropTypes.object,
	inputProps: PropTypes.object,
	meta: PropTypes.object,
	rest: PropTypes.object,
	club: PropTypes.object,
};

export default withStyles(style)(LocationModal);
