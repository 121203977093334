import React, { useContext, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Danger from "components/Typography/Danger.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import { RootStoreContext } from "../../../mobX/RootStore";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

const eventNameForURL = (eventName) => {
	return eventName.replaceAll(" ", "-").toLowerCase();
};

const cardStyles = () => ({
	...blogsStyle,
	clamp8Lines: {
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 8,
		overflow: "hidden",
		margin: 0,
	},
	description1: {
		...blogsStyle.description1,
		color: "black",
	},
});

const useCardStyles = makeStyles(cardStyles);

const EventCard = observer(({ event, direction }) => {
	const store = useContext(RootStoreContext);
	const tenantStore = store.tenantStore;
	const classes = useCardStyles();

	return direction === "right" ? (
		<Card plain blog className={classes.card}>
			<Link
				to={`/${tenantStore.data.path}/events/${eventNameForURL(event.name)}?id=${event.id}`}
			>
				<GridContainer>
					<GridItem xs={12} sm={7} md={7}>
						{event.club && (
							<Danger>
								<h6 className={classes.cardCategory}>{event.club.name}</h6>
							</Danger>
						)}
						<h3 className={classes.cardTitle}>{event.name}</h3>
						<p
							className={classNames(classes.description1, classes.clamp8Lines)}
						>
							{event.description}
						</p>
						<p> Weitere Infos </p>
						<p className={classes.author}>
							{new Date(event.schedule.from).toLocaleDateString()} -{" "}
							{new Date(event.schedule.to).toLocaleDateString()}
						</p>
					</GridItem>
					<GridItem xs={12} sm={5} md={5}>
						<CardHeader image plain>
							<img src={event.image} alt="..." />
							<div
								className={classes.coloredShadow}
								style={{
									backgroundImage: `url(${event.image})`,
									opacity: "1",
								}}
							/>
						</CardHeader>
					</GridItem>
				</GridContainer>
			</Link>
		</Card>
	) : (
		<Card plain blog className={classes.card}>
			<Link
				to={`/${tenantStore.data.path}/events/${eventNameForURL(event.name)}?id=${event.id}`}
			>
				<GridContainer>
					<GridItem xs={12} sm={5} md={5}>
						<CardHeader image plain>
							<img src={event.image} alt="..." />
							<div
								className={classes.coloredShadow}
								style={{
									backgroundImage: `url(${event.image})`,
									opacity: "1",
								}}
							/>
						</CardHeader>
					</GridItem>
					<GridItem xs={12} sm={7} md={7}>
						{event.club && (
							<Danger>
								<h6 className={classes.cardCategory}>{event.club.name}</h6>
							</Danger>
						)}
						<h3 className={classes.cardTitle}>{event.name}</h3>
						<p
							className={classNames(classes.description1, classes.clamp8Lines)}
						>
							{event.description}
						</p>
						<p> Weitere Infos </p>
						<p className={classes.author}>
							{new Date(event.schedule.from).toLocaleDateString()} -{" "}
							{new Date(event.schedule.to).toLocaleDateString()}
						</p>
					</GridItem>
				</GridContainer>
			</Link>
		</Card>
	);
});

EventCard.defaultProps = {
	direction: "left",
};

EventCard.propTypes = {
	direction: PropTypes.oneOf(["left", "right"]),
};

const useStyles = makeStyles(blogsStyle);

const EventList = observer(({ ...rest }) => {
	const store = useContext(RootStoreContext);
	const eventStore = store.eventStore;

	useEffect(() => {
		eventStore.fetchEvents();
	}, [eventStore]);

	const classes = useStyles();

	const sortedEvents = [...eventStore.events].sort((a, b) => {
		if (a.highlight && !b.highlight) {
			return -1;
		} else if (!a.highlight && b.highlight) {
			return 1;
		}

		return new Date(a.schedule.from) - new Date(b.schedule.from);
	});

	return (
		<div className="cd-section" {...rest}>
			<div className={classes.blog}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={10}
							md={10}
							className={classes.mlAuto + " " + classes.mrAuto}
						>
							<h2 className={classes.title}>Veranstaltungen</h2>

							<br />
							{sortedEvents.map((event, index) => (
								<EventCard
									key={event.id}
									event={event}
									direction={index % 2 === 0 ? "left" : "right"}
								/>
							))}
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
});

export default EventList;
