import { makeAutoObservable, runInAction } from "mobx";
import { buildImageKey, getFilteredOfferings } from "../utils/Utils";
import messages from "../utils/messages";
import { getUrl, uploadData, remove } from "aws-amplify/storage";

export class OfferingStore {
	rootStore;
	transportLayer;
	locationStore;
	contactStore;
	offerings = [];
	nextToken = null;
	isLoading = null;

	constructor(rootStore, transportLayer, locationStore, contactStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
		this.transportLayer = transportLayer;
		this.locationStore = locationStore;
		this.contactStore = contactStore;
	}

	updateOfferingFromServer(json, club) {
		const offering = this.offerings.find(
			(offeringInStore) => offeringInStore.id === json.id,
		);
		if (offering) {
			return offering.updateOfferingFromServer(json, club);
		} else {
			const newOffering = new Offering(this, json, club);
			this.offerings.push(newOffering);
			return newOffering;
		}
	}

	removeOfferingFromStore(id) {
		const index = this.offerings.findIndex(
			(offeringInStore) => offeringInStore.id === id,
		);
		if (index > -1) this.offerings.splice(index, 1);
	}

	fetchOffering(id) {
		this.isLoading = true;
		this.transportLayer.fetchOffering(id).then((fetchedOffering) => {
			runInAction(() => {
				if (fetchedOffering) {
					this.updateOfferingFromServer(fetchedOffering);
				}
				runInAction(() => {
					this.isLoading = false;
				});
			});
		});
	}

	fetchOfferings() {
		this.isLoading = true;

		const filter = {
			tenantAdminGroup: { beginsWith: this.rootStore.tenantStore.tenantKey },
		};
		this.transportLayer
			.fetchAllOfferings(undefined, undefined, filter)
			.then((res) => {
				runInAction(() => {
					if (res)
						res.forEach((offering) => {
							this.updateOfferingFromServer(offering);
						});
					this.isLoading = false;
				});
			});
	}

	fetchOfferingsWithLimit(limit) {
		this.isLoading = true;

		const filter = {
			tenantAdminGroup: { beginsWith: this.rootStore.tenantStore.tenantKey },
		};
		this.transportLayer
			.fetchOfferings(limit, this.nextToken, filter)
			.then((res) => {
				runInAction(() => {
					if (res.listOfferings.items)
						res.listOfferings.items.forEach((offering) => {
							this.updateOfferingFromServer(offering);
						});
					this.nextToken = res.listOfferings.nextToken;
					this.isLoading = false;
				});
			});
	}

	fetchCategories() {
		this.isLoading = true;

		const filter = {
			tenantAdminGroup: { beginsWith: this.rootStore.tenantStore.tenantKey },
		};
		this.transportLayer
			.fetchOfferingCategories(undefined, undefined, filter)
			.then((res) => {
				runInAction(() => {
					if (res)
						res.forEach((offering) => {
							this.updateOfferingFromServer(offering);
						});
					this.isLoading = false;
				});
			});
	}

	async updateOffering(offering, imageFile) {
		try {
			if (imageFile) {
				if (offering.image) {
					const imageKey = offering.image.slice(
						offering.image.indexOf("offeringImages"),
					);
					await remove({
						key: imageKey,
						options: {
							accessLevel: "protected",
						},
					});
				}

				const ext = imageFile.name.split(".").pop();
				const path = `offeringImages/${buildImageKey(imageFile)}.${ext}`;

				const result = await uploadData({
					key: path,
					data: imageFile,
					options: {
						accessLevel: "protected",
					},
				}).result;

				if (result.key) {
					const urlResult = await getUrl({
						key: result.key,
						options: {
							accessLevel: "protected",
						},
					});
					const fullUrl = urlResult.url.toString();
					offering.image = fullUrl.split("?")[0];
				}
			}

			const response = await this.transportLayer.updateOffering(offering);
			if (response.data.updateOffering) {
				const updatedOffering = response.data.updateOffering;
				this.updateOfferingFromServer(updatedOffering);

				this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	}

	async offeringBulkUpdate(offerings) {
		try {
			for (const offering of offerings) {
				if (offering.contact)
					offering["offeringContactId"] = offering.contact.id;
				if (offering.location)
					offering["offeringLocationId"] = offering.location.id;

				await this.updateOffering(offering);
			}
			this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	}

	async createOffering(
		offering,
		clubId,
		tenantAdminGroup,
		clubAdminGroup,
		imageFile,
	) {
		try {
			if (imageFile) {
				const ext = imageFile.name.split(".").pop();
				const path = `offeringImages/${buildImageKey(imageFile)}.${ext}`;

				const result = await uploadData({
					key: path,
					data: imageFile,
					options: {
						accessLevel: "protected",
					},
				}).result;

				if (result.key) {
					const urlResult = await getUrl({
						key: result.key,
						options: {
							accessLevel: "protected",
						},
					});
					const fullUrl = urlResult.url.toString();
					offering.image = fullUrl.split("?")[0];
				}
			}

			const response = await this.transportLayer.createOffering(
				offering,
				clubId,
				tenantAdminGroup,
				clubAdminGroup,
			);
			if (response.data.createOffering) {
				const newOffering = this.updateOfferingFromServer(
					response.data.createOffering,
				);
				//this.offerings.push(newOffering);
				const club = this.rootStore.clubStore.getClubById(clubId);
				club.addOffering(newOffering);
				this.rootStore.UIStore.setSuccessMessage(
					messages.CREATE_OFFERING_SUCCESS,
				);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_OFFERING_FAILURE);
		}
	}

	async deleteOffering(clubId, offeringId) {
		try {
			const response = await this.transportLayer.deleteOffering(offeringId);

			if (response.data.deleteOffering) {
				const offeringInStore = this.getOfferingById(offeringId);
				offeringInStore.removeFromStore();
				this.rootStore.UIStore.setSuccessMessage(messages.DELETE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.DELETE_FAILURE);
		}
	}

	getOfferingById = (id) => {
		return this.offerings.find((offering) => offering.id === id);
	};

	get validOfferings() {
		return this.offerings.filter((offering) => {
			return offering.title && offering.category && offering.clubLogo;
		});
	}

	get validCategories() {
		const categorySet = new Set();
		this.validOfferings.forEach(({ category }) => categorySet.add(category));
		const categories = [];
		categorySet.forEach((category) => categories.push(category));
		return categories.sort();
	}

	filteredOfferings(filter) {
		return getFilteredOfferings(filter, this.validOfferings);
	}

	get filteredCategories() {
		const categorySet = new Set();
		this.filteredOfferings.forEach(({ category }) => categorySet.add(category));
		const categories = [];
		categorySet.forEach((category) => categories.push(category));
		return categories.sort();
	}
}

export class Offering {
	store;
	club;
	id;
	clubID = null;
	tenantAdminGroup = null;
	clubAdminGroup = null;
	title = null;
	category = null;
	district = null;
	description = null;
	descriptionAsHtml = null;
	targetGroup = null;
	schedules = null;
	costs = null;
	hints = null;
	image = null;
	contact = null;
	location = null;
	clubLogo = null;
	clubLogoURI = null;
	clubName = null;
	type = "TRAINING";
	createdAt = null;

	constructor(store, offering, club) {
		makeAutoObservable(this);

		this.store = store;
		this.id = offering.id;
		this.updateOfferingFromServer(offering, club);
	}

	get asJson() {
		return {
			id: this.id,
			tenantAdminGroup: this.tenantAdminGroup,
			clubAdminGroup: this.clubAdminGroup,
			title: this.title,
			category: this.category,
			district: this.district,
			description: this.description,
			descriptionAsHtml: this.descriptionAsHtml,
			targetGroup: this.targetGroup,
			schedules: this.schedules,
			costs: this.costs,
			hints: this.hints,
			image: this.image,
			contact: this.contact ? this.contact.asJson : null,
			location: this.location ? this.location.asJson : null,
			type: this.type,
			createdAt: this.createdAt,
		};
	}

	//TODO USE ONLY OFFERING.CLUB
	updateOfferingFromServer(offering, club) {
		if (club) this.club = club;
		if (offering.clubID) this.clubID = offering.clubID;
		if (offering.tenantAdminGroup)
			this.tenantAdminGroup = offering.tenantAdminGroup;
		if (offering.clubAdminGroup) this.clubAdminGroup = offering.clubAdminGroup;
		if (offering.title) this.title = offering.title;
		if (offering.category) this.category = offering.category;
		if (offering.district) this.district = offering.district;
		if (offering.description) this.description = offering.description;
		if (offering.descriptionAsHtml)
			this.descriptionAsHtml = offering.descriptionAsHtml;
		if (offering.targetGroup) this.targetGroup = offering.targetGroup;
		if (offering.schedules) this.schedules = offering.schedules;
		if (offering.costs) this.costs = offering.costs;
		if (offering.hints) this.hints = offering.hints;
		if (offering.image) this.image = offering.image;
		if (offering.createdAt) this.createdAt = offering.createdAt;
		if (offering.contact) {
			this.contact = this.store.contactStore.updateContactFromServer(
				offering.contact,
			);
		}
		if (offering.location) {
			this.location = this.store.locationStore.updateLocationFromServer(
				offering.location,
			);
		}

		if (offering.club) {
			if (offering.club.logo) this.clubLogo = offering.club.logo;
			if (offering.club.name) this.clubName = offering.club.name;
		}
		return this;
	}

	removeFromStore() {
		if (this.club) this.club.removeOfferingFromClub(this.id);
		this.store.removeOfferingFromStore(this.id);
	}
}
