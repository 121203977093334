import { MapView } from "@aws-amplify/ui-react-geo";
import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Marker } from "react-map-gl";
import "@aws-amplify/ui-react-geo/styles.css";


const MapComponent = observer(({ lng, setLng, lat, setLat, fixedMarker}) => {
	const [zoom, setZoom] = useState(17);
	const [initialLat, setInitialLat] = useState()
	const [initialLng, setInitialLng] = useState()

	const handleMarkerDragEnd = (event) => {
		setLat(event.lngLat.lat)
		setLng(event.lngLat.lng)
	};

	const handleOnMove = (evt) => {
		setLat(evt.viewState.latitude)
		setLng(evt.viewState.longitude)
		setZoom(evt.viewState.zoom);
	};

	const handleClick = (evt) => {
		setLat(evt.lngLat.lat)
		setLng(evt.lngLat.lng)
	};

	useEffect(() => {
		if(lng && lat && !initialLat && !initialLng) {
			setInitialLat(lat)
			setInitialLng(lng)
		}
	}, [lng, lat])

	return (
		<MapView
			style={{ width: "auto", height: "400px" }}
			latitude={lat}
			longitude={lng}
			zoom={zoom}
			onMove={handleOnMove}
			onClick={handleClick}
		>
				<Marker
					latitude={fixedMarker ? initialLat : lat}
					longitude={fixedMarker ? initialLng : lng}
					onDragEnd={handleMarkerDragEnd}
					draggable
				/>
		</MapView>
	);
});

export default MapComponent;
