import React from "react";

import withStyles from "@mui/styles/withStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// custom components
import CustomInputAdapter from "../components/CustomInputAdapter";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import FieldAdapter from "../components/FieldAdapter";

const style = (theme) => ({
	...modalStyle(theme),
	modalRootExample: {
		"& > div:first-child": {
			display: "none",
		},
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	removeButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
});

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

class ContactModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scrollingModal: false,
		};
	}

	onSubmit = async (values) => {
		const { updateContact, newContact, createContact } = this.props;

		if (newContact) {
			createContact(values);
			this.handleClose("scrollingModal");
		} else {
			updateContact(values);
			this.handleClose("scrollingModal");
		}
	};

	handleClickOpen(modal) {
		const x = [];
		x[modal] = true;
		this.setState(x);
	}

	handleClose(modal) {
		const x = [];
		x[modal] = false;
		this.setState(x);
	}

	render() {
		const { classes, contact, button } = this.props;

		return (
			<div>
				{button(() => {
					this.handleClickOpen("scrollingModal");
				})}
				{this.state.scrollingModal && (
					<Dialog
						classes={{
							root: `${classes.modalRoot} ${classes.modalRootExample}`,
							paper: `${classes.modal} ${classes.modalLarge}`,
						}}
						open={this.state.scrollingModal}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleClose("scrollingModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<Form
							onSubmit={this.onSubmit}
							initialValues={contact}
							mutators={{ ...arrayMutators }}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								values,
							}) => (
								<form onSubmit={handleSubmit}>
									<DialogTitle
										id="classic-modal-slide-title"
										className={classes.modalHeader}
									>
										<Button
											simple
											className={classes.modalCloseButton}
											key="close"
											aria-label="Close"
											onClick={() => this.handleClose("scrollingModal")}
										>
											{" "}
											<Close className={classes.modalClose} />
										</Button>
										<h4 className={classes.modalTitle}>
											{values.firstName || values.lastName
												? `${values.firstName ? values.firstName : ""}
                        ${values.lastName ? values.lastName : ""}`
												: "Neuer Kontakt"}
										</h4>
									</DialogTitle>
									<DialogContent
										id="classic-modal-slide-description"
										className={classes.modalBody}
									>
										<GridContainer>
											<GridItem xs={12} sm={12} md={12} lg={12}>
												<h4>Kontakt</h4>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<FieldAdapter
													name="firstName"
													component={CustomInputAdapter}
													type="text"
													placeholder="Vorname"
													label="Vorname"
												/>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<FieldAdapter
													name="lastName"
													component={CustomInputAdapter}
													type="text"
													placeholder="Nachname"
													label="Nachname"
													validate={(value) => !value && "Eingabe erforderlich"}
												/>
											</GridItem>
											<GridItem>
												<FieldAdapter
													name="email"
													component={CustomInputAdapter}
													type="text"
													placeholder="E-Mail"
													label="E-Mail"
													validate={(value) => !value && "Eingabe erforderlich"}
												/>
											</GridItem>
											<GridItem>
												<FieldAdapter
													name="phone"
													component={CustomInputAdapter}
													type="text"
													placeholder="Telefon"
													label="Telefon"
												/>
											</GridItem>
										</GridContainer>
									</DialogContent>
									<DialogActions className={classes.modalFooter}>
										<Button
											onClick={form.reset}
											disabled={submitting || pristine}
											color="secondary"
										>
											Zurücksetzen
										</Button>
										<Button color="primary" type="submit" disabled={submitting}>
											Speichern
										</Button>
									</DialogActions>
								</form>
							)}
						/>
					</Dialog>
				)}
			</div>
		);
	}
}

ContactModal.propTypes = {
	classes: PropTypes.object,
	updateContact: PropTypes.func,
	createContact: PropTypes.func,
	contact: PropTypes.object,
	newContact: PropTypes.bool,
	button: PropTypes.func,
};

export default withStyles(style)(ContactModal);
