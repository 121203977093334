import React, { useContext, useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

// core components
import Apps from "@mui/icons-material/Apps";
import Build from "@mui/icons-material/Build";
import Shield from "@mui/icons-material/Security";
import Sports from "@mui/icons-material/SportsHandball";

import Button from "components/CustomButtons/Button.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

import { RootStoreContext } from "../../mobX/RootStore";
import MultiColumnDropdown from "../MultiColumnDropdown/MultiColumnDropdown";
import { observer } from "mobx-react";
import { getClubURI } from "../../utils/Utils";
import { getEventNameForURL } from "./SportappHeaderUtils";

import { fetchAuthSession, signOut } from "aws-amplify/auth";

const SportappHeaderLinks = observer((props) => {
	const store = useContext(RootStoreContext);
	const [isTenant, setIsTenant] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const {
		tenantStore: { data: tenant },
	} = store;

	const getCategories = () => {
		const offeringCategories = store.offeringStore.validCategories.map(
			(category) => ({
				category,
			}),
		);
		const courseCategories = store.courseStore.validCategories.map(
			(category) => ({
				category,
			}),
		);
		const allCategories = [...offeringCategories, ...courseCategories]
			.filter(
				(value, index, array) =>
					array.findIndex((element) => element.category === value.category) ===
					index,
			)
			.sort((a, b) => a.category.localeCompare(b.category));
		return [{ category: "Alle", highlighted: true }, ...allCategories];
	};

	const clubs = [
		{ name: "Alle", id: "", highlighted: true },
		...store.clubStore.filteredClubs,
	];
	const categories = getCategories();

	const events = store.eventStore.events;
	const highlightedEvents = events.filter((event) => event.highlight);

	useEffect(() => {
		store.clubStore.fetchClubNames();
		store.offeringStore.fetchCategories();
		store.courseStore.fetchCategories();
		store.eventStore.fetchEventNames();
	}, []);

	useEffect(async () => {
		try {
			const authSession = await fetchAuthSession();
			if (authSession?.tokens?.accessToken) {
				setIsLoggedIn(true);
			}
			if (
				authSession?.tokens?.accessToken?.payload["cognito:groups"]?.find(
					(group) => group === `${tenant.key}-Admins`,
				)
			) {
				setIsTenant(true);
			}
		} catch (error) {
			setIsLoggedIn(false);
			console.log(error);
		}
	}, []);

	const { dropdownHoverColor } = props;
	const classes = useStyles();

	return (
		<div className={classes.collapse}>
			<List className={classes.list + " " + classes.mlAuto}>
				{highlightedEvents.map((event) => (
					<ListItem key={event.id} className={classes.listItem}>
						<Button
							href={`/${tenant.path}/events/${getEventNameForURL(event.name)}?id=${event.id}`}
							color={window.innerWidth < 960 ? "info" : "info"}
							className={classes.navButton}
							round
						>
							{event.icon && <i className={`${event.icon} ${classes.icons}`} />}
							{event.name}
						</Button>
					</ListItem>
				))}

				<ListItem className={classes.listItem}>
					<MultiColumnDropdown
						buttonText="Aktivitäten"
						buttonIcon={Sports}
						buttonProps={{
							className: classes.navLink,
							color: "transparent",
						}}
						ratio={3 / 10}
						keyExtractor={(item) => item.category}
						itemToDisplay={(item) => item.category}
						linkTo={(item) =>
							item.category === "Alle"
								? `/${tenant.path}/activities`
								: `/${
										tenant.path
									}/activities/filter&searchText=${encodeURIComponent(
										item.category,
									)}`
						}
						data={categories}
						navDropdown
					/>
				</ListItem>

				<ListItem className={classes.listItem}>
					<MultiColumnDropdown
						buttonText="Vereine"
						key="Vereine"
						buttonIcon={Shield}
						buttonProps={{
							className: classes.navLink,
							color: "transparent",
						}}
						ratio={3 / 10}
						keyExtractor={(item) => item.id}
						itemToDisplay={(item) => item.name}
						linkTo={(club) =>
							club.name === "Alle"
								? `/${tenant.path}/clubs`
								: getClubURI(club.name, club.id, tenant.path)
						}
						data={clubs}
						navDropdown
					/>
				</ListItem>

				<ListItem className={classes.listItem}>
					<Link to={`/${tenant.path}/events`} style={{ color: "inherit" }}>
						<Button className={classes.navLink} color="transparent">
							Veranstaltungen
						</Button>
					</Link>
				</ListItem>

				{isLoggedIn && (
					<ListItem className={classes.listItem}>
						<CustomDropdown
							noLiPadding
							navDropdown
							hoverColor={dropdownHoverColor}
							buttonText="Administration"
							buttonProps={{
								className: classes.navLink,
								color: "transparent",
							}}
							buttonIcon={Build}
							dropdownList={
								isTenant
									? [
											<Link
												key={"dashboard"}
												to={`/${tenant.path}/admin/dashboard`}
												className={classes.dropdownLink}
											>
												<Apps className={classes.dropdownIcons} /> Dashboard
											</Link>,
										]
									: [
											<Link
												key={"myclub"}
												to={`/${tenant.path}/admin/dashboard`}
												className={classes.dropdownLink}
											>
												<Apps className={classes.dropdownIcons} /> Mein Verein
											</Link>,
										]
							}
						/>
					</ListItem>
				)}

				<ListItem className={classes.listItem}>
					{isLoggedIn ? (
						<Button
							href="/"
							onClick={() => {
								signOut();
							}}
							color={window.innerWidth < 960 ? "danger" : "danger"}
							className={classes.navButton}
							round
						>
							<i className={`fas fa-sign-out-alt ${classes.icons}`} /> Abmelden
						</Button>
					) : (
						<Button
							href={`/${tenant.path}/admin/dashboard`}
							color={window.innerWidth < 960 ? "info" : "white"}
							className={classes.navButton}
							round
						>
							<i className={`fas fa-sign-in-alt ${classes.icons}`} /> Mein
							Verein
						</Button>
					)}
				</ListItem>
			</List>
		</div>
	);
});

SportappHeaderLinks.defaultProps = {
	hoverColor: "primary",
};

SportappHeaderLinks.propTypes = {
	dropdownHoverColor: PropTypes.oneOf([
		"dark",
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
	]),
};

export default SportappHeaderLinks;
