import React, { useEffect, useState, useRef } from "react";

import withStyles from "@mui/styles/withStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// @mui/icons-material
import Close from "@mui/icons-material/Close";

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// custom components
import CustomInputAdapter from "../components/CustomInputAdapter";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import PropTypes from "prop-types";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import FieldAdapter from "../components/FieldAdapter";
import { getLocationAsGeoData } from "../../../utils/Utils";
import { observer } from "mobx-react";
import MapComponent from "../../Map/Map";

const style = (theme) => ({
	...modalStyle(theme),
	modalRootExample: {
		"& > div:first-child": {
			display: "none",
		},
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	removeButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
});
// eslint-disable-next-line react/display-name

const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

const haveValuesChanged = (prevValues, currentValues) => {
	const { name: prevName, ...restPrev } = prevValues;
	const { name: currentName, ...restCurrent } = currentValues;
	return JSON.stringify(restPrev) !== JSON.stringify(restCurrent);
  };

const LocationModal = observer(
	({ classes, location, button, onSubmit: propsOnSubmit }) => {
		const [open, setOpen] = useState(false);
		const isInitialMount = useRef(true);
		const [previousValues, setPreviousValues] = useState({});

		const [lng, setLng] = useState(7.08844)
		const [lat, setLat] = useState(51.16676)

		useEffect(() => {
			if (location?.geoCoordinates?.latitude && location?.geoCoordinates?.longitude) {
				setLng(location?.geoCoordinates?.longitude);
				setLat(location?.geoCoordinates?.latitude);
			}
		}, [location]);

		const handleSubmit = async (values) => {
			if (propsOnSubmit) {
				values.geoCoordinates = values.geoCoordinates || {}
				values.geoCoordinates.latitude = lat
				values.geoCoordinates.longitude = lng
				values.street = values.street || ""
				values.zipCode = values.zipCode || ""
				values.houseNumber = values.houseNumber || ""
				await propsOnSubmit({ ...values });
				handleClose();
			}
		};

		const handleClickOpen = () => {
			setOpen(true);
		};

		const handleClose = () => {
			isInitialMount.current = true
			setOpen(false);
		};

		return (
			<div>
				{button(handleClickOpen)}
				{open && (
					<Dialog
						classes={{
							root: `${classes.modalRoot} ${classes.modalRootExample}`,
							paper: `${classes.modal} ${classes.modalLarge}`,
						}}
						open={open}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleClose}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<Form
							onSubmit={handleSubmit}
							initialValues={location}
							mutators={{ ...arrayMutators }}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								values,
							}) => (
								<form onSubmit={handleSubmit}>
									<FormSpy
										subscription={{ values: true }}
										onChange={async ({ values }) => {
											console.log(!isInitialMount.current)
											if (!isInitialMount.current) {
												console.log(haveValuesChanged(previousValues, values))
												if (haveValuesChanged(previousValues, values)) {
													const geoData = await getLocationAsGeoData(values);
													if (geoData?.latitude && geoData?.longitude) {
														setLat(geoData.latitude);
														setLng(geoData.longitude);
													}
													setPreviousValues(values);
												}
											} else {
												isInitialMount.current = false;
												setPreviousValues(values);
											}
										}}
									/>
									<DialogTitle
										id="classic-modal-slide-title"
										className={classes.modalHeader}
									>
										<Button
											simple
											className={classes.modalCloseButton}
											key="close"
											aria-label="Close"
											onClick={handleClose}
										>
											<Close className={classes.modalClose} />
										</Button>
										<h4 className={classes.modalTitle}>
											{values.name ? values.name : "Neue Sportstätte"}
										</h4>
									</DialogTitle>
									<DialogContent
										id="classic-modal-slide-description"
										className={classes.modalBody}
									>
										<GridContainer>
											<GridItem xs={12} sm={12} md={12} lg={12}>
												<h4>Sportstätte</h4>
											</GridItem>
											<GridItem xs={12} sm={12} md={12} lg={12}>
												<FieldAdapter
													name="name"
													component={CustomInputAdapter}
													type="text"
													placeholder="Name"
													label="Name"
													validate={(value) => !value && "Eingabe erforderlich"}
												/>
											</GridItem>
											<GridItem xs={12} sm={8} md={8} lg={8}>
												<FieldAdapter
													name="street"
													component={CustomInputAdapter}
													type="text"
													placeholder="Straße"
													label="Straße"
												/>
											</GridItem>
											<GridItem xs={12} sm={4} md={4} lg={4}>
												<FieldAdapter
													name="houseNumber"
													component={CustomInputAdapter}
													type="text"
													placeholder="Hausnummer"
													label="Hausnummer"
												/>
											</GridItem>
											<GridItem xs={12} sm={4} md={4} lg={4}>
												<FieldAdapter
													name="zipCode"
													component={CustomInputAdapter}
													type="text"
													placeholder="Postleitzahl"
													label="Postleitzahl"
												/>
											</GridItem>
											<GridItem xs={12} sm={8} md={8} lg={8}>
												<FieldAdapter
													name="city"
													component={CustomInputAdapter}
													type="text"
													placeholder="Ort"
													label="Ort"
												/>
											</GridItem>
											<GridItem>
												<MapComponent
													lng={lng}
													lat={lat}
													setLat={setLat}
													setLng={setLng}
												/>
											</GridItem>
										</GridContainer>
									</DialogContent>
									<DialogActions className={classes.modalFooter}>
										<Button
											onClick={form.reset}
											disabled={submitting || pristine}
											color="secondary"
										>
											Zurücksetzen
										</Button>
										<Button color="primary" type="submit" disabled={submitting}>
											Speichern
										</Button>
									</DialogActions>
								</form>
							)}
						/>
					</Dialog>
				)}
			</div>
		);
	},
);

LocationModal.propTypes = {
	classes: PropTypes.object,
	onSubmit: PropTypes.func,
	location: PropTypes.object,
	button: PropTypes.func,
};

export default withStyles(style)(LocationModal);
