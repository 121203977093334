/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withAuthenticator, translations } from "@aws-amplify/ui-react";
import NotFoundPage from "containers/NotFoundPage/Loadable";
import ClubsPage from "containers/ClubsPage";
import ActivitiesPage from "containers/ActivitiesPage";
import MyClub from "../MyClub";
import Dashboard from "../Dashboard";
import ClubDetails from "../ClubDetails";
import OfferingDetails from "../OfferingDetails";
import Imprint from "../Imprint";
import Policy from "../Policy";
import Widget from "../Widget/Widget";

import { I18n } from "aws-amplify/utils";
import EventDetails from "containers/EventDetails/EventDetails";
import CourseDetails from "containers/CourseDetails";
import EventsPage from "containers/EventsPage";
import InstallAppDialog from "../../customComponents/InstallAppDialog";
import { observer } from "mobx-react";
import { RootStoreContext } from "mobX/RootStore";
import {
	ThemeProvider,
	StyledEngineProvider,
	createTheme,
} from "@mui/material/styles";

const theme = createTheme();

I18n.putVocabularies(translations);

const App = observer(() => {
	const store = useContext(RootStoreContext);

	const {
		tenantStore: { data: tenant },
	} = store;

	useEffect(() => {
		document.title = `Ich trainiere in ${tenant.title}`;
		store.tenantStore.fetchTenantSupport();
	}, []);

	useEffect(() => {
		if (
			// location.hostname === "localhost" || // just for testing purposes on localhost
			location.hostname === "sportapp.solingersport.de"
		) {
			location.replace(`https://ich-trainiere.in${location.pathname}`);
		}
	}, []);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<div>
					<Switch>
						<Redirect
							exact
							from={`/${tenant.path}`}
							to={`/${tenant.path}/clubs`}
						/>
						<Route exact path="/">
							<Redirect
								to={{
									pathname: `${tenant.path}/clubs`,
								}}
							/>
						</Route>

						<Route
							path={[
								"/clubs",
								"/activities",
								"/events",
								"/course",
								"/offering",
							].map((path) => `/${tenant.path}${path}`)}
						>
							<InstallAppDialog />
							<Route
								exact
								path={`/${tenant.path}/clubs`}
								component={ClubsPage}
							/>
							<Route
								exact
								path={`/${tenant.path}/clubs/:nameWithId`}
								component={ClubDetails}
							/>

							<Route
								exact
								path={["/activities", "/activities/:filter"].map(
									(path) => `/${tenant.path}${path}`,
								)}
								component={ActivitiesPage}
							/>

							<Route
								exact
								path={`/${tenant.path}/offering/:nameWithId`}
								component={OfferingDetails}
							/>
							<Route
								exact
								path={`/${tenant.path}/course/:nameWithId`}
								component={CourseDetails}
							/>

							<Route
								exact
								path={`/${tenant.path}/events`}
								component={EventsPage}
							/>
							<Route
								exact
								path={`/${tenant.path}/events/:eventName`}
								component={EventDetails}
							/>
						</Route>

						<Route path={`/${tenant.path}/admin`}>
							<Route
								exact
								path={`/${tenant.path}/admin/dashboard`}
								component={withAuthenticator(Dashboard, {
									initialAuthState: "signin",
									hideSignUp: "true",
									backgroundColor: "#ff0000",
									style: {
										//https://docs.amplify.aws/ui/customization/theming/q/framework/react/
										"--amplify-font-family": "Fira Sans",
										"--amplify-primary-color": "rgb(38, 111, 170)", // $palette-sgblue-500
										"--amplify-primary-contrast": "white",
										"--amplify-primary-tint": "rgb(128, 171, 205)", // $palette-sgblue-300
										"--amplify-primary-shade": "rgb(0, 79, 147)", // $palette-sgblue-700

										"--amplify-secondary-color": "rgb(38, 111, 170)", // $palette-sgblue-500
										"--amplify-secondary-contrast": "white",
										"--amplify-secondary-tint": "rgb(128, 171, 205)", // $palette-sgblue-300
										"--amplify-secondary-shade": "rgb(0, 79, 147)", // $palette-sgblue-700

										"--amplify-tertiary-color": "rgb(251, 192, 45)", // $palette-sgyellow-500
										"--amplify-tertiary-contrast": "white",
										"--amplify-tertiary-tint": "rgb(252, 211, 108)", // $palette-sgyellow-300
										"--amplify-tertiary-shade": "rgb(250, 178, 34)", // $palette-sgyellow-700
									},
								})}
							/>
							<Route
								exact
								path={`/${tenant.path}/admin/club`}
								component={withAuthenticator(MyClub)}
							/>
						</Route>
						<Route exact path="/impressum" component={Imprint} />
						<Route exact path="/datenschutz" component={Policy} />
						<Route
							exact
							path={`/${tenant.path}/widget/:filter`}
							component={Widget}
						/>
						<Route component={NotFoundPage} />
					</Switch>
				</div>
			</ThemeProvider>
		</StyledEngineProvider>
	);
});

export default App;
