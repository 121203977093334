export default {
	UPDATE_SUCCESS: "Änderungen erfolgreich gespeichert!",
	UPDATE_FAILURE: "Änderungen konnten nicht gespeichert werden!",
	DELETE_SUCCESS: "Daten erfolgreich gelöscht!",
	DELETE_FAILURE: "Daten konnten nicht gelöscht werden!",
	CREATE_CLUB_SUCCESS: "Club erfolgreich erstellt!",
	CREATE_CLUB_FAILURE: "Club konnte nicht erstellt werden!",
	CREATE_OFFERING_SUCCESS: "Angebot erfolgreich erstellt!",
	CREATE_OFFERING_FAILURE: "Angebot konnte nicht erstellt werden!",
	CREATE_COURSE_SUCCESS: "Kurs erfolgreich erstellt!",
	CREATE_COURSE_FAILURE: "Kurs konnte nicht erstellt werden!",
	CREATE_LOCATION_SUCCESS: "Sportstätte erfolgreich erstellt!",
	CREATE_LOCATION_FAILURE: "Sportstätte konnte nicht erstellt werden!",
	CREATE_CONTACT_SUCCESS: "Kontakt erfolgreich erstellt!",
	CREATE_CONTACT_FAILURE: "Kontakt konnte nicht erstellt werden!",
	CREATE_EVENT_SUCCESS: "Veranstaltung erfolgreich erstellt!",
	CREATE_EVENT_FAILURE: "Veranstaltung konnte nicht erstellt werden!",
	FETCH_FAILURE: "Daten konnten nicht geladen werden!",
	UPDATE_ADMIN_SUCCESS: "Administrator erfolgreich bearbeitet!",
	UPDATE_ADMIN_FAILURE: "Administrator konnte nicht bearbeitet werden!",
	CREATE_ADMIN_SUCCESS: "Administrator erfolgreich erstellt!",
	CREATE_ADMIN_FAILURE: "Administrator konnte nicht erstellt werden!",
	RESEND_INVITATION_SUCCESS: "Einladung verschickt!",
	RESEND_INVITATION_FAILURE: "Einladung konnte nicht verschickt werden!",
};
