import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import withStyles from "@mui/styles/withStyles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle";

import {
	grayColor,
	primaryColor,
	dangerColor,
} from "assets/jss/material-kit-pro-react";

const dropdownStyle = {
	...basicsStyle,
	labelDropdown: {
		top: "3px",
	},
	selectFormControl: {
		margin: "5px 1px 10px 0px !important",
		"& > div": {
			"&:before": {
				borderBottomWidth: "1px !important",
				borderBottomColor: `${grayColor[11]} !important`,
			},
			"&:after": {
				borderBottomColor: `${primaryColor[0]}!important`,
			},
		},
	},
	error: {
		color: `${dangerColor[1]} !important`,
	},
};
/* eslint-disable */
export const CustomDropdownAdapter = ({
	input,
	dropDownList,
	classes,
	label,
	id,
	meta,
	getName,
	getKey,
	getValue,
}) => {
	return (
        <FormControl variant="standard" fullWidth className={classes.selectFormControl}>
			{label && (
				<InputLabel
					htmlFor={id}
					className={
						meta.error && meta.touched
							? `${classes.selectLabel} ${classes.labelDropdown} ${classes.error}`
							: `${classes.selectLabel} ${classes.labelDropdown}`
					}
				>
					{meta.error && meta.touched ? `${label}` : label}
				</InputLabel>
			)}
			<Select
                variant="standard"
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={
					dropDownList.find((item) => getKey(item) === getKey(input.value)) ||
					""
				}
                onChange={input.onChange}
                inputProps={{ name: id, id }}>
				{dropDownList
					.filter((item) => getName(item))
					.map((listItem) => {
						return (
							<MenuItem
								key={getKey(listItem)}
								value={getValue(listItem)}
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected,
								}}
							>
								{" "}
								{getName(listItem)}{" "}
							</MenuItem>
						);
					})}
			</Select>
		</FormControl>
    );
};

CustomDropdownAdapter.defaultProps = {
	getName: (item) => item,
	getKey: (item) => item,
	getValue: (item) => item,
};

CustomDropdownAdapter.propTypes = {
	input: PropTypes.object,
	dropDownList: PropTypes.array,
	classes: PropTypes.object,
	label: PropTypes.string,
	id: PropTypes.string,
	meta: PropTypes.object,
	getName: PropTypes.func,
	getKey: PropTypes.func,
	getValue: PropTypes.func,
};

export default withStyles(dropdownStyle)(CustomDropdownAdapter);
