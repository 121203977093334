import EditorComponent from "../../Editor/Editor";
import React from "react";
import PropTypes from "prop-types";

const EditorAdapter = ({ input, label, ...rest }) => {
	return (
		<EditorComponent
			editorProps={{ onChange: input.onChange, html: input.value }}
			labelText={label}
			formControlProps={{
				fullWidth: true,
			}}
			{...rest}
		/>
	);
};

EditorAdapter.propTypes = {
	input: PropTypes.object,
	label: PropTypes.string,
	rest: PropTypes.object,
};

export default EditorAdapter;
