import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { observer } from "mobx-react";
import { RootStoreContext } from "../../mobX/RootStore";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/icons-material
import LocationOn from "@mui/icons-material/LocationOn";
import Home from "@mui/icons-material/Home";
// core components
import Footer from "components/Footer/Footer";
import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
// custom components
import SportappHeader from "customComponents/SportappHeader/Header";

import placeholder from "customAssets/img/image-placeholder.png";
import backgroundImage from "customAssets/img/ssb_background.png";
import { clubDetailsStyles } from "./styles";
import OfferingCard from "../../customComponents/OfferingCard/OfferingCard";
import InfiniteSectionScroll from "../../customComponents/InfiniteScroll";
import LoadingIndicator from "../../customComponents/LoadingIndicator";
import { useLocation } from "react-router-dom";
import { getFullHomepageURL } from "utils/Utils";

// see https://v5.reactrouter.com/web/example/query-parameters
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Section = observer(({ section, classes }) => {
	return (
		<GridContainer key={section.title}>
			<GridItem>
				<h4 className={classNames(classes.left, classes.title)}>
					{section.title}
				</h4>
			</GridItem>
			<GridContainer style={{ width: "100%" }}>
				{section.items.map((offering) => (
					<GridItem xs={12} sm={4} key={offering.id}>
						<OfferingCard offering={offering} />
					</GridItem>
				))}
			</GridContainer>
		</GridContainer>
	);
});

const ClubDetails = observer(() => {
	const store = useContext(RootStoreContext);
	const clubStore = store.clubStore;
	let query = useQuery();

	const {
		tenantStore: { data: tenant },
	} = store;

	const clubId = query.get("id");
	const [club, setClub] = useState();

	useEffect(() => {
		clubStore.fetchClub(clubId);
	}, [clubId]);

	useEffect(() => {
		if (!clubStore.isLoading) {
			const club = clubStore.getClubById(clubId);
			setClub(club);
		}
	}, [clubStore.isLoading]);

	const classes = clubDetailsStyles();
	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgFluid,
	);

	const renderSection = (section, key) => {
		return <Section key={key} section={section} classes={classes} />;
	};

	return (
		<div>
			{club?.name && (
				<Helmet>
					<title>{`${club.name} - Ich trainiere in ${tenant.title}`}</title>
				</Helmet>
			)}
			<SportappHeader />
			<Parallax
				image={backgroundImage}
				filter="dark"
				className={classes.parallax}
			/>
			{club && (
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={10}>
								<div className={classes.profile}>
									<div className={classes.imgWrapper}>
										<img
											src={club.logoURI ? club.logoURI : placeholder}
											alt={club.name}
											className={imageClasses}
										/>
									</div>
									<div className={classes.name}>
										<h3 className={classes.title}>{club.name}</h3>
									</div>
									<div
										dangerouslySetInnerHTML={{ __html: club.descriptionAsHtml }}
									/>
									{club.address && (
										<GridContainer style={{ textAlign: "left" }}>
											<GridItem xs={12}>
												<h4 className={classNames(classes.left, classes.title)}>
													Adresse: {club.address.name}
												</h4>
											</GridItem>
											<GridItem xs={1}>
												<LocationOn className={classes.icon} />
											</GridItem>
											<GridItem xs={6} style={{ paddingLeft: 0 }}>
												<div>
													<p>
														{club.address.street} {club.address.houseNumber}
													</p>
													<p>
														{club.address.zipCode} {club.address.city}
													</p>
												</div>
											</GridItem>
										</GridContainer>
									)}
									{club.homepage && (
										<GridContainer style={{ textAlign: "left" }}>
											<GridItem>
												<h4 className={classNames(classes.left, classes.title)}>
													Homepage
												</h4>
											</GridItem>
											<GridItem xs={1}>
												<Home className={classes.icon} />
											</GridItem>
											<GridItem xs={6} style={{ paddingLeft: 0 }}>
												<a
													href={getFullHomepageURL(club.homepage)}
													className={classes.homepageUrl}
												>
													{club.homepage}
												</a>
											</GridItem>
										</GridContainer>
									)}
								</div>
								{club.offerings &&
									club.offerings.length > 0 &&
									(club.offeringsAndCoursesByCategory.length > 1 ? (
										<InfiniteSectionScroll
											data={club.offeringsAndCoursesByCategory}
											numOfItemsPerLoad={2}
											renderSection={renderSection}
											sectionKeyExtractor={(section) => section.title}
											loadNext={() => {}}
											loadingIndicator={
												<LoadingIndicator
													color={"#004589"}
													size={30}
													style={{ marginLeft: "auto", marginRight: "auto" }}
												/>
											}
										/>
									) : (
										<GridContainer>
											<GridItem>
												<h4 className={classNames(classes.left, classes.title)}>
													Angebote
												</h4>
											</GridItem>
											<GridContainer style={{ width: "100%" }}>
												{club.sortedOfferingsAndCourses.map((offering) => (
													<GridItem xs={12} sm={4} key={offering.id}>
														<OfferingCard offering={offering} />
													</GridItem>
												))}
											</GridContainer>
										</GridContainer>
									))}
							</GridItem>
						</GridContainer>
						<div style={{ height: 20 }} />
					</div>
				</div>
			)}
			<Footer parentClasses={classes} />
		</div>
	);
});

export default ClubDetails;
