import React from "react";
import "./style.css";

const Policy = () => (
	<div className="policy">
		<h4 className="header">Datenschutzerklärung für Apps</h4>
		<h4 className="header">Datenschutzerklärung für die Solingen Sportapp</h4>
		<h4 className="header">Präambel</h4>
		<p>
			Dieser Dienst (nachfolgend <span className="highlight">„Sportapp“</span>)
			wird von dem Solinger Sportbund e.V., Am Neumarkt 27, 42651 Solingen
			(nachfolgend <span className="highlight">„wir“</span> oder{" "}
			<span className="highlight">„uns“</span>) als Verantwortlicher im Sinne
			des jeweils geltenden Datenschutzrechts zur Verfügung gestellt. Im Rahmen
			der Sportapp ermöglichen wir Ihnen den Abruf und Darstellungen folgender
			Informationen. Die Leistungsbeschreibung umfasst in Erwartung einer
			zukünftigen Angleichung der Funktionalität in gleichem Maße sowohl die
			mobilen Apps für Android und iOS als auch die Web-App:
		</p>
		<ul>
			<li>
				<p>
					Verwaltung und Abruf von allgemeinen Informationen zu Sportvereinen
					(Name, Logo, Beschreibung, Kontakt)
				</p>
			</li>
			<li>
				<p>
					Verwaltung und Abruf der von den Vereinen angebotenen Aktivitäten
					(Name, (Name, (Name, Kategorie, Beschreibung, Zielgruppe,
					Trainingszeiten, Ort)
				</p>
			</li>
			<li>
				<p>
					Verwaltung und Abruf von Terminen und Ereignissen (Datum,
					Beschreibung)
				</p>
			</li>
			<li>
				<p>
					Verwaltung der persönlichen Ziele zur Erreichung des Sportabzeichens
				</p>
			</li>
		</ul>
		<p>
			Bei der Nutzung der Sportapp werden von uns personenbezogene Daten über
			Sie verarbeitet. Unter personenbezogenen Daten sind sämtliche
			Informationen zu verstehen, die sich auf eine identifizierte oder
			identifizierbare natürliche Person beziehen. Weil uns der Schutz Ihrer
			Privatsphäre bei der Nutzung der Sportapp wichtig ist, möchten wir Sie mit
			den nachfolgenden Angaben darüber informieren, welche personenbezogenen
			Daten wir verarbeiten, wenn Sie die Sportapp nutzen und wie wir mit diesen
			Daten umgehen. Darüber hinaus unterrichten wir Sie über die
			Rechtsgrundlage für die Verarbeitung Ihrer Daten und, soweit die
			Verarbeitung zur Wahrung unserer berechtigten Interessen erforderlich ist,
			auch über unsere berechtigten Interessen.Sie können diese
			Datenschutzerklärung jederzeit innerhalb der Sportapp aufrufen.
		</p>
		<h4 className="subheader">1. Informationen zur Verarbeitung Ihrer Daten</h4>
		<p>
			Bestimmte Informationen werden bereits automatisch verarbeitet, sobald Sie
			die Sportapp verwenden. Welche personenbezogenen Daten genau verarbeitet
			werden, haben wir im Folgenden für Sie aufgeführt:
		</p>
		<h4 className="subheader">
			1.1 Informationen, die beim Download erhoben werden
		</h4>
		<ul>
			<li>
				<p>
					Beim Download der Sportapp werden bestimmte erforderliche
					Informationen an den von Ihnen ausgewählten App Store (Google Play
					Store oder Apple App Store) übermittelt, insbesondere können dabei der
					Nutzername, die E-Mail-Adresse, die Kundennummer Ihres Accounts, der
					Zeitpunkt des Downloads, Zahlungsinformationen sowie die individuelle
					Gerätekennziffer verarbeitet werden. Die Verarbeitung dieser Daten
					erfolgt ausschließlich durch den jeweiligen App Store und liegt
					außerhalb unseres Einflussbereiches.
				</p>
			</li>
		</ul>
		<h4 className="subheader">
			1.2 Informationen, die automatisch erhoben werden
		</h4>
		<ul>
			<li>
				<p>
					Im Rahmen Ihrer Nutzung der Sportapp erheben wir bestimmte Daten
					automatisch, die für die Nutzung der App erforderlich sind. Hierzu
					gehören die üblichen Parameter des Internets:
				</p>
				<ul>
					<li>
						<p>IP-Adresse des Nutzers</p>
					</li>
					<li>
						<p>Datum und Uhrzeit der Anfrage</p>
					</li>
					<li>
						<p>Inhalt der Anforderung (konkrete Seite / Datenmenge)</p>
					</li>
					<li>
						<p>Zugriffsstatus/HTTP-Statuscode</p>
					</li>
					<li>
						<p>
							“userAgent”, welcher Informationen über Ihr verwendetes
							Betriebssystem und Browser beinhalten kann. Dies sind Beispiele,
							wie der “userAgent” gestaltet sein kann:
						</p>
						<ul>
							<li>
								<p>
									{/* eslint-disable-next-line react/no-unescaped-entities */}
									"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7)
									AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.77
									{/* eslint-disable-next-line react/no-unescaped-entities */}
									Safari/537.36"
								</p>
							</li>
							<li>
								{/* eslint-disable-next-line react/no-unescaped-entities */}
								<p>"aws-amplify/1.0.28 react-native"</p>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li>
				<p>
					Diese Daten werden automatisch an uns übermittelt und gespeichert, (1)
					um Ihnen den Dienst und die damit verbundenen Funktionen zur Verfügung
					zu stellen; (2) die Funktionen und Leistungsmerkmale der Sportapp zu
					verbessern und (3) Missbrauch sowie Fehlfunktionen vorzubeugen und zu
					beseitigen. Diese Datenverarbeitung ist dadurch gerechtfertigt, dass
					(1) die Verarbeitung für die Erfüllung des Vertrags zwischen Ihnen als
					Betroffener und uns gemäß Art.6 Abs.1 lit.b DSGVO zur Nutzung der App
					erforderlich ist, oder (2) wir ein berechtigtes Interesse daran haben,
					die Funktionsfähigkeit und den fehlerfreien Betrieb der Sportapp zu
					gewährleisten und einen markt- und interessengerechten Dienst anbieten
					zu können, das hier Ihre Rechte undInteressen am Schutz Ihrer
					personenbezogenen Daten im Sinne von Art.6 Abs.1 lit.f DSGVO
					überwiegt.
				</p>
			</li>
		</ul>
		<h4 className="subheader">
			1.3 Erstellung eines Nutzeraccounts (Registrierung) und Anmeldung
		</h4>
		<ul>
			<li>
				<p>
					Wenn Sie einen Nutzeraccount erstellen oder sich anmelden, verwenden
					wir Ihre Zugangsdaten (Nutzername, E-Mail-Adresse und Passwort), um
					Ihnen den Zugang zu Ihrem Nutzeraccount zu gewähren und diesen zu
					verwalten (<span className="highlight">„Pflichtangaben“</span>
					). Pflichtangaben im Rahmen der Registrierung sind mit einem Sternchen
					gekennzeichnet und sind für den Abschluss des Nutzungsvertrages
					erforderlich. Wenn Sie diese Daten nicht angeben, können Sie keinen
					Nutzeraccount erstellen.
				</p>
			</li>
			<li>
				<p>
					Darüber hinaus können Sie freiwillige Angaben im Rahmen der
					Registrierung machen.
				</p>
			</li>
			<li>
				<p>
					Die Pflichtangaben verwenden wir, um Sie beim Login zu
					authentifizieren und Anfragen zur Rücksetzung Ihres Passwortes
					nachzugehen. Die von Ihnen im Rahmen der Registrierung oder einer
					Anmeldung eingegebenenDaten werden von uns verarbeitet und verwendet,
					(1) um Ihre Berechtigung zur Verwaltung des Nutzeraccounts zu
					verifizieren; (2) die Nutzungsbedingungen der Sport sowie alle damit
					verbundenen Rechte und Pflichten durchzusetzen und (3) mit Ihnen in
					Kontakt zu treten, um Ihnen technische oder rechtliche Hinweise,
					Updates, Sicherheitsmeldungen oder andere Nachrichten, die etwa die
					Verwaltung des Nutzeraccounts betreffen, senden zu können.
				</p>
			</li>
			<li>
				<p>
					Freiwillige Angaben verwenden wir, um diese entsprechend Ihrer
					vorgenommenen Einstellungen im Rahmen der Sportapp anzuzeigen und auf
					Ihren Wunsch hin anderen Nutzern der App zugänglich zu machen.
				</p>
			</li>
			<li>
				<p>
					Diese Datenverarbeitung ist dadurch gerechtfertigt, dass (1) die
					Verarbeitung für die Erfüllung des Vertrags zwischen Ihnen als
					Betroffener und uns gemäß Art.6 Abs.1 lit.b DSGVO zur Nutzung der App
					erforderlich ist, oder (2) wir ein berechtigtes Interesse daran haben,
					die Funktionsfähigkeit und den fehlerfreien Betrieb der Sportapp zu
					gewährleisten, das hier Ihre Rechte und Interessen am Schutz Ihrer
					personenbezogenen Daten im Sinne von Art.6 Abs.1 lit.f DSGVO
					überwiegt.
				</p>
			</li>
		</ul>
		<h4 className="subheader">1.4 Nutzung der Sportapp</h4>
		<ul>
			<li>
				<p>
					Im Rahmen der Sportapp können Informationen, Aufgaben und Aktivitäten
					eingeben, verwaltet und bearbeitet werden. Diese Informationen
					umfassen insbesondere Daten über dievon den Vereinen angebotenen
					Angebote und Ereignisse.
				</p>
			</li>
			<li>
				<p>
					Nach Ihrer Zustimmung erheben wir anonyme Nutzungsstatistiken der
					Sportapp, um nachvollziehen zu können, welche Funktionen genutzt
					werden - und welche nicht. Aufgrund der aus diesen Daten gewonnen
					Erkenntnissen kann der Funktionsumfang der Sportapp Zug um Zug an Ihre
					Ziele angepasst werden.
				</p>
			</li>
			<li>
				<p>
					Um sich für die Push-Nachrichten anzumelden, müssen Sie die Abfrage
					Ihres Browsers bzw. Endgerätes zum Erhalt der Push-Nachrichten
					bestätigen. Dieser Prozess wird dokumentiert und gespeichert. Hierfür
					werden der Anmeldezeitpunkt sowie ein Push-Token bzw. Geräte-ID
					gespeichert. Diese Daten dienen einerseits dazu, Ihnen die
					Push-Nachrichten zusenden zu können und andererseits als Nachweis
					Ihrer Anmeldung.
				</p>
			</li>
		</ul>
		<p>Die App erfordert darüber hinaus folgende Berechtigungen:</p>
		<ul>
			<li>
				<p>
					Internetzugriff: Dieser wird benötigt, um auf aktuelle Vereins- und
					Sportangebote zuzugreifen.
				</p>
			</li>
			<li>
				<p>
					Standort: Dieser wird benötigt, um die Sportangebote in der Umgebung
					anzuzeigen.
				</p>
			</li>
		</ul>
		<p>
			Die Verarbeitung und Verwendung von Nutzungsdaten erfolgt zur
			Bereitstellung des Dienstes. Diese Datenverarbeitung ist dadurch
			gerechtfertigt, dass die Verarbeitung für die Erfüllung des Vertrags
			zwischen Ihnen als Betroffener und uns gemäß Art.6 Abs.1 lit.b DSGVO zur
			Nutzung derSportapp erforderlich ist.
		</p>
		<h4 className="subheader">2. Weitergabe und Übertragung von Daten</h4>
		<p>
			Eine Weitergabe Ihrer personenbezogenen Daten ohne Ihre ausdrückliche
			vorherige Einwilligung erfolgt neben den explizit in dieser
			Datenschutzerklärung genannten Fällen lediglich dann, wenn es gesetzlich
			zulässig bzw. erforderlich ist.
		</p>
		<p>
			2.1 Wenn es zur Aufklärung einer rechtswidrigen bzw. missbräuchlichen
			Nutzung der Sportapp oder für die Rechtsverfolgung erforderlich ist,
			werden personenbezogene Daten an die Strafverfolgungsbehörden oder andere
			Behörden sowie ggf. an geschädigte Dritte oder Rechtsberater
			weitergeleitet. Dies geschieht jedoch nur, wenn Anhaltspunkte für ein
			gesetzwidriges bzw. missbräuchliches Verhalten vorliegen. Eine Weitergabe
			kann auch stattfinden, wenn dies der Durchsetzung von Nutzungsbedingungen
			oder anderen Rechtsansprüchen dient. Wir sind zudem gesetzlich
			verpflichtet, auf Anfrage bestimmten öffentlichen Stellen Auskunft zu
			erteilen. Dies sind Strafverfolgungsbehörden, Behörden, die
			bußgeldbewehrte Ordnungswidrigkeiten verfolgen, und die Finanzbehörden.
		</p>
		<ul>
			<li>
				<p>
					Eine etwaige Weitergabe der personenbezogenen Daten ist dadurch
					gerechtfertigt, dass (1) die Verarbeitung zur Erfüllung einer
					rechtlichen Verpflichtung erforderlich ist, der wir gemäß Art.6 Abs.1
					lit.f DSGVO i.V.m. nationalen rechtlichen Vorgaben zur Weitergabe von
					Daten an Strafverfolgungsbehörden unterliegen, oder (2) wir ein
					berechtigtes Interesse daran haben, die Daten bei Vorliegen von
					Anhaltspunkten für missbräuchliches Verhalten oder zur Durchsetzung
					unserer Nutzungsbedingungen, anderer Bedingungen oder von
					Rechtsansprüchen an die genannten Dritten weiterzugeben und Ihre
					Rechte und Interessen am Schutz Ihrer personenbezogenen Daten im Sinne
					von Art.6 Abs.1 lit.f DSGVO nicht überwiegen.
				</p>
			</li>
		</ul>
		<p>
			2.2 Wir sind für die Erbringung unseres Dienstes auf externe Dienstleister
			angewiesen:
		</p>
		<ul>
			<li>
				<p>codecentric AG: Erstellung, Wartung und Betrieb der Sportapp</p>
			</li>
			<li>
				<p>
					Amazon AWS Europe: IT Services in Frankfurt zum technischen Betrieb
					des Sportapp Backends
				</p>
			</li>
			<li>
				<p>
					Eine etwaige Weitergabe der personenbezogenen Daten ist dadurch
					gerechtfertigt, dass (1) wir ein berechtigtes Interesse daran haben,
					die Daten für administrative Zwecke innerhalb unserer
					Unternehmensgruppeweiterzugeben und Ihre Rechte und Interessen am
					Schutz Ihrer personenbezogenen Daten im Sinne von Art.6 Abs.1 lit.f
					DSGVO nicht überwiegen und (2) wir unsere Fremdunternehmen und
					externen Dienstleister im Rahmen von Art.28 Abs.1 DSGVO als
					Auftragsverarbeiter sorgfältig ausgewählt, regelmäßig überprüft und
					vertraglich verpflichtet haben, sämtliche personenbezogenen Daten
					ausschließlich entsprechend unserer Weisungen zu verarbeiten.
				</p>
			</li>
		</ul>
		<p>
			2.3 Im Rahmen der Weiterentwicklung unseres Geschäfts kann es dazu kommen,
			dass sich die Struktur unseres Unternehmens wandelt, indem die Rechtsform
			geändert wird, Tochtergesellschaften, Unternehmensteile oder Bestandteile
			gegründet, gekauft oder verkauft werden. Bei solchen Transaktionen werden
			die Kundeninformationen gegebenenfalls zusammen mitdem zu übertragenden
			Teil des Unternehmens weitergegeben. Bei jeder Weitergabe von
			personenbezogenen Daten an Dritte in dem vorbeschriebenen Umfang tragen
			wir dafür Sorge, dass dies in Übereinstimmung mit dieser
			Datenschutzerklärung und dem anwendbaren Datenschutzrecht erfolgt.Eine
			etwaige Weitergabe der personenbezogenen Daten ist dadurch gerechtfertigt,
			dass wir ein berechtigtes Interesse daran haben, unsere Unternehmensform
			den wirtschaftlichen und rechtlichen Gegebenheiten entsprechend bei Bedarf
			anzupassen und Ihre Rechte und Interessen am Schutz Ihrer
			personenbezogenen Daten im Sinne von Art.6 Abs.1 lit.f DSGVO nicht
			überwiegen.
		</p>
		<p>
			2.4 Sportapp als offene PlattformDie Daten über Vereine und
			Vereinsaktivitäten werden über eine offene Schnittstelle zur Verfügung
			gestellt. Dies ermöglicht es Dritten die Informationen über Vereine oder
			Vereinsangebote in deren Apps und Webangebote zu integrieren.
		</p>
		<h4 className="subheader">3. Datenübermittlungen in Drittländer</h4>
		<p>
			Die Server der Sportapp stehen in einem Rechenzentrum in Frankfurt und
			werden dort gespeichert und verarbeitet. Ggf. verarbeiten wir Daten auch
			in Staaten außerhalb des Europäischen Wirtschaftsraumes (
			<span className="highlight">„EWR“</span>
			). Dies betrifft im Einzelnen:
		</p>
		<p>
			Rechenzentrum Frankfurt (eu-central-1) von AWS Europe
			<br />
			Amazon Web Services EMEA SARL
			<br />
			38 avenue John F. Kennedy
			<br />
			L-1855 Luxemburg
		</p>
		<p>
			Um den Schutz der Persönlichkeitsrechte der Nutzer auch im Rahmen dieser
			Datenübertragungen zu gewährleisten, bedienen wir uns bei der
			Ausgestaltungder Vertragsverhältnisse mit den Empfängern in Drittländern
			der Standardvertragsklauseln der EU-Kommission gemäß Art.46 Abs.2 lit.c
			DSGVO.
		</p>
		<h4 className="subheader">4. Zweckänderungen</h4>
		<p>
			Verarbeitungen Ihrer personenbezogenen Daten zu anderen als den
			beschriebenen Zwecken erfolgen nur, soweit eine Rechtsvorschrift dies
			erlaubt oder Sie in den geänderten Zweck der Datenverarbeitung
			eingewilligt haben. Im Falle einer Weiterverarbeitung zu anderen Zwecken
			als denen, für den die Daten ursprünglich erhoben worden sind, informieren
			wir Sie vor der Weiterverarbeitung über diese anderen Zwecke und stellen
			Ihnen sämtliche weitere hierfür maßgeblichen Informationen zur Verfügung.
		</p>
		<h4 className="subheader">5. Zeitraum der Datenspeicherung</h4>
		<p>
			Wir löschen oder anonymisieren Ihre personenbezogenen Daten, sobald sie
			für die Zwecke, für die wir sie nach den vorstehenden Ziffern erhoben oder
			verwendet haben, nicht mehr erforderlich sind. Soweit nicht abweichend
			angegeben speichern wir Ihre personenbezogenen Daten für die Dauer des
			Nutzungs- bzw. des Vertragsverhältnisses über die App zzgl. eines
			Zeitraumes von 14 Tagen, während welchem wir nach der Löschung
			Sicherungskopien aufbewahren, soweit diese Daten nicht für die
			strafrechtliche Verfolgung oder zur Sicherung, Geltendmachung oder
			Durchsetzung von Rechtsansprüchen länger benötigt werden.Spezifische
			Angaben in dieser Datenschutzerklärung oder rechtliche Vorgaben zur
			Aufbewahrung und Löschung personenbezogener Daten, insbesondere solcher,
			die wir aus steuerrechtlichen Gründen aufbewahren müssen, bleiben
			unberührt.
		</p>
		<h4 className="subheader">6. Ihre Rechte als Betroffener</h4>
		<h4 className="subheader">6.1 Auskunftsrecht</h4>
		<ul>
			<li>
				<p>
					Sie haben das Recht, von uns jederzeit auf Antrag eine Auskunft über
					die von uns verarbeiteten, Sie betreffenden personenbezogenen Daten im
					Umfang des Art.15 DSGVO zu erhalten. Hierzu können Sie einen Antrag
					postalisch oder per E-Mail an die unten angegebene Adresse stellen.
				</p>
			</li>
		</ul>
		<h4 className="subheader">6.2 Recht zur Berichtigung unrichtiger Daten</h4>
		<ul>
			<li>
				<p>
					Sie haben das Recht, von uns die unverzügliche Berichtigung der Sie
					betreffenden personenbezogenen Daten zu verlangen, sofern diese
					unrichtig sein sollten. Wenden Sie sich hierfür bitte an die unten
					angegebenen Kontaktadressen.
				</p>
			</li>
		</ul>
		<h4 className="subheader">6.3 Recht auf Löschung</h4>
		<ul>
			<li>
				<p>
					Sie haben das Recht, unter den in Art.17 DSGVO beschriebenen
					Voraussetzungen von uns die Löschung der Sie betreffenden
					personenbezogenen Daten zu verlangen. Diese Voraussetzungen sehen
					insbesondere ein Löschungsrecht vor, wenn die personenbezogenen Daten
					für die Zwecke, für die sie erhoben oder auf sonstige Weise
					verarbeitet wurden, nicht mehr notwendig sind, sowie in Fällen der
					unrechtmäßigen Verarbeitung, des Vorliegens eines Widerspruchs oder
					des Bestehens einer Löschungspflicht nach Unionsrecht oder dem Recht
					des Mitgliedstaates, dem wir unterliegen. Zum Zeitraum der
					Datenspeicherung siehe im Übrigen Ziffer5 dieser
					Datenschutzerklärung.Um Ihr Recht auf Löschung geltend zu machen,
					wenden Sie sich bitte an die unten angegebenen Kontaktadressen.
				</p>
			</li>
		</ul>
		<h4 className="subheader">6.4 Recht auf Einschränkung der Verarbeitung</h4>
		<ul>
			<li>
				<p>
					Sie haben das Recht, von uns die Einschränkung der Verarbeitung nach
					Maßgabe des Art.18 DSGVO zu verlangen. Dieses Recht besteht
					insbesondere, wenn die Richtigkeit der personenbezogenen Daten
					zwischen dem Nutzer und uns umstritten ist, für die Dauer, welche die
					Überprüfung der Richtigkeit erfordert, sowie im Fall, dass der Nutzer
					bei einem bestehenden Recht auf Löschung anstelle der Löschung eine
					eingeschränkte Verarbeitung verlangt; ferner für den Fall, dass die
					Datenfür die von uns verfolgten Zwecke nicht länger erforderlich sind,
					der Nutzer sie jedoch zur Geltendmachung, Ausübung oder Verteidigung
					von Rechtsansprüchen benötigt sowie, wenn die erfolgreiche Ausübung
					eines Widerspruchs zwischen uns und dem Nutzer noch umstritten ist. Um
					Ihr Recht auf Einschränkung der Verarbeitung geltend zu machen, wenden
					Sie sich bitte an die unten angegebenen Kontaktadressen.
				</p>
			</li>
		</ul>
		<h4 className="subheader">6.5 Recht auf Datenübertragbarkeit</h4>
		<ul>
			<li>
				<p>
					Sie haben das Recht, von uns die Sie betreffenden personenbezogenen
					Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
					gängigen, maschinenlesbaren Format nach Maßgabe des Art.20 DSGVO zu
					erhalten. Um Ihr Recht auf Datenübertragbarkeit geltend zu machen,
					wenden Sie sich bitte an die unten angegebenen Kontaktadressen.
				</p>
			</li>
		</ul>
		<h4 className="subheader">7. Widerspruchsrecht</h4>
		<p>
			Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
			ergeben, jederzeit gegen die Verarbeitung Sie betreffender
			personenbezogener Daten, die u.a. aufgrund von Art.6 Abs.1 lit.e oder
			lit.f DSGVO erfolgt, Widerspruch nach Art.21 DSGVO einzulegen.Wir werden
			die Verarbeitung Ihrer personenbezogenen Daten einstellen, es sei denn,
			wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
			die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die
			Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von
			Rechtsansprüchen dient.
		</p>
		<h4 className="subheader">8. Beschwerderecht</h4>
		<p>
			Sie haben ferner das Recht, sich bei Beschwerden an eine Aufsichtsbehörde
			zu wenden.
		</p>
		<h4 className="subheader">9. Kontakt</h4>
		<p>
			Sollten Sie Fragen oder Anmerkungen zu unserem Umgang mit Ihren
			personenbezogenen Daten haben oder möchten Sie die unter Ziffer 6 und 7
			genannten Rechte als betroffene Person ausüben, wenden Sie sich bitte an
			an Solinger Sportbund e.V. unter folgenden Kontaktdaten:
		</p>
		<p>
			Am Neumarkt 27, 42651 Solingen
			<br />
			E-mail: vorstand@solingersport.de
			<br />
			Tel.: 0212 20 21 11
		</p>
		<p>
			Unser Datenschutzbeauftragter ist unter folgenden Kontaktdaten zu
			erreichen:
		</p>
		<p>
			E-mail: datenschutzbeauftragter@solingersport.de
			<br />
			Tel.: 0176 21 69 6622
		</p>
		<h4 className="subheader">10. Änderungen dieser Datenschutzerklärung</h4>
		<p>
			Wir halten diese Datenschutzerklärung immer auf dem neuesten Stand. Die
			aktuelle Fassung der Datenschutzerklärung ist stets innerhalb der App
			abrufbar.
		</p>
		<h4 className="subheader">Stand: 27.07.2021</h4>
	</div>
);

export default Policy;
