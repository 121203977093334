import React, { useContext } from "react";

import Header from "components/Header/Header";
import SportappHeaderLinks from "customComponents/SportappHeader/HeaderLinks";
import { RootStoreContext } from "mobX/RootStore";

export default function SportappHeader(props) {
	const { ...rest } = props;
	const store = useContext(RootStoreContext);

	const {
		tenantStore: { data: tenant },
	} = store;

	return (
		<Header
			color="transparent"
			brand={`Ich trainiere in ${tenant.title}`}
			links={<SportappHeaderLinks dropdownHoverColor="primary" />}
			fixed
			changeColorOnScroll={{
				height: 250,
				color: "primary",
			}}
			{...rest}
		/>
	);
}

SportappHeader.defaultProps = {};

SportappHeader.propTypes = {};
