import React from "react";

import withStyles from "@mui/styles/withStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// custom components
import CustomDropdownAdapter from "customComponents/CustomDropdownAdapter/CustomDropdownAdapter";
import CustomInputAdapter from "../components/CustomInputAdapter";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-calculate";
import FieldAdapter from "../components/FieldAdapter";

import categories from "../../../utils/categories";

import LocationModal from "../LocationModal";
import ContactModal from "../ContactModal";
import SwitchAdapter from "../components/SwitchAdapter";
import { FieldArray } from "react-final-form-arrays";

const genderDecorator = createDecorator(
	{
		field: "targetGroup.male",
		updates: {
			"targetGroup.female": (male, all) =>
				male ? all.targetGroup.female : true,
		},
	},
	{
		field: "targetGroup.female",
		updates: {
			"targetGroup.male": (female, all) =>
				female ? all.targetGroup.male : true,
		},
	},
);

const style = (theme) => ({
	...modalStyle(theme),
	modalRootExample: {
		"& > div:first-child": {
			display: "none",
		},
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	removeButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
});

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

const getNonNullEntries = (object) => {
	return Object.entries(object).reduce((prev, [key, value]) => {
		if (value) prev[key] = value;
		return prev;
	}, {});
};

class BulkEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scrollingModal: false,
			offeringData: null,
		};
	}

	onSubmit = async (values) => {
		const { updateOffering, selectedIds } = this.props;

		const nonNullEntries = getNonNullEntries(values);
		const offerings = selectedIds.map((id) => {
			return { id, ...nonNullEntries };
		});
		updateOffering(offerings);
		this.handleClose();
	};

	async handleClickOpen() {
		const offeringData = this.props.offering;
		this.setState({ scrollingModal: true, offeringData });
	}

	handleClose() {
		this.setState({ scrollingModal: false });
	}

	render() {
		const {
			classes,
			contacts,
			locations,
			createContact,
			createLocation,
			button,
		} = this.props;
		const { offeringData } = this.state;

		return (
			<div>
				{button(() => this.handleClickOpen())}
				{this.state.scrollingModal && (
					<Dialog
						classes={{
							root: `${classes.modalRoot} ${classes.modalRootExample}`,
							paper: `${classes.modal} ${classes.modalLarge}`,
						}}
						open={this.state.scrollingModal}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleClose("scrollingModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<Form
							onSubmit={this.onSubmit}
							decorators={[genderDecorator]}
							initialValues={offeringData}
							mutators={{ ...arrayMutators }}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								form: {
									mutators: { push },
								},
							}) => (
								<form onSubmit={handleSubmit}>
									<DialogTitle
										id="classic-modal-slide-title"
										className={classes.modalHeader}
									>
										<Button
											simple
											className={classes.modalCloseButton}
											key="close"
											aria-label="Close"
											onClick={() => this.handleClose("scrollingModal")}
										>
											{" "}
											<Close className={classes.modalClose} />
										</Button>
										<h4 className={classes.modalTitle}>Angebote Anpassen</h4>
									</DialogTitle>
									<DialogContent
										id="classic-modal-slide-description"
										className={classes.modalBody}
									>
										<GridContainer>
											<GridItem>
												<FieldAdapter
													name="category"
													component={CustomDropdownAdapter}
													type="text"
													placeholder="Sportart"
													label="Sportart"
													dropDownList={categories}
													id="category"
												/>
											</GridItem>
											<GridItem>
												<FieldAdapter
													name="title"
													component={CustomInputAdapter}
													type="text"
													placeholder="Titel"
													label="Titel"
												/>
											</GridItem>
											<GridItem>
												<FieldAdapter
													name="description"
													component={CustomInputAdapter}
													type="text"
													required
													placeholder="Beschreibung"
													label="Beschreibung"
													inputProps={{
														multiline: true,
														rowsMax: 10,
													}}
												/>
											</GridItem>
											{offeringData.targetGroup && (
												<div>
													<GridItem xs={12} sm={6} md={6} lg={6}>
														<h4>Zielgruppe</h4>
													</GridItem>
													<GridItem xs={12}>
														<GridContainer key="targetGroup">
															<GridItem xs={6} sm={6} md={6} lg={6}>
																<FieldAdapter
																	name="targetGroup.from"
																	component={CustomInputAdapter}
																	inputProps={{
																		type: "number",
																	}}
																	placeholder="Von"
																	label="Mindestalter"
																	validate={(value) => {
																		if (value === null || value < 0)
																			return "Ungültiges Alter";
																	}}
																/>
															</GridItem>

															<GridItem xs={6} sm={6} md={6} lg={6}>
																<FieldAdapter
																	name="targetGroup.to"
																	component={CustomInputAdapter}
																	inputProps={{
																		type: "number",
																	}}
																	placeholder="Bis"
																	label="Höchstalter"
																	validate={(value) => {
																		if (value === null || value < 0)
																			return "Ungültiges Alter";
																	}}
																/>
															</GridItem>

															<GridItem xs={6} sm={3} md={3} lg={3}>
																<FieldAdapter
																	name="targetGroup.female"
																	component={SwitchAdapter}
																	type="text"
																	label="Weiblich"
																/>
															</GridItem>
															<GridItem xs={6} sm={3} md={3} lg={3}>
																<FieldAdapter
																	name="targetGroup.male"
																	component={SwitchAdapter}
																	type="text"
																	label="Männlich"
																/>
															</GridItem>
														</GridContainer>
													</GridItem>
													{offeringData.costs && (
														<GridItem>
															<GridContainer>
																<GridItem xs={12} sm={6} md={6} lg={6}>
																	<h4>Gebühren</h4>
																</GridItem>
																<GridItem xs={12} sm={6} md={6} lg={6}>
																	<Button
																		color="primary"
																		simple
																		onClick={() => push("costs", undefined)}
																	>
																		<i className="fas fa-plus" /> Gebühren
																		hinzufügen
																	</Button>
																</GridItem>
																<GridItem xs={12}>
																	<FieldArray name="costs">
																		{({ fields }) =>
																			fields.map((name, index) => (
																				<GridContainer key={name}>
																					<GridItem xs={3} sm={3} md={3} lg={3}>
																						<FieldAdapter
																							name={`${name}.billingInterval`}
																							placeholder="Zeitraum"
																							component={CustomDropdownAdapter}
																							type="text"
																							label="Zeitraum"
																							dropDownList={[
																								"Monat",
																								"Halbjahr",
																								"Jahr",
																							]}
																							id={`${name}.billingInterval`}
																							validate={(value) =>
																								!value && "Eingabe erforderlich"
																							}
																						/>
																					</GridItem>
																					<GridItem xs={3} sm={3} md={3} lg={3}>
																						<FieldAdapter
																							name={`${name}.cost`}
																							component={CustomInputAdapter}
																							type="text"
																							placeholder="Gebühren"
																							label="Gebühren"
																							nonReactInputProps={{
																								required: true,
																							}}
																						/>
																					</GridItem>
																					<GridItem xs={3} sm={3} md={3} lg={3}>
																						<FieldAdapter
																							name={`${name}.ageGroup`}
																							placeholder="Altersklasse"
																							component={CustomDropdownAdapter}
																							type="text"
																							label="Altersklasse"
																							dropDownList={[
																								"Erwachsene",
																								"Kinder",
																								"Senioren",
																								"Studenten",
																								"Jeden",
																							]}
																							id={`${name}.ageGroup`}
																							validate={(value) =>
																								!value && "Eingabe erforderlich"
																							}
																						/>
																					</GridItem>
																					<GridItem
																						xs={3}
																						sm={3}
																						md={3}
																						lg={3}
																						className={classes.removeButton}
																					>
																						{fields.length !== 1 && (
																							<Button
																								color="danger"
																								simple
																								onClick={() =>
																									fields.remove(index)
																								}
																							>
																								<i className="fas fa-minus-circle" />
																							</Button>
																						)}
																					</GridItem>
																				</GridContainer>
																			))
																		}
																	</FieldArray>
																</GridItem>
															</GridContainer>
														</GridItem>
													)}
													<GridItem>
														<FieldAdapter
															name="hints"
															component={CustomInputAdapter}
															type="text"
															placeholder="Zusatzhinweise"
															label="Zusatzhinweise"
															inputProps={{ multiline: true, rowsMax: 10 }}
														/>
													</GridItem>{" "}
												</div>
											)}

											<GridItem xs={12} sm={6} md={6} lg={6}>
												<h4>Kontakt</h4>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<ContactModal
													newContact
													createContact={createContact}
													button={(onClick) => {
														return (
															<Button
																color="primary"
																simple
																onClick={() => onClick()}
															>
																<i className="fas fa-plus" /> Neuer Kontakt
															</Button>
														);
													}}
												/>
											</GridItem>
											<GridItem>
												<FieldAdapter
													name="contact"
													component={CustomDropdownAdapter}
													type="text"
													dropDownList={contacts}
													id="contact"
													getKey={(item) => item.id}
													getValue={(item) => item}
													getName={(item) =>
														`${item.firstName} ${item.lastName}`
													}
												/>
											</GridItem>

											<GridItem xs={12} sm={6} md={6} lg={6}>
												<h4>Sportstätte</h4>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<LocationModal
													newLocation
													createLocation={createLocation}
													button={(onClick) => {
														return (
															<Button
																color="primary"
																simple
																onClick={() => onClick()}
															>
																<i className="fas fa-plus" /> Neue Sportstätte
															</Button>
														);
													}}
												/>
											</GridItem>
											<GridItem>
												<FieldAdapter
													name="location"
													component={CustomDropdownAdapter}
													type="text"
													dropDownList={locations}
													id="location"
													getKey={(item) => item.id}
													getValue={(item) => item}
													getName={(item) => item.name}
												/>
											</GridItem>
										</GridContainer>
									</DialogContent>
									<DialogActions className={classes.modalFooter}>
										<Button
											onClick={form.reset}
											disabled={submitting || pristine}
											color="secondary"
										>
											Zurücksetzen
										</Button>
										<Button color="primary" type="submit" disabled={submitting}>
											{this.props.selectedIds.length} Angebote Anpassen
										</Button>
									</DialogActions>
								</form>
							)}
						/>
					</Dialog>
				)}
			</div>
		);
	}
}

BulkEditModal.propTypes = {
	classes: PropTypes.object,
	selectedIds: PropTypes.array,
	offering: PropTypes.object,
	updateOffering: PropTypes.func,
	createContact: PropTypes.func,
	createLocation: PropTypes.func,
	contacts: PropTypes.array,
	locations: PropTypes.array,
	button: PropTypes.func,
};

const districts = [
	"Höhscheid",
	"Mitte",
	"Ohligs",
	"Merscheid",
	"Wald",
	"Burg",
	"Gräfrath",
	"Aufderhöhe",
	"Außerhalb von SG",
];

export default withStyles(style)(BulkEditModal);
