import { getUrl } from "aws-amplify/storage";
import { awsConfig } from "../aws-config";
import { Geo } from "@aws-amplify/geo";

const getGender = (targetGroup) => {
	const male =
		targetGroup === "m" || targetGroup === "" || targetGroup === "mw";
	const female =
		targetGroup === "w" || targetGroup === "" || targetGroup === "mw";
	return { male, female };
};

export const getTargetData = (item) => {
	if (!item.targetGroup) return null;

	const { targetGroup } = item;
	const targetGroupSplitBySemicolon = targetGroup.split(";");
	const targetGroupSplitByComma = targetGroupSplitBySemicolon[0].split(",");
	const { male, female } = getGender(targetGroupSplitBySemicolon[1]);

	// TODO: getTargetData(offering)
	return {
		male,
		female,
		from: targetGroupSplitByComma[0],
		to: targetGroupSplitByComma[1],
	};
};

export const targetGroupForDisplay = (offering) => {
	if (!offering.targetGroup) return "-";

	const { from, to, male, female } = offering.targetGroup;
	let gender = "-";

	if (male && female) gender = "m/w";
	if (male && !female) gender = "m";
	if (!male && female) gender = "w";

	return `${from}-${to} (${gender})`;
};

export const reconstructTargetGroup = (offering) => {
	const {
		targetGroup: { from, to, male, female },
	} = offering;
	return {
		...offering,
		targetGroup: `${from || 0},${to || 100};${male ? "m" : ""}${
			female ? "w" : ""
		}`,
	};
};

export const mapDayEnumsToAbbrev = (day) => {
	switch (day) {
		case "MONDAY":
			return "Mo";
		case "TUESDAY":
			return "Di";
		case "WEDNESDAY":
			return "Mi";
		case "THURSDAY":
			return "Do";
		case "FRIDAY":
			return "Fr";
		case "SATURDAY":
			return "Sa";
		case "SUNDAY":
			return "So";
		case "EVERYDAY":
			return "Jeden Tag";
		default:
			return null;
	}
};

export const mapAbbrevToDayEnums = (abbr) => {
	switch (abbr) {
		case "Mo":
			return "MONDAY";
		case "Di":
			return "TUESDAY";
		case "Mi":
			return "WEDNESDAY";
		case "Do":
			return "THURSDAY";
		case "Fr":
			return "FRIDAY";
		case "Sa":
			return "SATURDAY";
		case "So":
			return "SUNDAY";
		case "Jeden Tag":
			return "EVERYDAY";
		default:
			return null;
	}
};

export const mapDayEnumsToFullName = (day) => {
	switch (day) {
		case "MONDAY":
			return "Montag";
		case "TUESDAY":
			return "Dienstag";
		case "WEDNESDAY":
			return "Mittwoch";
		case "THURSDAY":
			return "Donnerstag";
		case "FRIDAY":
			return "Freitag";
		case "SATURDAY":
			return "Samstag";
		case "SUNDAY":
			return "Sonntag";
		case "EVERYDAY":
			return "Jeden Tag";
		default:
			return null;
	}
};

export const mapAgeGroupEnums = (ageGroup) => {
	switch (ageGroup) {
		case "CHILDREN":
			return "Kinder";
		case "STUDENTS":
			return "Studenten";
		case "ADULTS":
			return "Erwachsene";
		case "ELDERLY":
			return "Senioren";
		case "EVERYBODY":
			return "Jeden";
		case "MEMBERS":
			return "Mitglieder";
		default:
			return null;
	}
};

export const mapAgeGroupToEnums = (ageGroup) => {
	switch (ageGroup) {
		case "Kinder":
			return "CHILDREN";
		case "Studenten":
			return "STUDENTS";
		case "Erwachsene":
			return "ADULTS";
		case "Senioren":
			return "ELDERLY";
		case "Jeden":
			return "EVERYBODY";
		case "Mitglieder":
			return "MEMBERS";
		default:
			return null;
	}
};

export const mapBillingIntervalEnums = (interval) => {
	switch (interval) {
		case "YEAR":
			return "Jahr";
		case "SEMESTER":
			return "Halbjahr";
		case "MONTH":
			return "Monat";
		case "COURSE":
			return "Kurs";
		default:
			return null;
	}
};

export const mapBillingIntervalToEnums = (interval) => {
	switch (interval) {
		case "Jahr":
			return "YEAR";
		case "Halbjahr":
			return "SEMESTER";
		case "Monat":
			return "MONTH";
		case "Kurs":
			return "COURSE";
		default:
			return null;
	}
};

export const getLogoURI = async (logoPath) => {
	const pathVariables = logoPath.split("/");

	let level = "public";
	let identityId = null;
	if (pathVariables[0] === "protected") {
		level = pathVariables.shift();
		identityId = pathVariables.shift();
	}
	return getUrl({
		key: pathVariables.join("/"),
		options: {
			accessLevel: level,
			targetIdentityId: identityId,
		},
	});
};

export const getSafeFullName = (contact) => {
	return `${contact.firstName ? contact.firstName : ""} ${
		contact.lastName ? contact.lastName : ""
	}`;
};

export const sortSchedulesByDay = (schedules) => {
	const dayIndexing = [
		"MONDAY",
		"TUESDAY",
		"WEDNESDAY",
		"THURSDAY",
		"FRIDAY",
		"SATURDAY",
		"SUNDAY",
		"EVERYDAY",
	];
	return schedules.slice().sort((a, b) => {
		const indexA = dayIndexing.indexOf(a.day);
		const indexB = dayIndexing.indexOf(b.day);
		if (indexA < indexB) return -1;
		if (indexA > indexB) return 1;
		return 0;
	});
};

export const appliesToAgeGroup = (ageGroups, offering) => {
	if (!offering.targetGroup) return true;
	const { from, to } = offering.targetGroup;

	if (to - from > 90) return false;

	return ageGroups.some((ageGroup) => {
		switch (ageGroup) {
			case "BABYS": // 0…2
				return !(to < 0 || from > 2);
			case "KINDERGARTEN": // 3…7
				return !(to < 3 || from > 7);
			case "CHILDREN": // 6…12
				return !(to < 6 || from > 12);
			case "TEENS": // 13…19
				return !(to < 13 || from > 19);
			case "ADULTS": // 18 … 60
				return !(to < 18 || from > 60);
			// eslint-disable-next-line no-irregular-whitespace
			case "ELDERLY": // 60 … 100
				return !(to < 60 || from > 100);
		}
	});
};

// returns true if intersect of from-to ranges is not empty
export const compareSchedule = (offeringSchedule, requiredSchedule) => {
	return (
		offeringSchedule.to &&
		offeringSchedule.from &&
		parseInt(offeringSchedule.from.replace(":", "")) >=
			parseInt(requiredSchedule.from.replace(":", "")) &&
		parseInt(offeringSchedule.to.replace("00:00", "24:00").replace(":", "")) <=
			parseInt(
				requiredSchedule.to.replace("00:00", "24:00").replace(":", ""),
			) &&
		offeringSchedule.day === requiredSchedule.day
	);
};

export const compareSchedules = (offeringSchedules, searchedForSchedules) =>
	offeringSchedules && searchedForSchedules
		? !!offeringSchedules.every((offeringSchedule) =>
				searchedForSchedules.find((requiredSchedule) =>
					compareSchedule(offeringSchedule, requiredSchedule),
				),
			)
		: false;

export const encodeFilter = (filter) => {
	return new URLSearchParams({
		...filter,
		schedules: filter.schedules?.map((schedule) => {
			return new URLSearchParams(schedule).toString();
		}),
	}).toString();
};

export const decodeFilter = (filter) => {
	const params = new URLSearchParams(filter);
	const schedules = params.get("schedules");
	const ageGroups = params.get("ageGroups");
	const offeringTypes = params.get("offeringTypes");
	return {
		searchText: params.get("searchText") || "",
		ageGroups: params.get("ageGroups") ? ageGroups.split(",") : [],
		offeringTypes: params.get("offeringTypes") ? offeringTypes.split(",") : [],
		schedules: schedules
			? schedules.split(",").map((scheduleString) => {
					const scheduleItem = new URLSearchParams(scheduleString);
					return {
						day: scheduleItem.get("day"),
						from: scheduleItem.get("from"),
						to: scheduleItem.get("to"),
					};
				})
			: [],
	};
};

export const decodeWidgetURI = (widgetURL) => {
	const url = new URL(widgetURL);
	const params = new URLSearchParams(url.search);

	const schedules = params.get("schedules");
	return {
		targetGroup: {
			from: params.get("from"),
			to: params.get("to"),
			male: params.get("male"),
			female: params.get("female"),
		},
		schedules: schedules
			? schedules.split(",").map((scheduleString) => {
					const scheduleItem = new URLSearchParams(scheduleString);
					return {
						day: scheduleItem.get("day"),
						from: scheduleItem.get("from"),
						to: scheduleItem.get("to"),
					};
				})
			: [],
		clubID: params.get("clubID") || "",
		color: params.get("color") || "",
		fontFamily: params.get("fontFamily") || "",
		category: params.get("category") || "",
		doUseComplexDesign: JSON.parse(params.get("doUseComplexDesign")),
	};
};

export const getCategoryImageURI = (category) => {
	const s3 = awsConfig().Storage.AWSS3;
	return `https://${s3.bucket}.s3.${s3.region}.amazonaws.com/public/categories/${category}.jpg`.replace(
		" ",
		"%20",
	);
};

/*export const getOfferingImageURI = (imagePath) => {
	return Storage.get(imagePath, { level: "public" });
};*/

export const getClubURI = (name, id, tenantPath) =>
	`/${tenantPath}/clubs/${encodeURIComponent(name)}?id=${id}`;

export const getAgeGroupToDisplay = (targetGroup) => {
	return `Alter ${targetGroup.from}-${targetGroup.to}`;
};

export const displayTargetGroup = (targetGroup) => {
	if (targetGroup.male && targetGroup.female) {
		return `Für alle zwischen ${targetGroup.from} und ${targetGroup.to}`;
	} else if (targetGroup.male) {
		return `Jungen und Männer zwischen ${targetGroup.from} und ${targetGroup.to}`;
	} else if (targetGroup.female) {
		return `Mädchen und Frauen zwischen ${targetGroup.from} und ${targetGroup.to}`;
	}
};

export const getSchedulesToDisplay = (schedules) => {
	const sortedSchedules = sortSchedulesByDay(schedules);
	return sortedSchedules
		.reduce(
			(prev, current) =>
				prev +
				`${mapDayEnumsToAbbrev(current.day)}: ${current.from}-${current.to}, `,
			"",
		)
		.slice(0, -2);
};

const matchesAgeGroup = (a, b) => {
	return a.from <= b.to && a.to >= b.from;
};

const compareTargetGroup = (a, b) => {
	return (
		(a.male || (!a.male && !b.male)) &&
		(a.female || (!a.female && !b.female)) &&
		matchesAgeGroup(a, b)
	);
};

export const getFilteredOfferings = (filter, offerings) => {
	const {
		ageGroups,
		schedules,
		searchText,
		category,
		clubID,
		targetGroup,
		offeringTypes = [],
	} = filter;
	return offerings.filter((offering) => {
		return (
			(!searchText ||
				offering.category.toLowerCase().includes(searchText.toLowerCase()) ||
				offering.title.toLowerCase().includes(searchText.toLowerCase()) ||
				(offering.description &&
					offering.description
						.toLowerCase()
						.includes(searchText.toLowerCase()))) &&
			(!(ageGroups && ageGroups.length !== 0) ||
				appliesToAgeGroup(ageGroups, offering)) &&
			(schedules.length === 0 ||
				compareSchedules(offering.schedules, schedules)) &&
			(!clubID || clubID === offering.clubID) &&
			(!category || category === offering.category) &&
			(offeringTypes.length === 0 || offeringTypes.includes("TRAINING")) &&
			(!targetGroup || compareTargetGroup(targetGroup, offering.targetGroup))
		);
	});
};

export const getFilteredCourses = (filter, courses) => {
	const {
		ageGroups,
		schedules,
		searchText,
		category,
		clubID,
		targetGroup,
		offeringTypes,
	} = filter;
	return courses.filter((course) => {
		return (
			(!searchText ||
				course.category.toLowerCase().includes(searchText.toLowerCase()) ||
				course.title.toLowerCase().includes(searchText.toLowerCase()) ||
				(course.description &&
					course.description
						.toLowerCase()
						.includes(searchText.toLowerCase()))) &&
			(!(ageGroups && ageGroups.length !== 0) ||
				appliesToAgeGroup(ageGroups, course)) &&
			(schedules.length === 0 ||
				compareSchedules(course.schedule.schedules, schedules)) &&
			(!clubID || clubID === course.clubID) &&
			(!category || category === course.category) &&
			(offeringTypes.length === 0 || offeringTypes.includes("COURSE")) &&
			(!targetGroup || compareTargetGroup(targetGroup, course.targetGroup))
		);
	});
};

const isTargetGroupEqual = (a, b) => {
	return (
		a &&
		b &&
		a.male === b.male &&
		a.female === b.female &&
		a.from === b.from &&
		a.to === b.to
	);
};

const areSchedulesEqual = (a, b) =>
	a.length === b.length &&
	a.every((c) =>
		b.find((d) => c.day === d.day && c.from === d.from && c.to === d.to),
	) &&
	b.every((c) =>
		a.find((d) => c.day === d.day && c.from === d.from && c.to === d.to),
	);

const areCostsEqual = (a, b) =>
	a.length === b.length &&
	a.every((c) =>
		b.find(
			(d) =>
				c.billingInterval === d.billingInterval &&
				c.ageGroup === d.ageGroup &&
				c.cost === d.cost,
		),
	) &&
	b.every((c) =>
		a.find(
			(d) =>
				c.billingInterval === d.billingInterval &&
				c.ageGroup === d.ageGroup &&
				c.cost === d.cost,
		),
	);

export const getCommonAttributes = (offerings) =>
	offerings.length > 0
		? offerings.reduce((prev, current) => {
				return {
					title: prev.title && prev.title === current.title ? prev.title : null,
					category:
						prev.category && prev.category === current.category
							? prev.category
							: null,
					description:
						prev.description && prev.description === current.description
							? prev.description
							: null,
					district:
						prev.district && prev.district === current.district
							? prev.district
							: null,
					hint: prev.hint && prev.hint === current.hint ? prev.hint : null,
					targetGroup:
						prev.targetGroup &&
						isTargetGroupEqual(prev.targetGroup, current.targetGroup)
							? prev.targetGroup
							: null,
					schedules:
						prev.schedules &&
						current.schedules &&
						areSchedulesEqual(prev.schedules, current.schedules)
							? prev.schedules
							: null,
					costs:
						prev.costs &&
						current.costs &&
						areCostsEqual(prev.costs, current.costs)
							? prev.costs
							: null,
					contact:
						prev.contact &&
						current.contact &&
						prev.contact.id === current.contact.id
							? prev.contact
							: null,
					location:
						prev.location &&
						current.location &&
						prev.location.id === current.location.id
							? prev.location
							: null,
				};
			}, offerings[0])
		: {
				title: null,
				category: null,
				description: null,
				district: null,
				hint: null,
				targetGroup: null,
				schedules: null,
				costs: null,
				contact: null,
				location: null,
			};

export const getOfferingsById = (ids, offerings) =>
	offerings.filter((offering) => ids.some((id) => offering.id === id));

export const getImageDimensions = (dataUrl) =>
	new Promise((resolve) => {
		const img = new Image();

		img.onload = () => {
			resolve({
				height: img.height,
				width: img.width,
			});
		};
		img.src = dataUrl;
	});

export const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const getEventScheduleForDisplay = (schedule) => {
	return `${new Date(schedule.from).toLocaleDateString()} - ${new Date(
		schedule.to,
	).toLocaleDateString()}`;
};

export const stripHtml = (html) => {
	let tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
};

export const getLinkToDetailsForOffering = (offering, tenantPath) => {
	return offering.type === "TRAINING"
		? `/${tenantPath}/offering/${encodeURIComponent(offering.title)}?id=${
				offering.id
			}`
		: `/${tenantPath}/course/${encodeURIComponent(offering.title)}?id=${
				offering.id
			}`;
};

export const getCurrentDateAsISOString = () => new Date().toISOString();

const getReadableAddressFromLocation = (location) => {
	return `${location.street} ${location.houseNumber} ${location.zipCode} ${location.city}`;
};

const getGeolocationFromLocation = (location) => {
	const readableAddress = getReadableAddressFromLocation(location);

	const searchOptions = {
		countries: ["DEU"],
		maxResults: 1,
		//searchAreaConstraints: [SWLongitude, SWLatitude, NELongitude, NELatitude], // Bounding box to search inside of
	};

	return Geo.searchByText(readableAddress, searchOptions);
};

export const getLocationAsGeoData = async (location) => {
	try {
		const searchResult = await getGeolocationFromLocation(location);
		if (!searchResult.length) {
			return;
		}

		const coordinates = searchResult[0].geometry.point;
		return {
			longitude: coordinates[0],
			latitude: coordinates[1],
		};
	} catch (error) {
		console.error(error);
	}
};

export const getAverageViewPoint = (coordinates) => {
	const acc = coordinates.reduce(
		(prev, curr) => ({
			longitude: prev.longitude + curr.longitude,
			latitude: prev.latitude + curr.latitude,
		}),
		{ longitude: 0, latitude: 0 },
	);
	return {
		longitude: acc.longitude / coordinates.length,
		latitude: acc.latitude / coordinates.length,
	};
};

export const costPerBillingIntervalMessage = (cost, interval) => {
	if (cost === 0 || cost === "0") return "kostenlos";
	switch (interval) {
		case "YEAR":
		case "SEMESTER":
		case "MONTH":
			return `${cost} € im ${mapBillingIntervalEnums(interval)}`;
		case "COURSE":
			return `${cost} € pro Kurs`;
		default:
			return null;
	}
};

export const getFullHomepageURL = (homepage) => {
	return homepage.startsWith("http") ? homepage : `https://${homepage}`;
};

export const buildImageKey = (imageFile) => {
	return imageFile.name.substring(0, 10) + "_" + Date.now();
};

export const getImageURL = async (image) => {
	let identityId, imageKey;
	try {
		identityId = JSON.parse(image).identityId;
		imageKey = JSON.parse(image).imageKey;
	} catch (error) {
		console.error(error);
		imageKey = image;
	}
	try {
		const result = await getUrl({
			key: imageKey,
			options: {
				accessLevel: "protected",
				targetIdentityId: identityId,
			},
		});
		return result?.url?.toString();
	} catch (error) {
		console.error(error);
	}
};

/**
 * Function to get the value of a query parameter by name
 * @param {string} parameterName - The name of the query parameter, defaults to "id"
 * @returns {string|null} - The value of the query parameter or null if it does not exist
 */
export function getQueryParam(parameterName = "id") {
	// Create a URL object from the current URL
	const url = new URL(window.location.href);

	// Use URLSearchParams to get the query parameters
	const params = new URLSearchParams(url.search);

	// Get the value of the 'id' query parameter
	return params.get(parameterName);
}
