import React, { useState } from "react";

import withStyles from "@mui/styles/withStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// custom components
import CustomDatetimeAdapter from "../components/CustomDatetimeAdapter";
import CustomInputAdapter from "../components/CustomInputAdapter";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import FieldAdapter from "../components/FieldAdapter";

import ImageSelectAdapter from "../components/ImageSelectAdapter";
import EditorAdapter from "../components/EditorAdapter";
import { getImageURL } from "utils/Utils";

const style = (theme) => ({
	...modalStyle(theme),
	modalRootExample: {
		"& > div:first-child": {
			display: "none",
		},
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	removeButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
});

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

const EventModal = (props) => {
	const [open, setOpen] = useState(false);
	const [event, setEvent] = useState();
	const [imageURI, setImageURI] = useState();

	const onSubmit = async (values) => {
		props.onSubmit(values);
		handleClose();
	};

	const handleClickOpen = async () => {
		const { event } = props;
		setEvent({ ...event, description: event ? event.descriptionAsHtml : "" });
		if (event && event.image) setImageURI(event.image);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const { classes, button } = props;

	return (
		<div>
			<div onClick={() => handleClickOpen()}>{button}</div>
			{open && (
				<Dialog
					classes={{
						root: `${classes.modalRoot} ${classes.modalRootExample}`,
						paper: `${classes.modal} ${classes.modalLarge}`,
					}}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => handleClose("scrollingModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<Form
						onSubmit={onSubmit}
						initialValues={event}
						mutators={{ ...arrayMutators }}
						render={({ handleSubmit, form, submitting, pristine, values }) => (
							<form onSubmit={handleSubmit}>
								<DialogTitle
									id="classic-modal-slide-title"
									className={classes.modalHeader}
								>
									<Button
										simple
										className={classes.modalCloseButton}
										key="close"
										aria-label="Close"
										onClick={() => handleClose("scrollingModal")}
									>
										{" "}
										<Close className={classes.modalClose} />
									</Button>
									<h4 className={classes.modalTitle}>
										{values.name ? values.name : "Neue Veranstaltung"}
									</h4>
								</DialogTitle>
								<DialogContent
									id="classic-modal-slide-description"
									className={classes.modalBody}
								>
									<GridContainer>
										<GridItem>
											<FieldAdapter
												name="imageFile"
												component={ImageSelectAdapter}
												type="file"
												id="imageFile"
												imagePath={imageURI}
											/>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="name"
												component={CustomInputAdapter}
												type="text"
												placeholder="Name"
												label="Name"
												validate={(value) => !value && "Eingabe erforderlich"}
											/>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="tagline"
												component={CustomInputAdapter}
												type="text"
												placeholder="Tagline"
												label="Tagline"
												inputProps={{
													multiline: true,
													maxRows: 10,
												}}
											/>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="description"
												component={EditorAdapter}
												label="Beschreibung"
												id="description"
											/>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="details"
												component={CustomInputAdapter}
												type="text"
												placeholder="Details"
												label="Details"
												inputProps={{
													multiline: true,
													maxRows: 10,
												}}
											/>
										</GridItem>

										<GridItem xs={12} sm={6} md={6} lg={6}>
											<h4>Datum</h4>
										</GridItem>
										<GridItem xs={12}>
											<GridContainer>
												<GridItem xs={3} sm={3} md={3} lg={3}>
													<FieldAdapter
														name={"schedule.from"}
														component={CustomDatetimeAdapter}
														label="Start"
														dateFormat={"YYYY-MM-DD"}
														timeFormat={false}
														validate={(value) => {
															const match =
																/^[0-9][0-9][0-9][0-9]-([1][0-2]|[0][1-9])-[0-3][0-9]$/;
															const matchesPattern = match.test(value);
															if (!value) {
																return "Eingabe erforderlich";
															} else if (!matchesPattern) {
																return "Datum muss von Format YYYY-MM-DD sein";
															}
														}}
													/>
												</GridItem>
												<GridItem xs={3} sm={3} md={3} lg={3}>
													<FieldAdapter
														name={"schedule.to"}
														component={CustomDatetimeAdapter}
														label="Ende"
														dateFormat={"YYYY-MM-DD"}
														timeFormat={false}
														validate={(value) => {
															const match =
																/^[0-9][0-9][0-9][0-9]-([1][0-2]|[0][1-9])-[0-3][0-9]$/;
															const matchesPattern = match.test(value);
															if (!value) {
																return "Eingabe erforderlich";
															} else if (!matchesPattern) {
																return "Datum muss von Format YYYY-MM-DD sein";
															}
														}}
													/>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</DialogContent>
								<DialogActions className={classes.modalFooter}>
									<Button
										onClick={form.reset}
										disabled={submitting || pristine}
										color="secondary"
									>
										Zurücksetzen
									</Button>
									<Button color="primary" type="submit" disabled={submitting}>
										Speichern
									</Button>
								</DialogActions>
							</form>
						)}
					/>
				</Dialog>
			)}
		</div>
	);
};

EventModal.propTypes = {
	classes: PropTypes.object,
	event: PropTypes.object,
	onSubmit: PropTypes.func,
	button: PropTypes.node,
};

export default withStyles(style)(EventModal);
