import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import withStyles from "@mui/styles/withStyles";

import { green, amber, red } from "@mui/material/colors";

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const styles1 = () => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: red[600],
	},
	info: {
		backgroundColor: green[600],
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: "20px",
	},
	message: {
		display: "flex",
		alignItems: "center",
	},
});

function MySnackbarContent(props) {
	const { classes, className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={classNames(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={classNames(classes.icon, classes.iconVariant)} />
					{message}
				</span>
			}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					className={classes.close}
					onClick={onClose}
					size="large"
				>
					<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	);
}

MySnackbarContent.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = () => ({
	margin: {
		margin: "0 0 50px 0",
	},
});

function CustomizedSnackbar(props) {
	const { open, message, variant, onClose } = props;
	return (
		<div>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				open={open}
				autoHideDuration={5000}
				onClose={onClose}
			>
				<div>
					<MySnackbarContentWrapper
						onClose={onClose}
						variant={variant}
						message={message}
					/>
				</div>
			</Snackbar>
		</div>
	);
}

CustomizedSnackbar.propTypes = {
	open: PropTypes.bool,
	message: PropTypes.string,
	variant: PropTypes.string,
	onClose: PropTypes.func,
};

export default withStyles(styles2)(CustomizedSnackbar);
