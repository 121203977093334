/**
 *
 * DeleteDialog
 *
 */

import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// custom components
import CustomDropdownAdapter from "customComponents/CustomDropdownAdapter/CustomDropdownAdapter";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import { Form } from "react-final-form";

import { Field } from "react-final-form-html5-validation";
/* eslint-disable react/prefer-stateless-function */

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

class DeleteDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			liveDemo: false,
		};
	}

	handleClickOpen(modal) {
		const x = [];
		x[modal] = true;
		this.setState(x);
	}

	handleClose = (modal) => {
		const x = [];
		x[modal] = false;
		this.setState(x);
	};

	handleDelete = (values) => {
		const { onDelete, objectId } = this.props;
		onDelete(objectId, values.selectedReplacement);
		this.handleClose("liveDemo");
	};

	render() {
		const { classes, message, title, list, getKey, getValue, getName } =
			this.props;

		return (
			<div>
				<Button
					simple
					color="primary"
					onClick={() => this.handleClickOpen("liveDemo")}
				>
					<i className="fas fa-trash-alt" />
				</Button>
				{this.state.liveDemo && (
					<Dialog
						classes={{
							root: classes.modalRoot,
							paper: classes.modal,
						}}
						open={this.state.liveDemo}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleClose("liveDemo")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<Form
							onSubmit={this.handleDelete}
							initialValues={{
								selectedReplacement: null,
							}}
							render={({ handleSubmit, form, submitting }) => (
								<form onSubmit={handleSubmit}>
									<DialogTitle
										id="classic-modal-slide-title"
										className={classes.modalHeader}
									>
										<Button
											simple
											className={classes.modalCloseButton}
											key="close"
											aria-label="Close"
											onClick={() => this.handleClose("liveDemo")}
										>
											{" "}
											<Close className={classes.modalClose} />
										</Button>
										<h4 className={classes.modalTitle}>{title}</h4>
									</DialogTitle>
									<DialogContent
										id="classic-modal-slide-description"
										className={classes.modalBody}
									>
										{message}
										{list && (
											<GridContainer>
												<GridItem>
													<Field
														name="selectedReplacement"
														component={CustomDropdownAdapter}
														getKey={getKey}
														getValue={getValue}
														getName={getName}
														type="text"
														placeholder="Ersatz"
														label="Ersatz"
														dropDownList={list}
														id="selectedReplacement"
														nonReactInputProps={{
															required: true,
														}}
													/>
												</GridItem>
											</GridContainer>
										)}
									</DialogContent>
									<DialogActions className={classes.modalFooter}>
										<Button
											onClick={() => {
												form.reset();
												this.handleClose("liveDemo");
											}}
											color="secondary"
										>
											Abbrechen
										</Button>
										<Button color="danger" type="submit" disabled={submitting}>
											Löschen
										</Button>
									</DialogActions>
								</form>
							)}
						/>
					</Dialog>
				)}
			</div>
		);
	}
}

DeleteDialog.propTypes = {
	classes: PropTypes.object,
	title: PropTypes.string,
	onDelete: PropTypes.func,
	objectId: PropTypes.string,
	message: PropTypes.string,
	list: PropTypes.array,
	getKey: PropTypes.func,
	getValue: PropTypes.func,
	getName: PropTypes.func,
};

export default withStyles(modalStyle)(DeleteDialog);
