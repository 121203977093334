import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, { useState, useRef, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress, {
	CircularProgressProps,
  } from '@mui/material/CircularProgress';

import ReactCrop, {
	centerCrop,
	makeAspectCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";

import "react-image-crop/dist/ReactCrop.css";
import CircularWithValueLabel from "customComponents/Progress/CircularProgress";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

export default function CropImageModal(props) {
	const [imgSrc, setImgSrc] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [progress, setProgress] = useState(0)
	const previewCanvasRef = useRef(null);
	const imgRef = useRef(null);

	const [crop, setCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);
	const [aspect, setAspect] = useState(1); // Set aspect to 1 for square cropping

	useEffect(() => {
		setProgress(0);
		setSelectedFile(null);
		setImgSrc("");
		setCompletedCrop(undefined);
	}, [props.open])

	function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
		return centerCrop(
			makeAspectCrop(
				{
					unit: "%",
					width: 90,
				},
				aspect,
				mediaWidth,
				mediaHeight,
			),
			mediaWidth,
			mediaHeight,
		);
	}

	function useDebounceEffect(fn, waitTime, deps) {
		useEffect(() => {
			const t = setTimeout(() => {
				fn.apply(undefined, deps);
			}, waitTime);

			return () => {
				clearTimeout(t);
			};
		}, deps);
	}

	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined);
			const file = e.target.files[0];
			setSelectedFile(file);
			const reader = new FileReader();
			reader.addEventListener("load", () =>
				setImgSrc(reader.result?.toString() || ""),
			);
			reader.readAsDataURL(file);
		}
	}

	function onImageLoad(e) {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	}

	async function resizeImage(blob, maxSize) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (event) => {
				const img = new Image();
				img.onload = () => {
					const canvas = document.createElement("canvas");
					const ctx = canvas.getContext("2d");
					let { width, height } = img;
					const resize = () => {
						const ratio = Math.sqrt(maxSize / blob.size);
						width *= ratio;
						height *= ratio;
						canvas.width = width;
						canvas.height = height;
						ctx.drawImage(img, 0, 0, width, height);
						canvas.toBlob((resizedBlob) => {
							// Calculate progress
							const resizedRatio = resizedBlob.size / blob.size;
							const progressValue = Math.min(
								100,
								Math.max(1, Math.round(resizedRatio * 100)),
							);
							setProgress(progressValue);
							if (resizedBlob.size <= maxSize || width <= 1 || height <= 1) {
								setProgress(100); // Final update to 100 when resizing is done
								resolve(resizedBlob);
							} else {
								// Further reduce size if needed
								const newBlob = new Blob([resizedBlob], { type: blob.type });
								blob = newBlob;
								resize();
							}
						}, blob.type);
					};
					resize();
				};
				img.src = event.target.result;
			};
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	}

	async function handleUpload() {
		const image = imgRef.current;
		const previewCanvas = previewCanvasRef.current;
		if (!image || !previewCanvas || !completedCrop || !selectedFile) {
			throw new Error("Crop canvas does not exist or no file selected");
		}
	
		// This will size relative to the uploaded image size.
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
	
		const offscreen = new OffscreenCanvas(
			completedCrop.width * scaleX,
			completedCrop.height * scaleY,
		);
		const ctx = offscreen.getContext("2d");
		if (!ctx) {
			throw new Error("No 2d context");
		}
	
		ctx.drawImage(
			previewCanvas,
			0,
			0,
			previewCanvas.width,
			previewCanvas.height,
			0,
			0,
			offscreen.width,
			offscreen.height,
		);

		let blob = await offscreen.convertToBlob({
			type: "image/png",
		});
	
		if (blob.size > props.maxSize) {
			blob = await resizeImage(blob, props.maxSize);
		}
	
		blob.name = selectedFile.name
		props.handleSelectImage(blob);
	}

	useDebounceEffect(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				canvasPreview(
					imgRef.current,
					previewCanvasRef.current,
					completedCrop,
					scale,
					rotate,
				);
			}
		},
		100,
		[completedCrop, scale, rotate],
	);

	return (
		<>
			<Modal
				open={props.open}
				onClose={props.handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div className="App">
						<h6>Bild auswählen:</h6>
						<div className="Crop-Controls">
							<input type="file" accept="image/*" onChange={onSelectFile} />
						</div>
						{!!imgSrc && (
							<>
								<h6>Bild zuschneiden:</h6>
								<ReactCrop
									circularCrop={!props.rectangular}
									crop={crop}
									onChange={(_, percentCrop) => setCrop(percentCrop)}
									onComplete={(c) => setCompletedCrop(c)}
									aspect={aspect}
									minHeight={100}
								>
									<img
										ref={imgRef}
										alt="Crop me"
										src={imgSrc}
										style={{
											transform: `scale(${scale}) rotate(${rotate}deg)`,
										}}
										onLoad={onImageLoad}
									/>
								</ReactCrop>
							</>
						)}
						{!!completedCrop && (
							<>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: 10,
										flexDirection: "column",
										alignItems: "center",
									}}
								>
									<h6 style={{ alignSelf: "flex-start" }}>Vorschau:</h6>
									<canvas
										ref={previewCanvasRef}
										style={{
											border: "1px solid rgba(0,0,0,0.7)",
											borderRadius: `${props.rectangular ? "0%" : "50%"}`,
											objectFit: "contain",
											width: 300,
											height: 300,
										}}
									/>
								</div>
								<div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: 20}}>
									{progress ? (
										<CircularWithValueLabel value={progress} />
									) : (
										<Button
											component="label"
											role={undefined}
											variant="contained"
											tabIndex={-1}
											startIcon={<CloudUploadIcon />}
											onClick={handleUpload}
										>
											Hochladen
										</Button>
									)}
								</div>
							</>
						)}
					</div>
				</Box>
			</Modal>
		</>
	);
}
