import React from "react";
import PropTypes from "prop-types";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import withStyles from "@mui/styles/withStyles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle";

const style = () => ({
	...profilePageStyle,
	...customSelectStyle,
});

const FilterSelector = (props) => {
	const {
		classes,
		filterValues,
		handleFilterSelect,
		value,
		label,
		getDisplayValue,
	} = props;

	return (
		<FormControl
			variant="standard"
			fullWidth
			className={classes.selectFormControl}
		>
			<InputLabel
				htmlFor="category-filter-select"
				className={classes.selectLabel}
			>
				{label}
			</InputLabel>
			<Select
				variant="standard"
				multiple
				disabled={filterValues.length === 0}
				value={value}
				onChange={handleFilterSelect}
				MenuProps={{
					className: classes.selectMenu,
					classes: { paper: classes.selectPaper },
				}}
				classes={{ select: classes.select }}
				inputProps={{
					name: "categoryFilterSelect",
					id: "category-filter-select",
				}}
			>
				{filterValues
					.filter((item) => getDisplayValue(item))
					.sort((a, b) => getDisplayValue(a).localeCompare(getDisplayValue(b)))
					.map((value) => (
						<MenuItem
							classes={{
								root: classes.selectMenuItem,
								selected: classes.selectMenuItemSelectedMultiple,
							}}
							key={value.id ? value.id : value}
							value={value.id ? value.id : value}
						>
							{getDisplayValue(value)}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
};

FilterSelector.propTypes = {
	classes: PropTypes.object,
	filterValues: PropTypes.array,
	value: PropTypes.array,
	handleFilterSelect: PropTypes.func,
	label: PropTypes.string,
	getDisplayValue: PropTypes.func,
};

export default withStyles(style)(FilterSelector);
