import React from "react";
import PropTypes from "prop-types";

import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";

const LoadingIndicator = ({ color, size, style }) => {
	return <Spinner color={color} size={size} style={style} />;
};

LoadingIndicator.propTypes = {
	color: PropTypes.string,
	size: PropTypes.number,
	style: PropTypes.object,
};

export default LoadingIndicator;
