import { makeAutoObservable } from "mobx";

export class UIStore {
	successMessage = null;
	errorMessage = null;

	constructor() {
		makeAutoObservable(this);
	}

	setSuccessMessage = (message) => {
		this.successMessage = message;
	};

	resetSuccessMessage = () => {
		this.successMessage = null;
	};

	setErrorMessage = (message) => {
		this.errorMessage = message;
	};

	resetErrorMessage = () => {
		this.errorMessage = null;
	};
}
