import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle";
import CheckboxStyles from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import {
	dangerColor,
	primaryColor,
	whiteColor,
} from "assets/jss/material-kit-pro-react";

export default () => ({
	...profilePageStyle,
	...customSelectStyle,
	CheckboxStyles,
	imgWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	imgOverlay: {
		display: "flex",
		justifyContent: "center",
		width: "160px",
		height: "160px",
		position: "absolute",
		zIndex: 1,
		transform: "translate3d(0, -50%, 0)",

		color: whiteColor,
		fontSize: 12,
		fontWeight: 400,
		textTransform: "uppercase",
		letterSpacing: 0,
		lineHeight: 1.42857143,
		cursor: "pointer",

		"&:hover": {
			backgroundColor: primaryColor[0],
			opacity: 0.98,
			cursor: "pointer",

			"& > span": {
				visibility: "visible !important",
				color: whiteColor,
				alignSelf: "center",
				fontSize: "small",
			},
			"& > svg": {
				visibility: "visible !important",
			},
		},
	},
	imgOverlayTxt: {
		visibility: "hidden",
	},
	fileInput: {
		display: "none",
	},
	logoErrorMessage: {
		position: "absolute",
		marginLeft: "60%",
		textAlign: "left",
		color: "red",
		fontWeight: 600,
	},
	dummy: {
		display: "flex",
		width: "160px",
		height: "160px",
		position: "absolute",
		zIndex: 1,
		transform: "translate3d(0, -50%, 0)",

		color: whiteColor,
		fontSize: 12,
		fontWeight: 400,
		textTransform: "uppercase",
		letterSpacing: 0,
		lineHeight: 1.42857143,

		backgroundColor: dangerColor[0],
		opacity: 0.98,
		cursor: "pointer",

		"& > span": {
			visibility: "visible !important",
			color: whiteColor,
			alignSelf: "center",
			fontSize: "small",
			lineHeight: "normal",
			margin: "0 10px 0 10px",
		},
		"& > svg": {
			visibility: "visible !important",
		},
	},
	logoIconOverlay: {
		fontSize: 28,
		color: whiteColor,
		position: "absolute",
		top: 2,
		left: 4,
		visibility: "hidden",
	},
});
