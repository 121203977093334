import { generateClient } from "aws-amplify/api";
import * as queries from "./customQueries";
import * as mutations from "./customMutations";
import { getCurrentDateAsISOString } from "../utils/Utils";

const client = generateClient();

const getAuthToken = () => {
	if (process.env.NODE_ENV === "development") {
		return "5_}vKFogcz1XpK.x29a#EFat~}j5j2";
	}
	if (process.env.NODE_ENV === "production") {
		return "kfwmxR5d#noKL_=#rxM9dDte=G=D]9";
	}
};

export class GraphqlApi {
	fetchEvents = async (events, nextToken = null, filter = {}) => {
		const params = {
			query: queries.listEvents,
			authMode: "AWS_LAMBDA",
			authToken: getAuthToken(),
			variables: {
				filter: {
					to: {
						ge: getCurrentDateAsISOString(),
					},
					...filter,
				},
				courseFilter: {
					finalDate: {
						ge: getCurrentDateAsISOString(),
					},
				},
			},
			nextToken,
		};

		const result = await client.graphql(params);
		const fetchedEvents = result.data.listEvents.items;
		if (events) fetchedEvents.push(...events);
		params.nextToken = result.data.listEvents.nextToken;
		if (params.nextToken)
			return this.fetchEvents(fetchedEvents, params.nextToken);
		return fetchedEvents;
	};

	fetchHighlightedEvents = async (events, nextToken = null, filter = {}) => {
		const params = {
			query: queries.listEvents,
			authMode: "AWS_LAMBDA",
			authToken: getAuthToken(),
			variables: {
				filter: {
					highlight: {
						eq: true,
					},
					to: {
						ge: getCurrentDateAsISOString(),
					},
					...filter,
				},
				courseFilter: {
					finalDate: {
						ge: getCurrentDateAsISOString(),
					},
				},
			},
			nextToken,
		};

		const result = await client.graphql(params);
		const fetchedEvents = result.data.listEvents.items;
		if (events) fetchedEvents.push(...events);
		params.nextToken = result.data.listEvents.nextToken;
		if (params.nextToken)
			return this.fetchHighlightedEvents(fetchedEvents, params.nextToken);
		return fetchedEvents;
	};

	fetchClubs = async (clubs, nextToken = null, filter = {}) => {
		const params = {
			query: queries.listClubs,
			authMode: "AWS_LAMBDA",
			authToken: getAuthToken(),
			nextToken,
			variables: {
				filter,
			},
		};

		const result = await client.graphql(params);
		const fetchedClubs = result.data.listClubs.items;
		if (clubs) fetchedClubs.push(...clubs);
		params.nextToken = result.data.listClubs.nextToken;
		if (params.nextToken)
			return this.fetchClubs(fetchedClubs, params.nextToken);
		return fetchedClubs;
	};

	fetchClubsForHeader = async (clubs, nextToken = null, filter = {}) => {
		const params = {
			query: queries.listClubsForHeader,
			authMode: "AWS_LAMBDA",
			authToken: getAuthToken(),
			nextToken,
			variables: {
				filter,
			},
		};

		const result = await client.graphql(params);
		const fetchedClubs = result.data.listClubs.items;
		if (clubs) fetchedClubs.push(...clubs);
		params.nextToken = result.data.listClubs.nextToken;
		if (params.nextToken)
			return this.fetchClubsForHeader(fetchedClubs, params.nextToken);
		return fetchedClubs;
	};

	fetchTenantSupport = async (key) => {
		const params = {
			query: `
				query GetTenant($key: String!) {
					getTenant(key: $key) {
						support {
							email
							homepage
							name
							phone
						}
						license {
							tier
							licenseLimits
						}
					}
				}
			`,
			authToken: getAuthToken(),
			variables: {
				key,
			},
		};
		try {
			const result = await client.graphql(params);
			return result.data.getTenant;
		} catch (error) {
			console.error(error);
		}
	};

	fetchClub = async (id) => {
		const params = {
			query: queries.getClub,
			authToken: getAuthToken(),
			variables: {
				id,
				courseFilter: {
					finalDate: {
						ge: getCurrentDateAsISOString(),
					},
				},
			},
		};
		try {
			const result = await client.graphql(params);
			const nextToken = result.data.getClub.offerings.nextToken;
			if (nextToken) {
				const prevOfferings = result.data.getClub.offerings.items;
				const offeringsByClub = await this.fetchOfferingsByClub(
					id,
					prevOfferings,
					nextToken,
				);
				result.data.getClub.offerings.items = [...offeringsByClub];
			}
			return result.data.getClub;
		} catch (error) {
			console.error(error);
		}
	};

	fetchOfferingsByClub = async (
		clubID,
		prevOfferings = [],
		nextToken = null,
	) => {
		const params = {
			query: queries.offeringsByClub,
			authToken: getAuthToken(),
			variables: {
				clubID,
				nextToken,
			},
		};
		try {
			const result = await client.graphql(params);
			if (result.data.offeringsByClub) {
				const offerings = [
					...result.data.offeringsByClub.items,
					...prevOfferings,
				];
				const nextToken = result.data.offeringsByClub.nextToken;
				if (nextToken)
					return this.fetchOfferingsByClub(clubID, offerings, nextToken);
				return offerings;
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
		}
	};

	fetchOfferingsByClubAsAdmin = async (
		clubID,
		prevOfferings = [],
		nextToken = null,
	) => {
		const params = {
			query: queries.offeringsByClubAsAdmin,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				clubID,
				nextToken,
			},
		};
		try {
			const result = await client.graphql(params);
			if (result.data.offeringsByClub) {
				const offerings = [
					...result.data.offeringsByClub.items,
					...prevOfferings,
				];
				const nextToken = result.data.offeringsByClub.nextToken;
				if (nextToken)
					return this.fetchOfferingsByClubAsAdmin(clubID, offerings, nextToken);
				return offerings;
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
		}
	};

	// this is used in MyClub, thus information about tenantAdminGroup and clubAdminGroup is required
	fetchClubAsAdmin = async (id, filter = {}) => {
		const params = {
			query: queries.getClubAsAdmin,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				id,
				filter,
			},
		};
		const result = await client.graphql(params);

		const nextToken = result.data.getClub.offerings.nextToken;
		if (nextToken) {
			const prevOfferings = result.data.getClub.offerings.items;
			const offeringsByClub = await this.fetchOfferingsByClubAsAdmin(
				id,
				prevOfferings,
				nextToken,
			);
			result.data.getClub.offerings.items = [...offeringsByClub];
		}
		return result.data.getClub;
	};

	fetchClubsAsAdmin = async (filter = {}) => {
		const params = {
			query: queries.listClubs,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				filter,
			},
		};

		const result = await client.graphql(params);
		return result.data.listClubs.items[0];
	};

	//this method is used in the dashboard for tenants to also load the club admins
	fetchClubsAsTenant = async (clubs, nextToken = null, filter = {}) => {
		const params = {
			query: queries.listClubsAsTenant,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			nextToken,
			variables: {
				filter,
			},
		};
		const result = await client.graphql(params);
		const fetchedClubs = result.data.listClubs.items;
		if (clubs) fetchedClubs.push(clubs);
		params.nextToken = result.data.listClubs.nextToken;
		if (params.nextToken)
			return this.fetchClubs(fetchedClubs, params.nextToken);
		return fetchedClubs;
	};

	fetchClubAsTenant = async (id) => {
		const params = {
			query: queries.getClubAsTenant,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				id,
			},
		};
		try {
			const result = await client.graphql(params);
			const nextToken = result.data.getClub.offerings.nextToken;
			if (nextToken) {
				const prevOfferings = result.data.getClub.offerings.items;
				const offeringsByClub = await this.fetchOfferingsByClubAsAdmin(
					id,
					prevOfferings,
					nextToken,
				);
				result.data.getClub.offerings.items = [...offeringsByClub];
			}
			return result.data.getClub;
		} catch (error) {
			console.error(error);
		}
	};

	fetchAllOfferings = async (
		prevOfferings = [],
		nextToken = null,
		filter = {},
	) => {
		const params = {
			query: queries.listOfferings,
			authToken: getAuthToken(),
			variables: {
				nextToken,
				filter,
			},
		};
		try {
			const result = await client.graphql(params);
			if (result.data.listOfferings) {
				const offerings = [
					...result.data.listOfferings.items,
					...prevOfferings,
				];
				const nextToken = result.data.listOfferings.nextToken;
				if (nextToken)
					return this.fetchAllOfferings(offerings, nextToken, filter);
				return offerings;
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
		}
	};

	fetchOfferings = async (limit, nextToken, filter = {}) => {
		const params = {
			query: queries.listOfferings,
			authToken: getAuthToken(),
			variables: {
				limit,
				nextToken,
				filter,
			},
		};
		try {
			const result = await client.graphql(params);
			return result.data;
		} catch (error) {
			console.error(error);
		}
	};

	fetchOffering = async (id) => {
		const params = {
			query: queries.getOffering,
			authToken: getAuthToken(),
			variables: {
				id: id,
			},
		};
		try {
			const result = await client.graphql(params);
			return result.data.getOffering;
		} catch (error) {
			console.error(error);
		}
	};

	fetchCourse = async (id) => {
		const params = {
			query: queries.getCourse,
			authToken: getAuthToken(),
			variables: {
				id: id,
			},
		};
		try {
			const result = await client.graphql(params);
			return result.data.getCourse;
		} catch (error) {
			console.error(error);
		}
	};

	fetchAllCourses = async (prevCourses = [], nextToken = null, filter = {}) => {
		const params = {
			query: queries.listCourses,
			authToken: getAuthToken(),
			variables: {
				nextToken,
				filter: {
					finalDate: {
						ge: getCurrentDateAsISOString(),
					},
					...filter,
				},
			},
		};
		try {
			const result = await client.graphql(params);
			if (result.data.listCourses) {
				const courses = [...result.data.listCourses.items, ...prevCourses];
				const nextToken = result.data.listCourses.nextToken;
				if (nextToken) return this.fetchAllCourses(courses, nextToken, filter);
				return courses;
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
		}
	};

	fetchOfferingCategories = async (
		prevOfferings = [],
		nextToken = null,
		filter = {},
	) => {
		const params = {
			query: queries.listOfferingsWithCategory,
			authToken: getAuthToken(),
			variables: {
				nextToken,
				filter,
			},
		};
		try {
			const result = await client.graphql(params);
			if (result.data.listOfferings) {
				const offerings = [
					...result.data.listOfferings.items,
					...prevOfferings,
				];
				const nextToken = result.data.listOfferings.nextToken;
				if (nextToken)
					return this.fetchOfferingCategories(offerings, nextToken, filter);
				return offerings;
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
		}
	};

	fetchCourseCategories = async (
		prevCourses = [],
		nextToken = null,
		filter = {},
	) => {
		const params = {
			query: queries.listCoursesWithCategory,
			authToken: getAuthToken(),
			variables: {
				nextToken,
				filter: {
					finalDate: {
						ge: getCurrentDateAsISOString(),
					},
					...filter,
				},
			},
		};
		try {
			const result = await client.graphql(params);
			if (result.data.listCourses) {
				const courses = [...result.data.listCourses.items, ...prevCourses];
				const nextToken = result.data.listCourses.nextToken;
				if (nextToken)
					return this.fetchCourseCategories(courses, nextToken, filter);
				return courses;
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
		}
	};

	fetchEventNames = async (prevEvents = [], nextToken = null, filter = {}) => {
		const params = {
			query: queries.listEventsForHeader,
			authToken: getAuthToken(),
			variables: {
				nextToken,
				filter: {
					to: {
						ge: getCurrentDateAsISOString(),
					},
					...filter,
				},
			},
		};
		try {
			const result = await client.graphql(params);
			if (result.data.listEvents) {
				const events = [...result.data.listEvents.items, ...prevEvents];
				const nextToken = result.data.listEvents.nextToken;
				if (nextToken) return this.fetchEventNames(events, nextToken, filter);
				return events;
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);
		}
	};

	static fetchSitemapData = async (prevNames = [], nextToken = null) => {
		const params = {
			query: queries.listClubNames,
			authToken: getAuthToken(),
			variables: {
				nextToken,
			},
		};
		try {
			const result = await client.graphql(params);
			if (result.data.listClubs) {
				const clubNames = [...result.data.listClubs.items, ...prevNames];
				const nextToken = result.data.listClubs.nextToken;
				if (nextToken) return this.fetchSitemapData(clubNames, nextToken);
				return clubNames;
			}
		} catch (error) {
			console.error(error);
		}
	};

	updateClub = async (club) => {
		const updatedClub = {
			...club,
		};

		delete updatedClub.address;
		delete updatedClub.locations;
		delete updatedClub.contacts;
		delete updatedClub.admins;
		delete updatedClub.tenant;
		delete updatedClub.offerings;
		delete updatedClub.logoURI;
		delete updatedClub.createdAt;
		delete updatedClub.updatedAt;

		const OPTIONAL_FIELDS = [
			"tenant",
			"admins",
			"description",
			"logo",
			"address",
			"homepage",
			"membershipCosts",
			"contacts",
			"locations",
			"offerings",
			"events",
			"migratedId",
		];

		// remove optional fields with null value - clubAdmins don't have delete
		// permissions in the model and AppSync / Amplify considers setting attributes
		// to null as partial delete, and claims this is unauthorized
		OPTIONAL_FIELDS.forEach((field) => {
			if (
				Object.keys(updatedClub).includes(field) &&
				updatedClub[field] == null
			) {
				delete updatedClub[field];
			}
		});

		try {
			const params = {
				query: mutations.updateClub,
				authMode: "AMAZON_COGNITO_USER_POOLS",
				variables: {
					input: {
						...updatedClub,
					},
				},
			};

			const clubAddress = { ...club.address, name: club.name };

			if (
				(clubAddress.street ||
					clubAddress.city ||
					clubAddress.zipCode ||
					clubAddress.houseNumber) &&
				!club.address.id
			) {
				delete clubAddress["id"];
				const createAddressResult = await this.createLocation(
					clubAddress,
					club.id,
					club.tenantAdminGroup,
					club.clubAdminGroup,
				);
				params.variables.input.clubAddressId =
					createAddressResult.data.createAddress.id;
				return {
					updateClub: await client.graphql(params),
					updateLocation: createAddressResult,
				};
			}
			if (clubAddress.id)
				return {
					updateClub: await client.graphql(params),
					updateLocation: await this.updateLocation(clubAddress),
				};
			return {
				updateClub: await client.graphql(params),
				updateLocation: null,
			};
		} catch (error) {
			console.error(error);
			return {
				updateClub: null,
				updateLocation: null,
			};
		}
	};

	createClub = async (clubData, tenantKey) => {
		const { name } = clubData;

		try {
			const params = {
				query: mutations.createClub,
				authMode: "AMAZON_COGNITO_USER_POOLS",
				variables: {
					input: {
						name,
						tenantAdminGroup: `${tenantKey}-Admins`,
						tenantKey: tenantKey,
						clubAdminGroup: "temporary",
					},
				},
			};

			const createClubResult = await client.graphql(params);

			if (createClubResult.data.createClub) {
				const { id } = createClubResult.data.createClub;
				const createGroupResult = await this.createGroup(id, clubData.name);
				const clubAdminGroup = createGroupResult.data.createGroup;
				const updateParams = {
					query: mutations.updateClub,
					authMode: "AMAZON_COGNITO_USER_POOLS",
					variables: {
						input: {
							id,
							name,
							clubAdminGroup,
						},
					},
				};
				const updateClubResult = await client.graphql(updateParams);
				return updateClubResult.data.updateClub;
			}
			return null;
		} catch (error) {
			console.error("ERROR:", error);
			return null;
		}
	};

	createAdmin = async (adminData) => {
		const params = {
			query: mutations.createAdmin,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				adminData: {
					...adminData,
				},
			},
		};

		return client.graphql(params);
	};

	updateAdmin = async (prevAdminData, newAdminData) => {
		const params = {
			query: mutations.updateAdmin,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				prevAdminData: {
					...prevAdminData,
				},
				newAdminData: {
					...newAdminData,
				},
			},
		};

		return client.graphql(params);
	};

	addAdminToClub = async (adminData, clubAdminGroup) => {
		const params = {
			query: mutations.addAdminToClub,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				adminData: {
					...adminData,
				},
				clubAdminGroup,
			},
		};

		return client.graphql(params);
	};

	removeAdminFromClub = async (adminData, clubAdminGroup) => {
		const params = {
			query: mutations.removeAdminFromClub,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				adminData: {
					...adminData,
				},
				clubAdminGroup,
			},
		};

		return client.graphql(params);
	};

	resendAdminInvitation = async (adminData) => {
		const params = {
			query: mutations.resendInvitation,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				adminData: {
					...adminData,
				},
			},
		};

		return client.graphql(params);
	};

	updateLogoOfClub = async (clubId, logo) => {
		const params = {
			query: mutations.updateClub,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					id: clubId,
					logo,
				},
			},
		};

		return client.graphql(params);
	};

	updateCourse = async (course) => {
		const courseContactId = course.contact ? course.contact.id : null;
		const courseLocationId = course.location ? course.location.id : null;
		const eventID = course.event ? course.event.id : null;

		const courseData = {
			...course,
			courseContactId,
			courseLocationId,
			eventID,
		};

		delete courseData.index;
		delete courseData.clubId;
		delete courseData.createdAt;
		delete courseData.updatedAt;
		delete courseData.location;
		delete courseData.contact;
		delete courseData.club;
		delete courseData.event;
		delete courseData.clubLogo;
		delete courseData.type;

		const params = {
			query: mutations.updateCourse,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					...courseData,
				},
			},
		};

		return client.graphql(params);
	};

	createCourse = async (course, clubId, tenantAdminGroup, clubAdminGroup) => {
		const courseContactId = course.contact ? course.contact.id : null;
		const courseLocationId = course.location ? course.location.id : null;
		const eventID = course.event ? course.event.id : null;

		const createObj = {
			...course,
			courseContactId,
			courseLocationId,
			eventID,
		};

		delete createObj.location;
		delete createObj.contact;
		delete createObj.event;

		const params = {
			query: mutations.createCourse,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					clubID: clubId,
					tenantAdminGroup,
					clubAdminGroup,
					...createObj,
				},
			},
		};

		return client.graphql(params);
	};

	deleteCourse = async (id) => {
		const params = {
			query: mutations.deleteCourse,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					id,
				},
			},
		};

		return client.graphql(params);
	};

	updateOffering = async (offering) => {
		const offeringContactId = offering.contact ? offering.contact.id : null;
		const offeringLocationId = offering.location ? offering.location.id : null;

		const offeringData = {
			...offering,
			offeringContactId,
			offeringLocationId,
		};

		delete offeringData.index;
		delete offeringData.clubId;
		delete offeringData.createdAt;
		delete offeringData.updatedAt;
		delete offeringData.location;
		delete offeringData.contact;
		delete offeringData.club;
		delete offeringData.type;

		const params = {
			query: mutations.updateOffering,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					...offeringData,
				},
			},
		};
		return client.graphql(params);
	};

	createOffering = async (
		offering,
		clubId,
		tenantAdminGroup,
		clubAdminGroup,
	) => {
		const offeringContactId = offering.contact ? offering.contact.id : null;
		const offeringLocationId = offering.location ? offering.location.id : null;

		const createObj = {
			...offering,
			offeringContactId,
			offeringLocationId,
		};

		delete createObj.location;
		delete createObj.contact;

		const params = {
			query: mutations.createOffering,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					clubID: clubId,
					tenantAdminGroup,
					clubAdminGroup,
					...createObj,
				},
			},
		};

		return client.graphql(params);
	};

	deleteOffering = async (id) => {
		const params = {
			query: mutations.deleteOffering,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					id,
				},
			},
		};

		return client.graphql(params);
	};

	updateLocation = async (location) => {
		const updateObj = { ...location };
		delete updateObj.createdAt;
		delete updateObj.updatedAt;

		const params = {
			query: mutations.updateAddress,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					...updateObj,
				},
			},
		};

		return client.graphql(params);
	};

	createLocation = async (
		location,
		clubID,
		tenantAdminGroup,
		clubAdminGroup,
	) => {
		const params = {
			query: mutations.createAddress,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					...location,
					clubID,
					tenantAdminGroup,
					clubAdminGroup,
				},
			},
		};

		return client.graphql(params);
	};

	deleteLocation = async (id) => {
		const params = {
			query: mutations.deleteAddress,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					id,
				},
			},
		};

		return client.graphql(params);
	};

	updateContact = async (contact) => {
		const updateObj = { ...contact };
		delete updateObj.createdAt;
		delete updateObj.updatedAt;

		const params = {
			query: mutations.updateContact,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					...updateObj,
				},
			},
		};

		return client.graphql(params);
	};

	createContact = async (contact, clubID, tenantAdminGroup, clubAdminGroup) => {
		const params = {
			query: mutations.createContact,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					clubID,
					tenantAdminGroup,
					clubAdminGroup,
					...contact,
				},
			},
		};

		return client.graphql(params);
	};

	deleteContact = async (id) => {
		const params = {
			query: mutations.deleteContact,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					id,
				},
			},
		};

		return client.graphql(params);
	};

	createGroup = async (id, clubName) => {
		const groupName = `${clubName.replaceAll(" ", "")}-${id}-Admins`;
		const params = {
			query: mutations.createGroup,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				clubAdminGroup: groupName,
			},
		};

		return client.graphql(params);
	};

	updateEvent = async (event) => {
		const updateObj = { ...event };
		delete updateObj.createdAt;
		delete updateObj.updatedAt;

		const params = {
			query: mutations.updateEvent,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					...updateObj,
				},
			},
		};

		return client.graphql(params);
	};

	createEvent = async (event, clubID, tenantAdminGroup, clubAdminGroup) => {
		const params = {
			query: mutations.createEvent,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					clubID,
					tenantAdminGroup,
					clubAdminGroup,
					...event,
				},
			},
		};

		return client.graphql(params);
	};

	deleteEvent = async (id) => {
		const params = {
			query: mutations.deleteEvent,
			authMode: "AMAZON_COGNITO_USER_POOLS",
			variables: {
				input: {
					id,
				},
			},
		};

		return client.graphql(params);
	};
}
