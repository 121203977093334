import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
	primaryColor,
	whiteColor,
} from "../../../assets/jss/material-kit-pro-react";
import makeStyles from "@mui/styles/makeStyles";
import AddPhotoAlternateOutlined from "@mui/icons-material/AddPhotoAlternateOutlined";
import placeholder from "../../../customAssets/img/ssb_background.png";
import { getImageDimensions } from "../../../utils/Utils";
import CropImageModal from "../CropImageModal";

const MAX_UPLOAD_SIZE = 500 * 1024;

const imageSelectStyles = () => {
	const width = 200;
	const height = 200;

	return {
		imgWrapper: {
			display: "flex",
			position: "relative",
		},
		imgOverlay: {
			display: "flex",
			justifyContent: "center",
			width,
			height,
			position: "absolute",
			top: height / 2,
			zIndex: 1,
			transform: "translate3d(0, -50%, 0)",

			color: whiteColor,
			fontSize: 12,
			fontWeight: 400,
			textTransform: "uppercase",
			letterSpacing: 0,
			lineHeight: 1.42857143,
			cursor: "pointer",

			"&:hover": {
				backgroundColor: primaryColor[0],
				opacity: 0.98,
				cursor: "pointer",

				"& > span": {
					visibility: "visible !important",
					color: whiteColor,
					alignSelf: "center",
					fontSize: "small",
				},
				"& > svg": {
					visibility: "visible !important",
				},
			},
		},
		imgOverlayTxt: {
			visibility: "hidden",
		},
		fileInput: {
			display: "none",
		},
		imageErrorMessage: {
			position: "absolute",
			marginLeft: width + 10,
			textAlign: "left",
			color: "red",
			fontWeight: 600,
		},
		img: {
			width,
			height,
		},
		imageIconOverlay: {
			fontSize: 28,
			color: whiteColor,
			position: "absolute",
			top: 2,
			left: 4,
			visibility: "hidden",
		},
	};
};

const useImageSelectStyles = makeStyles(imageSelectStyles);

const ImageSelectAdapter = ({ input, imagePath }) => {
	const [image, setImage] = useState();
	const [imageErrorMessage, setImageErrorMessage] = useState(null);
	const fileInput = React.createRef();
	const [cropImageModalOpen, setCropImageModalOpen] = useState(false)

	useEffect(() => {
		if (imagePath) setImage(imagePath);
	}, [imagePath]);

	const handleChangeImageClick = () => {
		setCropImageModalOpen(true)
	};

	const handleSelectImage = async (file) => {


		if (file) {
			const fileType = file.type.split("/")[0];

			if (fileType === "image") {
				const uploadSizeLimit = MAX_UPLOAD_SIZE; // Limit file size upload to ...kB
				const url = URL.createObjectURL(file);
				const dimensions = await getImageDimensions(url);

				const difference = 0.3;

				if (file.size > uploadSizeLimit) {
					setImageErrorMessage(
						`Das Bild darf maximal ${uploadSizeLimit / 1024}kB groß sein.`,
					);
				} else if (
					dimensions.width > dimensions.height * (1 + difference) ||
					dimensions.width < dimensions.height * (1 - difference) ||
					dimensions.width > 1024
				) {
					setImageErrorMessage(
						"Das Bild muss annähernd quadratisch sein (Maximal 1024px breit. Breite darf sich um maximal 30% von der Höhe unterscheiden).",
					);
				} else {
					const formData = new FormData();
					formData.append("image", file, file.name);
					input.onChange(file);

					setImage(URL.createObjectURL(file));

					setCropImageModalOpen(false)
					if (imageErrorMessage) {
						setImageErrorMessage(null);
					}
				}
			}
		}
	};

	const classes = useImageSelectStyles();

	return (
		<div className={classes.imgWrapper}>
			<CropImageModal
				open={cropImageModalOpen}
				handleClose={() => setCropImageModalOpen(false)}
				handleSelectImage={handleSelectImage}
				rectangular
				maxSize={MAX_UPLOAD_SIZE}
			/>
			<div className={classes.imgOverlay} onClick={handleChangeImageClick}>
				<AddPhotoAlternateOutlined className={classes.imageIconOverlay} />
				<span className={classes.imgOverlayTxt}>
					ANGEBOTSBILD ÄNDERN
				</span>
			</div>
			<img
				src={image ? image : placeholder}
				alt={"Angebotsbild"}
				className={classes.img}
				onError={() => setImage(placeholder)}
			/>
			{imageErrorMessage && (
				<div className={classes.imageErrorMessage}>{imageErrorMessage}</div>
			)}
		</div>
	);
};

ImageSelectAdapter.propTypes = {
	input: PropTypes.object,
	imagePath: PropTypes.string,
};

export default ImageSelectAdapter;
