import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import makeStyles from "@mui/styles/makeStyles";
// custom components
import ScheduleList from "./ScheduleList";
import AgeCheckBoxes from "./AgeCheckBoxes";

import productStyle from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Typography from "@mui/material/Typography";
import TypeCheckBoxes from "./TypeCheckboxes";
import _debounce from "lodash/debounce";

const styles = () => ({
	...productStyle,
	filterSubHeader: {
		color: "black",
		fontWeight: "400",
	},
	subHeaderWrapper: {
		borderBottom: "solid",
		color: "lightgrey",
		borderBottomWidth: 1,
	},
});

const useStyles = makeStyles(styles);

const Filter = observer((props) => {
	const { setFilter, filter } = props;
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		setInputValue(filter.searchText);
	}, [filter.searchText]);

	const toggleOfferingType = (offeringType) => {
		const currentOfferingTypes = [...filter.offeringTypes];
		const index = currentOfferingTypes.indexOf(offeringType);

		if (offeringType === "EVERYTHING") {
			setFilter({
				...filter,
				offeringTypes: [],
			});
		} else if (index === -1) {
			setFilter({
				...filter,
				offeringTypes: [...currentOfferingTypes, offeringType],
			});
		} else {
			currentOfferingTypes.splice(index, 1);
			setFilter({
				...filter,
				offeringTypes: currentOfferingTypes,
			});
		}
	};

	const toggleAgeGroup = (ageGroup) => {
		const currentAgeGroups = [...filter.ageGroups];
		const index = currentAgeGroups.indexOf(ageGroup);

		if (ageGroup === "EVERYONE") {
			setFilter({
				...filter,
				ageGroups: [],
			});
		} else if (index === -1) {
			setFilter({
				...filter,
				ageGroups: [...currentAgeGroups, ageGroup],
			});
		} else {
			currentAgeGroups.splice(index, 1);
			setFilter({
				...filter,
				ageGroups: currentAgeGroups,
			});
		}
	};

	const setSchedule = (schedule) => {
		const schedules = filter.schedules;
		const scheduleIndex = schedules.findIndex((e) => e.day === schedule.day);

		if (scheduleIndex !== -1) {
			const newSchedules = [...schedules];
			newSchedules[scheduleIndex] = schedule;

			setFilter({
				...filter,
				schedules: newSchedules,
			});
		} else {
			const newSchedules = [...schedules, schedule];

			setFilter({
				...filter,
				schedules: newSchedules,
			});
		}
	};

	const removeSchedule = (schedule) => {
		const schedules = filter.schedules;
		const scheduleIndex = schedules.findIndex((e) => e.day === schedule.day);

		if (scheduleIndex !== -1) {
			const newSchedules = [...schedules];
			newSchedules.splice(scheduleIndex, 1);

			setFilter({
				...filter,
				schedules: newSchedules,
			});
		}
	};

	const debouncedSetSearchText = useMemo(() => {
		return _debounce((searchText) => {
			setFilter({
				...filter,
				searchText,
			});
		}, 500);
	}, []);

	const setSearchText = (searchText) => {
		debouncedSetSearchText(searchText);
	};

	const classes = useStyles();

	useEffect(() => {
		return () => {
			debouncedSetSearchText.cancel();
		};
	}, [debouncedSetSearchText]);

	return (
		<div className={classes.textLeft}>
			<CustomInput
				labelText="Suche"
				id="float"
				formControlProps={{
					fullWidth: true,
				}}
				inputProps={{
					value: inputValue,
					onChange: (event) => {
						setInputValue(event.target.value);
						setSearchText(event.target.value);
					},
				}}
			/>
			<div className={classes.subHeaderWrapper}>
				<Typography variant="subtitle1" className={classes.filterSubHeader}>
					Angebotstypen
				</Typography>
			</div>
			<TypeCheckBoxes
				offeringTypes={filter.offeringTypes}
				toggleOfferingType={toggleOfferingType}
			/>
			<div className={classes.subHeaderWrapper}>
				<Typography variant="subtitle1" className={classes.filterSubHeader}>
					Alter & Person
				</Typography>
			</div>
			<AgeCheckBoxes
				ageGroups={filter.ageGroups}
				toggleAgeGroup={toggleAgeGroup}
			/>

			<div className={classes.subHeaderWrapper}>
				<Typography variant="subtitle1" className={classes.filterSubHeader}>
					Trainingstage
				</Typography>
			</div>
			<div style={{ marginTop: 10 }}>
				<ScheduleList
					schedules={filter.schedules}
					onSetSchedule={setSchedule}
					onRemoveSchedule={removeSchedule}
				/>
			</div>
		</div>
	);
});

export default Filter;
