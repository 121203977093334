/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTenant =
	/* GraphQL */
	`
		query GetTenant($key: String!) {
			getTenant(key: $key) {
				key
				name

				districts
				createdAt
				updatedAt
				clubs {
					items {
						id
						tenantKey

						name
						description
						logo
						homepage

						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;
export const listTenants =
	/* GraphQL */
	`
		query ListTenants(
			$key: String
			$filter: ModelTenantFilterInput
			$limit: Int
			$nextToken: String
			$sortDirection: ModelSortDirection
		) {
			listTenants(
				key: $key
				filter: $filter
				limit: $limit
				nextToken: $nextToken
				sortDirection: $sortDirection
			) {
				items {
					key
					name

					districts
					createdAt
					updatedAt
					clubs {
						nextToken
					}
				}
				nextToken
			}
		}
	`;
export const getClub =
	/* GraphQL */
	`
		query GetClub($id: ID!, $courseFilter: ModelCourseFilterInput) {
			getClub(id: $id) {
				id
				tenantKey
				name
				description
				descriptionAsHtml
				logo
				homepage
				membershipCosts {
					ageGroup
					cost
					billingInterval
				}

				createdAt
				updatedAt
				offerings {
					items {
						id
						clubID

						title
						category
						district
						description
						descriptionAsHtml
						hints
						image
						targetGroup {
							male
							female
							from
							to
						}
						schedules {
							day
							from
							to
						}
						costs {
							ageGroup
							cost
							billingInterval
						}
						location {
							id
							clubID

							name
							street
							houseNumber
							zipCode
							city
							createdAt
							updatedAt
						}
						contact {
							id
							clubID

							firstName
							lastName
							email
							phone
							createdAt
							updatedAt
						}
					}
					nextToken
				}
				courses(filter: $courseFilter) {
					items {
						id
						clubID
						club {
							id
							logo
						}
						eventID
						tenantAdminGroup
						clubAdminGroup
						title
						category
						district
						description
						descriptionAsHtml
						image
						hints
						createdAt
						updatedAt
						courseContactId
						courseLocationId
					}
					nextToken
				}
				address {
					id
					clubID

					name
					street
					houseNumber
					zipCode
					city
					geoCoordinates {
						longitude
						latitude
					}
					createdAt
					updatedAt
				}
				locations {
					items {
						id
						clubID

						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
					nextToken
				}
				contacts {
					items {
						id
						clubID

						firstName
						lastName
						email
						phone
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;
export const getClubAsAdmin =
	/* GraphQL */
	`
		query GetClub($id: ID!) {
			getClub(id: $id) {
				id
				tenantKey
				tenantAdminGroup
				clubAdminGroup
				name
				description
				descriptionAsHtml
				logo
				homepage
				membershipCosts {
					ageGroup
					cost
					billingInterval
				}
				events {
					items {
						id
						club {
							id
							name
							description
							descriptionAsHtml
							logo
							homepage
						}
						name
						tagline
						description
						descriptionAsHtml
						details
						image
						schedule {
							from
							to
						}
					}
					nextToken
				}
				createdAt
				updatedAt
				offerings {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						title
						category
						district
						description
						descriptionAsHtml
						hints
						image
						targetGroup {
							male
							female
							from
							to
						}
						schedules {
							day
							from
							to
						}
						costs {
							ageGroup
							cost
							billingInterval
						}
						location {
							id
							clubID
							tenantAdminGroup
							clubAdminGroup
							name
							street
							houseNumber
							zipCode
							city
							geoCoordinates {
								longitude
								latitude
							}
							createdAt
							updatedAt
						}
						contact {
							id
							clubID
							tenantAdminGroup
							clubAdminGroup
							firstName
							lastName
							email
							phone
							createdAt
							updatedAt
						}
					}
					nextToken
				}
				courses {
					items {
						id
						club {
							id
							name
							logo
						}
						eventID
						event {
							id
							name
							description
							descriptionAsHtml
							image
							schedule {
								from
								to
							}
							sponsors {
								name
								logo
								url
								callToAction
							}
						}
						title
						category
						district
						description
						descriptionAsHtml
						image
						targetGroup {
							male
							female
							from
							to
						}
						schedule {
							startingDate
							schedules {
								day
								from
								to
							}
							finalDate
							dates
							fullDay
						}
						costs {
							ageGroup
							cost
							billingInterval
						}
						hints
						contact {
							id
							firstName
							lastName
							email
							phone
							createdAt
							updatedAt
						}
						location {
							id
							name
							street
							houseNumber
							zipCode
							city
							geoCoordinates {
								longitude
								latitude
							}
						}
						registration {
							participantLimit
							registrationLink
							openingDate
							closingDate
						}
					}
					nextToken
				}
				address {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					name
					street
					houseNumber
					zipCode
					city
					geoCoordinates {
						longitude
						latitude
					}
					createdAt
					updatedAt
				}
				locations {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
					nextToken
				}
				contacts {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						firstName
						lastName
						email
						phone
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;

export const getClubAsTenant =
	/* GraphQL */
	`
		query GetClub($id: ID!) {
			getClub(id: $id) {
				id
				tenantKey
				tenantAdminGroup
				clubAdminGroup
				name
				description
				logo
				homepage
				admins {
					username
					email
				}
				membershipCosts {
					ageGroup
					cost
					billingInterval
				}
				createdAt
				updatedAt
				offerings {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						title
						category
						district
						description
						hints
						image
						targetGroup {
							male
							female
							from
							to
						}
						schedules {
							day
							from
							to
						}
						costs {
							ageGroup
							cost
							billingInterval
						}
						location {
							id
							clubID
							tenantAdminGroup
							clubAdminGroup
							name
							street
							houseNumber
							zipCode
							city
							geoCoordinates {
								longitude
								latitude
							}
							createdAt
							updatedAt
						}
						contact {
							id
							clubID
							tenantAdminGroup
							clubAdminGroup
							firstName
							lastName
							email
							phone
							createdAt
							updatedAt
						}
					}
					nextToken
				}
				courses {
					items {
						id
						clubID
						eventID
						tenantAdminGroup
						clubAdminGroup
						title
						category
						district
						description
						image
						hints
						createdAt
						updatedAt
						courseContactId
						courseLocationId
					}
					nextToken
				}
				address {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					name
					street
					houseNumber
					zipCode
					city
					geoCoordinates {
						longitude
						latitude
					}
					createdAt
					updatedAt
				}
				locations {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
					nextToken
				}
				contacts {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						firstName
						lastName
						email
						phone
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;

export const listClubsAsTenant =
	/* GraphQL */
	`
		query ListClubs(
			$id: ID
			$filter: ModelClubFilterInput
			$limit: Int
			$nextToken: String
			$sortDirection: ModelSortDirection
		) {
			listClubs(
				id: $id
				filter: $filter
				limit: $limit
				nextToken: $nextToken
				sortDirection: $sortDirection
			) {
				items {
					id
					tenantKey
					tenantAdminGroup
					clubAdminGroup
					admins {
						username
						email
						status
					}
					name
					description
					logo
					homepage
					membershipCosts {
						ageGroup
						cost
						billingInterval
					}
					migratedId
					createdAt
					updatedAt
					tenant {
						key
						name
						tenantAdminGroup
						districts
						createdAt
						updatedAt
					}
					offerings {
						nextToken
					}
					address {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
					locations {
						nextToken
					}
					contacts {
						nextToken
					}
				}
				nextToken
			}
		}
	`;
export const listClubNames =
	/* GraphQL */
	`
		query ListClubs(
			$id: ID
			$filter: ModelClubFilterInput
			$limit: Int
			$nextToken: String
			$sortDirection: ModelSortDirection
		) {
			listClubs(
				id: $id
				filter: $filter
				limit: $limit
				nextToken: $nextToken
				sortDirection: $sortDirection
			) {
				items {
					id
					name
				}
				nextToken
			}
		}
	`;
export const listClubsForHeader =
	/* GraphQL */
	`
		query ListClubs(
			$id: ID
			$filter: ModelClubFilterInput
			$limit: Int
			$nextToken: String
			$sortDirection: ModelSortDirection
		) {
			listClubs(
				id: $id
				filter: $filter
				limit: $limit
				nextToken: $nextToken
				sortDirection: $sortDirection
			) {
				items {
					id
					name
					description
					logo
					homepage
					address {
						id
						clubID

						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
				}
				nextToken
			}
		}
	`;
export const listClubs =
	/* GraphQL */
	`
		query ListClubs(
			$id: ID
			$filter: ModelClubFilterInput
			$limit: Int
			$nextToken: String
			$sortDirection: ModelSortDirection
		) {
			listClubs(
				id: $id
				filter: $filter
				limit: $limit
				nextToken: $nextToken
				sortDirection: $sortDirection
			) {
				items {
					id
					tenantKey

					name
					description
					logo
					homepage
					membershipCosts {
						ageGroup
						cost
						billingInterval
					}

					createdAt
					updatedAt
					offerings {
						nextToken
					}
					address {
						id
						clubID

						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
					locations {
						nextToken
					}
					contacts {
						nextToken
					}
				}
				nextToken
			}
		}
	`;
export const clubsByTenant =
	/* GraphQL */
	`
		query ClubsByTenant(
			$tenantKey: String
			$sortDirection: ModelSortDirection
			$filter: ModelClubFilterInput
			$limit: Int
			$nextToken: String
		) {
			clubsByTenant(
				tenantKey: $tenantKey
				sortDirection: $sortDirection
				filter: $filter
				limit: $limit
				nextToken: $nextToken
			) {
				items {
					id
					tenantKey

					admins {
						username
						email
					}
					name
					description
					logo
					homepage
					membershipCosts {
						ageGroup
						cost
						billingInterval
					}

					createdAt
					updatedAt
					tenant {
						key
						name

						districts
						createdAt
						updatedAt
					}
					offerings {
						nextToken
					}
					address {
						id
						clubID

						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
					locations {
						nextToken
					}
					contacts {
						nextToken
					}
				}
				nextToken
			}
		}
	`;

export const getCourse = /* GraphQL */ `
	query GetCourse($id: ID!) {
		getCourse(id: $id) {
			id
			category
			district
			title
			description
			descriptionAsHtml
			image
			club {
				id
				name
				logo
			}
			event {
				id
			}
			targetGroup {
				male
				female
				from
				to
			}
			schedule {
				startingDate
				schedules {
					day
					from
					to
				}
				finalDate
				dates
				fullDay
			}
			costs {
				ageGroup
				cost
				billingInterval
			}
			hints
			contact {
				id
				firstName
				lastName
				email
				phone
				createdAt
				updatedAt
			}
			location {
				id
				name
				street
				houseNumber
				zipCode
				city
				geoCoordinates {
					longitude
					latitude
				}
				createdAt
				updatedAt
			}
			registration {
				participantLimit
				registrationLink
				openingDate
				closingDate
			}
		}
	}
`;

export const listCourses = /* GraphQL */ `
	query ListCourses(
		$filter: ModelCourseFilterInput
		$limit: Int
		$nextToken: String
	) {
		listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				category
				district
				title
				description
				image
				eventID
				createdAt
				club {
					logo
				}
				targetGroup {
					male
					female
					from
					to
				}
				schedule {
					startingDate
					schedules {
						day
						from
						to
					}
					finalDate
					dates
					fullDay
				}
				costs {
					ageGroup
					cost
					billingInterval
				}
				hints
			}
			nextToken
		}
	}
`;

export const getOffering =
	/* GraphQL */
	`
		query GetOffering($id: ID!) {
			getOffering(id: $id) {
				id
				clubID

				title
				category
				district
				description
				descriptionAsHtml
				image
				targetGroup {
					male
					female
					from
					to
				}
				schedules {
					day
					from
					to
				}
				costs {
					ageGroup
					cost
					billingInterval
				}
				hints
				createdAt
				updatedAt
				club {
					id

					name
					logo
				}
				location {
					id
					clubID

					name
					street
					houseNumber
					zipCode
					city
					geoCoordinates {
						latitude
						longitude
					}
					createdAt
					updatedAt
				}
				contact {
					id
					clubID

					firstName
					lastName
					email
					phone
					createdAt
					updatedAt
				}
			}
		}
	`;
export const listOfferings =
	/* GraphQL */
	`
		query ListOfferings(
			$filter: ModelOfferingFilterInput
			$limit: Int
			$nextToken: String
		) {
			listOfferings(filter: $filter, limit: $limit, nextToken: $nextToken) {
				items {
					id
					clubID
					title
					category
					district
					description
					image
					targetGroup {
						male
						female
						from
						to
					}
					schedules {
						day
						from
						to
					}
					costs {
						ageGroup
						cost
						billingInterval
					}
					club {
						logo
					}
					hints
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
export const listOfferingsWithCategory =
	/* GraphQL */
	`
		query ListOfferings(
			$filter: ModelOfferingFilterInput
			$limit: Int
			$nextToken: String
		) {
			listOfferings(filter: $filter, limit: $limit, nextToken: $nextToken) {
				items {
					id
					title
					category
					club {
						logo
					}
				}
				nextToken
			}
		}
	`;
export const listCoursesWithCategory =
	/* GraphQL */
	`
		query ListCourses(
			$filter: ModelCourseFilterInput
			$limit: Int
			$nextToken: String
		) {
			listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
				items {
					id
					title
					category
					club {
						logo
					}
				}
				nextToken
			}
		}
	`;
export const offeringsByClub =
	/* GraphQL */
	`
		query OfferingsByClub(
			$clubID: ID!
			$sortDirection: ModelSortDirection
			$filter: ModelOfferingFilterInput
			$limit: Int
			$nextToken: String
		) {
			offeringsByClub(
				clubID: $clubID
				sortDirection: $sortDirection
				filter: $filter
				limit: $limit
				nextToken: $nextToken
			) {
				items {
					id
					clubID

					title
					category
					district
					description
					targetGroup {
						male
						female
						from
						to
					}
					schedules {
						day
						from
						to
					}
					costs {
						ageGroup
						cost
						billingInterval
					}
					hints
					createdAt
					updatedAt
					club {
						id
						tenantKey

						name
						description
						logo
						homepage

						createdAt
						updatedAt
					}
					location {
						id
						clubID

						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
					contact {
						id
						clubID

						firstName
						lastName
						email
						phone
						createdAt
						updatedAt
					}
				}
				nextToken
			}
		}
	`;
export const offeringsByClubAsAdmin =
	/* GraphQL */
	`
		query OfferingsByClub(
			$clubID: ID!
			$sortDirection: ModelSortDirection
			$filter: ModelOfferingFilterInput
			$limit: Int
			$nextToken: String
		) {
			offeringsByClub(
				clubID: $clubID
				sortDirection: $sortDirection
				filter: $filter
				limit: $limit
				nextToken: $nextToken
			) {
				items {
					id
					clubID

					title
					category
					district
					description
					clubAdminGroup
					tenantAdminGroup
					targetGroup {
						male
						female
						from
						to
					}
					schedules {
						day
						from
						to
					}
					costs {
						ageGroup
						cost
						billingInterval
					}
					hints
					createdAt
					updatedAt
					club {
						id
						tenantKey

						name
						description
						logo
						homepage

						createdAt
						updatedAt
					}
					location {
						id
						clubID

						name
						street
						houseNumber
						zipCode
						city
						geoCoordinates {
							longitude
							latitude
						}
						createdAt
						updatedAt
					}
					contact {
						id
						clubID

						firstName
						lastName
						email
						phone
						createdAt
						updatedAt
					}
				}
				nextToken
			}
		}
	`;
export const getAddress =
	/* GraphQL */
	`
		query GetAddress($id: ID!) {
			getAddress(id: $id) {
				id
				clubID

				name
				street
				houseNumber
				zipCode
				city
				geoCoordinates {
					longitude
					latitude
				}
				createdAt
				updatedAt
			}
		}
	`;
export const listAddresss =
	/* GraphQL */
	`
		query ListAddresss(
			$filter: ModelAddressFilterInput
			$limit: Int
			$nextToken: String
		) {
			listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
				items {
					id
					clubID

					name
					street
					houseNumber
					zipCode
					city
					geoCoordinates {
						longitude
						latitude
					}
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
export const addressesByClub =
	/* GraphQL */
	`
		query AddressesByClub(
			$clubID: ID!
			$sortDirection: ModelSortDirection
			$filter: ModelAddressFilterInput
			$limit: Int
			$nextToken: String
		) {
			addressesByClub(
				clubID: $clubID
				sortDirection: $sortDirection
				filter: $filter
				limit: $limit
				nextToken: $nextToken
			) {
				items {
					id
					clubID

					name
					street
					houseNumber
					zipCode
					city
					geoCoordinates {
						longitude
						latitude
					}
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
export const getContact =
	/* GraphQL */
	`
		query GetContact($id: ID!) {
			getContact(id: $id) {
				id
				clubID

				firstName
				lastName
				email
				phone
				createdAt
				updatedAt
			}
		}
	`;
export const listContacts =
	/* GraphQL */
	`
		query ListContacts(
			$filter: ModelContactFilterInput
			$limit: Int
			$nextToken: String
		) {
			listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
				items {
					id
					clubID

					firstName
					lastName
					email
					phone
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;
export const contactsByClub =
	/* GraphQL */
	`
		query ContactsByClub(
			$clubID: ID!
			$sortDirection: ModelSortDirection
			$filter: ModelContactFilterInput
			$limit: Int
			$nextToken: String
		) {
			contactsByClub(
				clubID: $clubID
				sortDirection: $sortDirection
				filter: $filter
				limit: $limit
				nextToken: $nextToken
			) {
				items {
					id
					clubID

					firstName
					lastName
					email
					phone
					createdAt
					updatedAt
				}
				nextToken
			}
		}
	`;

export const listEvents = /* GraphQL */ `
	query ListEvents(
		$id: ID
		$filter: ModelEventFilterInput
		$limit: Int
		$nextToken: String
		$courseFilter: ModelCourseFilterInput
		$sortDirection: ModelSortDirection
	) {
		listEvents(
			id: $id
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			sortDirection: $sortDirection
		) {
			items {
				id
				clubID
				club {
					id
					tenantKey
					name
					description
					logo
					homepage
					createdAt
					updatedAt
					clubAddressId
				}
				highlight
				name
				tagline
				description
				descriptionAsHtml
				details
				image
				schedule {
					from
					to
				}
				courses(filter: $courseFilter, limit: 100) {
					items {
						id
						category
						district
						title
						description
						descriptionAsHtml
						club {
							id
							name
							logo
						}
						contact {
							firstName
							lastName
							email
							phone
						}
						event {
							id
						}
						hints
						location {
							name
							street
							houseNumber
							zipCode
							city
							geoCoordinates {
								longitude
								latitude
							}
						}
						registration {
							registrationLink
							participantLimit
						}
						schedule {
							schedules {
								day
								from
								to
							}
							dates
							finalDate
							startingDate
						}
						targetGroup {
							male
							female
							from
							to
						}
					}
				}
				sponsors {
					name
					logo
					url
					callToAction
				}
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

//this query lists names and date of the events for the navbar
export const listEventsForHeader = /* GraphQL */ `
	query ListEvents(
		$id: ID
		$filter: ModelEventFilterInput
		$limit: Int
		$nextToken: String
		$sortDirection: ModelSortDirection
	) {
		listEvents(
			id: $id
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			sortDirection: $sortDirection
		) {
			items {
				id
				name
				icon
				schedule {
					from
					to
				}
				highlight
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
