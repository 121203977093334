import { makeAutoObservable, runInAction } from "mobx";
import { getLogoURI } from "../utils/Utils";
import { uploadData } from "aws-amplify/storage";
import messages from "../utils/messages";

import { fetchAuthSession } from "aws-amplify/auth";

export class ClubStore {
	transportLayer;
	rootStore;

	clubs = [];
	selectedClub = null;
	isLoading = true;

	constructor(rootStore, transportLayer) {
		makeAutoObservable(this);
		this.transportLayer = transportLayer;

		this.rootStore = rootStore;
	}

	async fetchClubs() {
		this.isLoading = true;

		const filter = {
			tenantKey: { eq: this.rootStore.tenantStore.tenantKey },
		};

		this.transportLayer
			.fetchClubs(undefined, undefined, filter)
			.then((fetchedClubs) => {
				runInAction(() => {
					fetchedClubs.forEach((club) => {
						this.updateClubFromServer(club);
					});
					this.isLoading = false;
				});
			});
	}

	async fetchClubNames() {
		const filter = {
			tenantKey: { eq: this.rootStore.tenantStore.tenantKey },
		};

		this.transportLayer
			.fetchClubsForHeader(undefined, undefined, filter)
			.then((fetchedClubs) => {
				runInAction(() => {
					fetchedClubs.forEach((club) => {
						this.updateClubFromServer(club);
					});
				});
			});
	}

	async fetchClubsAsAdmin() {
		this.isLoading = true;

		const filter = {
			tenantKey: { eq: this.rootStore.tenantStore.tenantKey },
		};

		return this.transportLayer.fetchClubsAsAdmin(filter).then((fetchedClub) => {
			runInAction(() => {
				this.selectedClub = this.updateClubFromServer(fetchedClub);
			});
			this.isLoading = false;
		});
	}

	async fetchClubsAsTenant() {
		this.isLoading = true;

		const filter = {
			tenantKey: { eq: this.rootStore.tenantStore.tenantKey },
		};

		this.transportLayer
			.fetchClubsAsTenant(undefined, undefined, filter)
			.then((fetchedClubs) => {
				runInAction(() => {
					fetchedClubs.forEach((club) => {
						this.updateClubFromServer(club);
					});
					this.isLoading = false;
				});
			});
	}

	async fetchClub(id) {
		try {
			this.isLoading = true;
			this.transportLayer.fetchClub(id).then((fetchedClub) => {
				runInAction(() => {
					if (fetchedClub) this.updateClubFromServer(fetchedClub);
					this.isLoading = false;
				});
			});
		} catch (error) {
			console.error(error);
		}
	}

	fetchClubAsAdmin(id) {
		this.isLoading = true;
		this.transportLayer
			.fetchClubAsAdmin(id)
			.then((fetchedClub) => {
				runInAction(() => {
					if (fetchedClub) this.updateClubFromServer(fetchedClub);
					this.isLoading = false;
				});
			})
			.catch((error) => {
				console.error(error);
				this.rootStore.UIStore.setErrorMessage(messages.FETCH_FAILURE);
			});
	}

	updateClubFromServer(json) {
		const club = this.getClubById(json?.id);
		if (club) {
			return club.updateClubFromServer(json);
		} else {
			const newClub = new Club(this.rootStore, json);
			this.clubs.push(newClub);
			return newClub;
		}
	}

	async updateClub(id) {
		try {
			const club = this.getClubById(id);
			const result = await this.transportLayer.updateClub(club.asJson);

			const updateClubResult = result.updateClub;
			const updateLocationResult = result.updateLocation;

			if (updateClubResult && updateClubResult.data.updateClub)
				this.updateClubFromServer(updateClubResult.data.updateClub);

			if (updateLocationResult && updateLocationResult.data.updateAddress)
				this.rootStore.locationStore.updateLocationFromServer(
					updateLocationResult.data.updateAddress,
					club,
				);

			this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	}

	async updateLogo(clubId, logo, file) {
		try {
			let result;
			if (logo) {
				console.log(logo)
				result = await uploadData({
					key: logo,
					data: file,
					options: {
						accessLevel: "protected",
						acl: "public-read",
						contentType: file.type,
					},
				}).result;
			} else {
				const ext = file.name.split(".").pop();
				const path = `logos/${clubId}.${ext}`;
				console.log(path)
				result = await uploadData({
					key: path,
					data: file,
					options: {
						accessLevel: "protected",
						contentType: file.type,
					},
				}).result;
			}

			if (result.key) {
				const authSession = await fetchAuthSession();
				const identityId = authSession?.identityId;

				const clubLogo = `protected/${identityId}/${result.key}`;
				const gqlResponse = await this.transportLayer.updateLogoOfClub(
					clubId,
					clubLogo,
				);

				const updatedClub = gqlResponse.data.updateClub;
				updatedClub.logoURI = await getLogoURI(clubLogo);

				this.updateClubFromServer(updatedClub);

				// store logo in public folder because older app versions are not able to read from protected
				await uploadData({
					key: clubLogo,
					data: file,
					options: {
						contentType: file.type,
					},
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	async createClubWithAdmin(clubData) {
		try {
			const createClubResult = await this.transportLayer.createClub(
				clubData,
				this.rootStore.tenantStore.tenantKey,
			);
			if (createClubResult) {
				// admin should be all of clubData except from name
				// eslint-disable-next-line no-unused-vars
				const { name, ...admin } = clubData;
				if (admin.username && admin.email) {
					await this.transportLayer.createAdmin(admin);
					await this.transportLayer.addAdminToClub(
						admin,
						createClubResult.clubAdminGroup,
					);
				}
				const club = await this.transportLayer.fetchClubAsTenant(
					createClubResult.id,
				);
				this.updateClubFromServer(club);
				this.rootStore.UIStore.setSuccessMessage(messages.CREATE_CLUB_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_CLUB_FAILURE);
		}
	}

	async createAdmin(adminData, clubAdminGroup) {
		try {
			const createAdminResponse =
				await this.transportLayer.createAdmin(adminData);
			const addAdminToClubResponse = await this.transportLayer.addAdminToClub(
				adminData,
				clubAdminGroup,
			);
			await this.fetchClubsAsTenant();

			if (
				createAdminResponse.data.createAdmin.errors ||
				addAdminToClubResponse.data.addAdminToClub.errors
			) {
				this.rootStore.UIStore.setErrorMessage(messages.CREATE_ADMIN_FAILURE);
			} else {
				this.rootStore.UIStore.setSuccessMessage(messages.CREATE_ADMIN_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_ADMIN_FAILURE);
		}
	}

	async updateAdmin(
		newAdminData,
		newClubAdminGroup,
		prevAdminData,
		prevClubAdminGroup,
	) {
		try {
			if (prevClubAdminGroup !== newClubAdminGroup) {
				const removeAdminFromClubResult =
					await this.transportLayer.removeAdminFromClub(
						prevAdminData,
						prevClubAdminGroup,
					);
				const addAdminToClubResult = await this.transportLayer.addAdminToClub(
					newAdminData,
					newClubAdminGroup,
				);
				if (
					removeAdminFromClubResult.data.removeAdminFromClub.errors ||
					addAdminToClubResult.data.addAdminToClub.errors
				) {
					this.rootStore.UIStore.setErrorMessage(messages.UPDATE_ADMIN_FAILURE);
				} else {
					this.rootStore.UIStore.setSuccessMessage(
						messages.UPDATE_ADMIN_SUCCESS,
					);
				}
			}
			await this.fetchClubsAsTenant();
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_ADMIN_FAILURE);
		}
	}

	async resendAdminInvitation(admin) {
		try {
			const resendAdminInvitationResponse =
				await this.transportLayer.resendAdminInvitation(admin);
			if (resendAdminInvitationResponse.data.resendInvitation.errors) {
				this.rootStore.UIStore.setErrorMessage(
					messages.RESEND_INVITATION_FAILURE,
				);
			} else {
				this.rootStore.UIStore.setSuccessMessage(
					messages.RESEND_INVITATION_SUCCESS,
				);
			}
		} catch (error) {
			console.error(error);
		}
	}

	getClubById = (id) => {
		return this.clubs.find((club) => club.id === id);
	};

	get sortedClubs() {
		return this.clubs
			.slice()
			.sort((a, b) => (a.name ? a.name.localeCompare(b.name) : -1));
	}

	get filteredClubs() {
		return this.sortedClubs.filter(
			(club) =>
				club.logo &&
				club.name &&
				club.description &&
				club.address &&
				club.address.city &&
				club.address.houseNumber &&
				club.address.street &&
				club.address.zipCode,
		);
	}

	get admins() {
		return this.clubs.reduce((prev, club) => {
			return [
				...prev,
				...club.admins.map((admin) => ({
					...admin,
					club,
				})),
			];
		}, []);
	}
}

export class Club {
	rootStore = null;
	id;
	tenantKey = null;
	tenantAdminGroup = null;
	clubAdminGroup = null;
	admins = [];
	name = null;
	description = null;
	descriptionAsHtml = null;
	logo = null;
	logoURI = null;
	address = null;
	homepage = null;
	contacts = [];
	locations = [];
	offerings = [];
	courses = [];
	events = [];

	constructor(rootStore, club) {
		makeAutoObservable(this);

		this.rootStore = rootStore;
		this.id = club?.id;
		this.updateClubFromServer(club);
	}

	setName(name) {
		this.name = name;
	}

	setDescription(description) {
		this.description = description;
	}

	setHomepage(homepage) {
		this.homepage = homepage;
	}

	get asJson() {
		return {
			id: this.id,
			tenantKey: this.tenantKey,
			tenantAdminGroup: this.tenantAdminGroup,
			clubAdminGroup: this.clubAdminGroup,
			admins: this.admins,
			name: this.name,
			description: this.description,
			descriptionAsHtml: this.descriptionAsHtml,
			logo: this.logo,
			logoURI: this.logoURI,
			address: this.address ? this.address.asJson : null,
			homepage: this.homepage,
			/*contacts: this.contacts.map((contact) => contact.asJson),
			locations: this.locations.map((location) => location.asJson),
			offerings: this.offerings.map((offering) => offering.asJson),*/
			//courses: this.courses.map((course) => course.asJson()),
		};
	}

	updateClubFromServer(club) {
		if (!club) {
			return;
		}
		if (club.tenantKey) this.tenantKey = club.tenantKey;
		if (club.tenantAdminGroup) this.tenantAdminGroup = club.tenantAdminGroup;
		if (club.clubAdminGroup) this.clubAdminGroup = club.clubAdminGroup;
		if (club.name) this.name = club.name;
		if (club.description) this.description = club.description;
		if (club.descriptionAsHtml) this.descriptionAsHtml = club.descriptionAsHtml;
		if (club.logo) this.logo = club.logo;
		if (club.homepage) this.homepage = club.homepage;
		if (club.admins) this.admins = [...club.admins];

		if (club.address) {
			this.address = this.rootStore.locationStore.updateLocationFromServer(
				club.address,
				this,
			);
		} else {
			this.address = this.rootStore.locationStore.updateLocationFromServer(
				{},
				this,
			);
		}

		if (club.events && club.events.items)
			club.events.items.forEach((event) => {
				const updatedEvent =
					this.rootStore.eventStore.updateEventFromServer(event);

				const isNew = !this.events.find(
					(current) => current.id === updatedEvent.id,
				);
				if (isNew) this.events.push(updatedEvent);
			});

		if (club.contacts && club.contacts.items)
			club.contacts.items.forEach((contact) => {
				const updatedContact =
					this.rootStore.contactStore.updateContactFromServer(contact, this);
				const isNew = !this.contacts.find(
					(current) => current.id === updatedContact.id,
				);
				if (isNew) this.contacts.push(updatedContact);
			});

		if (club.locations && club.locations.items)
			club.locations.items.forEach((location) => {
				const updatedLocation =
					this.rootStore.locationStore.updateLocationFromServer(location, this);
				const isNew = !this.locations.find(
					(current) => current.id === updatedLocation.id,
				);
				if (isNew) this.locations.push(updatedLocation);
			});

		if (club.offerings && club.offerings.items)
			club.offerings.items.forEach((offering) => {
				const updatedOffering =
					this.rootStore.offeringStore.updateOfferingFromServer(offering, this);
				const isNew = !this.offerings.find(
					(current) => current.id === updatedOffering.id,
				);
				if (isNew) this.offerings.push(updatedOffering);
			});

		if (club.courses && club.courses.items)
			club.courses.items.forEach((course) => {
				const updatedCourse =
					this.rootStore.courseStore.updateCourseFromServer(course);
				const isNew = !this.courses.find(
					(current) => current.id === updatedCourse.id,
				);
				if (isNew) this.courses.push(updatedCourse);
			});

		if (club.logo)
			getLogoURI(club.logo).then((logoURI) => {
				runInAction(() => {
					this.logoURI = logoURI.url.href;
				});
			});

		return this;
	}

	addOffering(offering) {
		this.offerings.push(offering);
	}

	removeOfferingFromClub(id) {
		const index = this.offerings.findIndex(
			(offeringInStore) => offeringInStore.id === id,
		);
		if (index > -1) this.offerings.splice(index, 1);
	}

	addCourse(course) {
		this.courses.push(course);
	}

	removeCourseFromClub(id) {
		const index = this.courses.findIndex(
			(courseInStore) => courseInStore.id === id,
		);
		if (index > -1) this.courses.splice(index, 1);
	}

	addContact(contact) {
		this.contacts.push(contact);
	}

	removeContactFromClub(id) {
		const index = this.contacts.findIndex(
			(contactInStore) => contactInStore.id === id,
		);
		if (index > -1) this.contacts.splice(index, 1);
	}

	addLocation(location) {
		this.locations.push(location);
	}

	removeLocationFromClub(id) {
		const index = this.locations.findIndex(
			(locationInStore) => locationInStore.id === id,
		);
		if (index > -1) this.locations.splice(index, 1);
	}

	addEvent(event) {
		this.events.push(event);
	}

	removeEventFromClub(id) {
		const index = this.events.findIndex(
			(eventInStore) => eventInStore.id === id,
		);
		if (index > -1) this.events.splice(index, 1);
	}

	getContactById(id) {
		return this.contacts.find((contact) => contact.id === id);
	}

	getLocationById(id) {
		return this.locations.find((location) => location.id === id);
	}

	get offeringsAndCoursesByCategory() {
		const categoryMap = new Map();
		this.sortedOfferingsAndCourses.forEach((offering) => {
			const categoryInMap = categoryMap.get(offering.category);
			if (categoryInMap) {
				categoryMap.set(offering.category, [offering, ...categoryInMap]);
			} else {
				categoryMap.set(offering.category, [offering]);
			}
		});
		const categories = [];
		categoryMap.forEach((value, key) => {
			categories.push({
				title: key,
				items: value,
			});
		});
		return categories.sort((a, b) => a?.title?.localeCompare(b?.title));
	}

	get sortedOfferingsAndCourses() {
		return [...this.offerings, ...this.courses].sort((a, b) =>
			a?.title?.localeCompare(b?.title),
		);
	}

	get sortedLocations() {
		return this.locations
			.slice()
			.sort((a, b) => (a.name ? a.name.localeCompare(b.name) : -1));
	}

	get sortedContacts() {
		return this.contacts
			.slice()
			.sort((a, b) => (a.lastName ? a.lastName.localeCompare(b.lastName) : -1));
	}
}
