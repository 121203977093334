import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material

// core components
import styles from "assets/jss/material-kit-pro-react/components/cardBodyStyle.js";

const useStyles = makeStyles(styles);

export default function CardBody(props) {
	const {
		className,
		children,
		background,
		plain,
		formHorizontal,
		pricing,
		signup,
		color,
		...rest
	} = props;
	const classes = useStyles();
	const cardBodyClasses = classNames({
		[classes.cardBody]: true,
		[classes.cardBodyBackground]: background,
		[classes.cardBodyPlain]: plain,
		[classes.cardBodyFormHorizontal]: formHorizontal,
		[classes.cardPricing]: pricing,
		[classes.cardSignup]: signup,
		[classes.cardBodyColor]: color,
		[className]: className !== undefined,
	});
	return (
		<div className={cardBodyClasses} {...rest}>
			{children}
		</div>
	);
}

CardBody.propTypes = {
	className: PropTypes.string,
	background: PropTypes.bool,
	plain: PropTypes.bool,
	formHorizontal: PropTypes.bool,
	pricing: PropTypes.bool,
	signup: PropTypes.bool,
	color: PropTypes.bool,
	children: PropTypes.node,
};
