import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";

import makeStyles from "@mui/styles/makeStyles";
import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";
import classNames from "classnames";
import FormControl from "@mui/material/FormControl";
import Clear from "@mui/icons-material/Clear";
import Check from "@mui/icons-material/Check";
import { observer } from "mobx-react";

const useStyles = makeStyles(styles);

const EditorComponent = observer((props) => {
	const {
		formControlProps,
		labelText,
		id,
		labelProps,
		editorProps,
		error,
		success,
	} = props;

	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	useEffect(() => {
		const html = editorProps.html ? editorProps.html : "";
		const contentBlock = htmlToDraft(html);
		const contentState = ContentState.createFromBlockArray(
			contentBlock.contentBlocks,
		);
		setEditorState(EditorState.createWithContent(contentState));
	}, []);

	useEffect(() => {
		const { onChange } = editorProps;
		const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		onChange(text);
	}, [editorState]);

	const onEditorStateChange = (state) => {
		setEditorState(state);
	};

	const classes = useStyles();
	const labelClasses = classNames({
		[" " + classes.labelRootError]: error,
		[" " + classes.labelRootSuccess]: success && !error,
	});
	let formControlClasses;
	if (formControlProps !== undefined) {
		formControlClasses = classNames(
			formControlProps.className,
			classes.formControl,
		);
	} else {
		formControlClasses = classes.formControl;
	}
	return (
		<FormControl
			variant="standard"
			{...formControlProps}
			className={formControlClasses}
		>
			{labelText !== undefined ? (
				<InputLabel
					className={classes.labelRoot + " " + labelClasses}
					htmlFor={id}
					{...labelProps}
				>
					{labelText}
				</InputLabel>
			) : null}
			<Editor
				editorState={editorState}
				onEditorStateChange={onEditorStateChange}
				wrapperStyle={{ marginTop: 30 }}
				editorStyle={{
					height: "300px",
					border: "solid",
					borderWidth: 1,
					borderColor: "lightGrey",
				}}
				toolbarStyle={{
					border: "solid",
					borderWidth: 1,
					borderColor: "lightGrey",
				}}
				toolbar={{
					options: ["inline", "list", "link"],
				}}
			/>
			{error ? (
				<Clear className={classes.feedback + " " + classes.labelRootError} />
			) : success ? (
				<Check className={classes.feedback + " " + classes.labelRootSuccess} />
			) : null}
		</FormControl>
	);
});

EditorComponent.propTypes = {
	formControlProps: PropTypes.object,
	labelText: PropTypes.node,
	id: PropTypes.string,
	labelProps: PropTypes.object,
	editorProps: PropTypes.object,
	error: PropTypes.bool,
	success: PropTypes.bool,
};

export default EditorComponent;
