/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const createGroup =
	/* GraphQL */
	`
		mutation CreateGroup($clubAdminGroup: String) {
			createGroup(clubAdminGroup: $clubAdminGroup)
		}
	`;
export const createAdmin = /* GraphQL */ `
	mutation CreateAdmin($adminData: AdminInput!) {
		createAdmin(adminData: $adminData) {
			adminData {
				username
				email
				status
			}
			errors {
				message
			}
		}
	}
`;
export const updateAdmin = /* GraphQL */ `
	mutation UpdateAdmin(
		$prevAdminData: AdminInput!
		$newAdminData: AdminInput!
	) {
		updateAdmin(prevAdminData: $prevAdminData, newAdminData: $newAdminData) {
			adminData {
				username
				email
				status
			}
			errors {
				message
			}
		}
	}
`;
export const addAdminToClub = /* GraphQL */ `
	mutation AddAdminToClub($adminData: AdminInput!, $clubAdminGroup: String!) {
		addAdminToClub(adminData: $adminData, clubAdminGroup: $clubAdminGroup) {
			adminData {
				username
				email
				status
			}
			errors {
				message
			}
		}
	}
`;
export const removeAdminFromClub = /* GraphQL */ `
	mutation RemoveAdminFromClub(
		$adminData: AdminInput!
		$clubAdminGroup: String!
	) {
		removeAdminFromClub(
			adminData: $adminData
			clubAdminGroup: $clubAdminGroup
		) {
			adminData {
				username
				email
				status
			}
			errors {
				message
			}
		}
	}
`;
export const resendInvitation = /* GraphQL */ `
	mutation ResendInvitation($adminData: AdminInput!) {
		resendInvitation(adminData: $adminData) {
			adminData {
				username
				email
				status
			}
			errors {
				message
			}
		}
	}
`;
export const createTenant =
	/* GraphQL */
	`
		mutation CreateTenant(
			$input: CreateTenantInput!
			$condition: ModelTenantConditionInput
		) {
			createTenant(input: $input, condition: $condition) {
				key
				name
				tenantAdminGroup
				districts
				createdAt
				updatedAt
				clubs {
					items {
						id
						tenantKey
						tenantAdminGroup
						clubAdminGroup
						name
						description
						logo
						homepage
						migratedId
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;
export const updateTenant =
	/* GraphQL */
	`
		mutation UpdateTenant(
			$input: UpdateTenantInput!
			$condition: ModelTenantConditionInput
		) {
			updateTenant(input: $input, condition: $condition) {
				key
				name
				tenantAdminGroup
				districts
				createdAt
				updatedAt
				clubs {
					items {
						id
						tenantKey
						tenantAdminGroup
						clubAdminGroup
						name
						description
						logo
						homepage
						migratedId
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;
export const deleteTenant =
	/* GraphQL */
	`
		mutation DeleteTenant(
			$input: DeleteTenantInput!
			$condition: ModelTenantConditionInput
		) {
			deleteTenant(input: $input, condition: $condition) {
				key
				name
				tenantAdminGroup
				districts
				createdAt
				updatedAt
				clubs {
					items {
						id
						tenantKey
						tenantAdminGroup
						clubAdminGroup
						name
						description
						logo
						homepage
						migratedId
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;
export const deleteClub =
	/* GraphQL */
	`
		mutation DeleteClub(
			$input: DeleteClubInput!
			$condition: ModelClubConditionInput
		) {
			deleteClub(input: $input, condition: $condition) {
				id
				tenantKey
				tenantAdminGroup
				clubAdminGroup
				admins {
					username
					email
				}
				name
				description
				logo
				homepage
				membershipCosts {
					ageGroup
					cost
					billingInterval
				}
				migratedId
				createdAt
				updatedAt
				tenant {
					key
					name
					tenantAdminGroup
					districts
					createdAt
					updatedAt
					clubs {
						nextToken
					}
				}
				offerings {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						title
						category
						district
						description
						hints
						createdAt
						updatedAt
					}
					nextToken
				}
				address {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					name
					street
					houseNumber
					zipCode
					city
					createdAt
					updatedAt
				}
				locations {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						name
						street
						houseNumber
						zipCode
						city
						createdAt
						updatedAt
					}
					nextToken
				}
				contacts {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						firstName
						lastName
						email
						phone
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;
export const createClub =
	/* GraphQL */
	`
		mutation CreateClub(
			$input: CreateClubInput!
			$condition: ModelClubConditionInput
		) {
			createClub(input: $input, condition: $condition) {
				id
				tenantKey
				tenantAdminGroup
				clubAdminGroup
				admins {
					username
					email
				}
				name
				description
				descriptionAsHtml
				logo
				homepage
				membershipCosts {
					ageGroup
					cost
					billingInterval
				}
				migratedId
				createdAt
				updatedAt
				tenant {
					key
					name
					tenantAdminGroup
					districts
					createdAt
					updatedAt
					clubs {
						nextToken
					}
				}
				offerings {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						title
						category
						district
						description
						hints
						createdAt
						updatedAt
					}
					nextToken
				}
				address {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					name
					street
					houseNumber
					zipCode
					city
					createdAt
					updatedAt
				}
				locations {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						name
						street
						houseNumber
						zipCode
						city
						createdAt
						updatedAt
					}
					nextToken
				}
				contacts {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						firstName
						lastName
						email
						phone
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;
export const updateClub =
	/* GraphQL */
	`
		mutation UpdateClub(
			$input: UpdateClubInput!
			$condition: ModelClubConditionInput
		) {
			updateClub(input: $input, condition: $condition) {
				id
				tenantAdminGroup
				clubAdminGroup
				name
				description
				descriptionAsHtml
				logo
				homepage
				membershipCosts {
					ageGroup
					cost
					billingInterval
				}
				migratedId
				createdAt
				updatedAt
				offerings {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						title
						category
						district
						description
						hints
						createdAt
						updatedAt
					}
					nextToken
				}
				address {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					name
					street
					houseNumber
					zipCode
					city
					createdAt
					updatedAt
				}
				locations {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						name
						street
						houseNumber
						zipCode
						city
						createdAt
						updatedAt
					}
					nextToken
				}
				contacts {
					items {
						id
						clubID
						tenantAdminGroup
						clubAdminGroup
						firstName
						lastName
						email
						phone
						createdAt
						updatedAt
					}
					nextToken
				}
			}
		}
	`;
export const createEvent = /* GraphQL */ `
	mutation CreateEvent(
		$input: CreateEventInput!
		$condition: ModelEventConditionInput
	) {
		createEvent(input: $input, condition: $condition) {
			id
			clubID
			tenantAdminGroup
			clubAdminGroup
			name
			description
			descriptionAsHtml
			image
			tagline
			details
			schedule {
				from
				to
			}
			sponsors {
				name
				logo
				url
				callToAction
			}
			createdAt
			updatedAt
		}
	}
`;
export const updateEvent = /* GraphQL */ `
	mutation UpdateEvent(
		$input: UpdateEventInput!
		$condition: ModelEventConditionInput
	) {
		updateEvent(input: $input, condition: $condition) {
			id
			clubID
			tenantAdminGroup
			clubAdminGroup
			name
			description
			descriptionAsHtml
			image
			tagline
			details
			schedule {
				from
				to
			}
			sponsors {
				name
				logo
				url
				callToAction
			}
			createdAt
			updatedAt
		}
	}
`;
export const deleteEvent = /* GraphQL */ `
	mutation DeleteEvent(
		$input: DeleteEventInput!
		$condition: ModelEventConditionInput
	) {
		deleteEvent(input: $input, condition: $condition) {
			id
		}
	}
`;
export const createOffering =
	/* GraphQL */
	`
		mutation CreateOffering(
			$input: CreateOfferingInput!
			$condition: ModelOfferingConditionInput
		) {
			createOffering(input: $input, condition: $condition) {
				id
				clubID
				club {
					id
					name
					logo
				}
				tenantAdminGroup
				clubAdminGroup
				title
				category
				district
				description
				descriptionAsHtml
				targetGroup {
					male
					female
					from
					to
				}
				schedules {
					day
					from
					to
				}
				costs {
					ageGroup
					cost
					billingInterval
				}
				hints
				createdAt
				updatedAt
				location {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					name
					street
					houseNumber
					zipCode
					city
					createdAt
					updatedAt
				}
				contact {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					firstName
					lastName
					email
					phone
					createdAt
					updatedAt
				}
				createdAt
				updatedAt
			}
		}
	`;
export const updateOffering =
	/* GraphQL */
	`
		mutation UpdateOffering(
			$input: UpdateOfferingInput!
			$condition: ModelOfferingConditionInput
		) {
			updateOffering(input: $input, condition: $condition) {
				id
				clubID
				tenantAdminGroup
				clubAdminGroup
				title
				category
				district
				description
				descriptionAsHtml
				image
				targetGroup {
					male
					female
					from
					to
				}
				schedules {
					day
					from
					to
				}
				costs {
					ageGroup
					cost
					billingInterval
				}
				hints
				createdAt
				updatedAt
				location {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					name
					street
					houseNumber
					zipCode
					city
					createdAt
					updatedAt
				}
				contact {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					firstName
					lastName
					email
					phone
					createdAt
					updatedAt
				}
				createdAt
				updatedAt
			}
		}
	`;
export const deleteOffering =
	/* GraphQL */
	`
		mutation DeleteOffering(
			$input: DeleteOfferingInput!
			$condition: ModelOfferingConditionInput
		) {
			deleteOffering(input: $input, condition: $condition) {
				id
				clubID
				tenantAdminGroup
				clubAdminGroup
				title
				category
				district
				description
				targetGroup {
					male
					female
					from
					to
				}
				schedules {
					day
					from
					to
				}
				costs {
					ageGroup
					cost
					billingInterval
				}
				hints
				createdAt
				updatedAt
				location {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					name
					street
					houseNumber
					zipCode
					city
					createdAt
					updatedAt
				}
				contact {
					id
					clubID
					tenantAdminGroup
					clubAdminGroup
					firstName
					lastName
					email
					phone
					createdAt
					updatedAt
				}
			}
		}
	`;
export const createCourse = /* GraphQL */ `
	mutation CreateCourse(
		$input: CreateCourseInput!
		$condition: ModelCourseConditionInput
	) {
		createCourse(input: $input, condition: $condition) {
			id
			clubID
			tenantAdminGroup
			clubAdminGroup
			category
			district
			title
			description
			descriptionAsHtml
			image
			eventID
			club {
				id
				name
				logo
			}
			event {
				id
				name
				tagline
				description
				descriptionAsHtml
				details
				image
			}
			targetGroup {
				male
				female
				from
				to
			}
			schedule {
				startingDate
				schedules {
					day
					from
					to
				}
				finalDate
				dates
				fullDay
			}
			costs {
				ageGroup
				cost
				billingInterval
			}
			hints
			contact {
				id
				firstName
				lastName
				email
				phone
				createdAt
				updatedAt
			}
			location {
				id
				name
				street
				houseNumber
				zipCode
				city
				createdAt
				updatedAt
			}
			registration {
				participantLimit
				registrationLink
				openingDate
				closingDate
			}
			createdAt
			updatedAt
		}
	}
`;
export const updateCourse = /* GraphQL */ `
	mutation UpdateCourse(
		$input: UpdateCourseInput!
		$condition: ModelCourseConditionInput
	) {
		updateCourse(input: $input, condition: $condition) {
			id
			category
			district
			title
			description
			descriptionAsHtml
			image
			eventID
			club {
				id
				name
				logo
			}
			event {
				id
			}
			targetGroup {
				male
				female
				from
				to
			}
			schedule {
				startingDate
				schedules {
					day
					from
					to
				}
				finalDate
				dates
				fullDay
			}
			costs {
				ageGroup
				cost
				billingInterval
			}
			hints
			contact {
				id
				firstName
				lastName
				email
				phone
				createdAt
				updatedAt
			}
			location {
				id
				name
				street
				houseNumber
				zipCode
				city
				createdAt
				updatedAt
			}
			registration {
				participantLimit
				registrationLink
				openingDate
				closingDate
			}
			createdAt
			updatedAt
		}
	}
`;
export const deleteCourse = /* GraphQL */ `
	mutation DeleteCourse(
		$input: DeleteCourseInput!
		$condition: ModelCourseConditionInput
	) {
		deleteCourse(input: $input, condition: $condition) {
			id
			category
			district
			title
			description
			image
			club {
				id
				name
				logo
			}
			event {
				id
			}
			targetGroup {
				male
				female
				from
				to
			}
			schedule {
				startingDate
				schedules {
					day
					from
					to
				}
				finalDate
				dates
				fullDay
			}
			costs {
				ageGroup
				cost
				billingInterval
			}
			hints
			contact {
				id
				firstName
				lastName
				email
				phone
				createdAt
				updatedAt
			}
			location {
				id
				name
				street
				houseNumber
				zipCode
				city
				createdAt
				updatedAt
			}
			registration {
				participantLimit
				registrationLink
				openingDate
				closingDate
			}
		}
	}
`;
export const createAddress =
	/* GraphQL */
	`
		mutation CreateAddress(
			$input: CreateAddressInput!
			$condition: ModelAddressConditionInput
		) {
			createAddress(input: $input, condition: $condition) {
				id
				clubID
				tenantAdminGroup
				clubAdminGroup
				name
				street
				houseNumber
				zipCode
				city
				geoCoordinates {
					longitude
					latitude
				}
				createdAt
				updatedAt
			}
		}
	`;
export const updateAddress =
	/* GraphQL */
	`
		mutation UpdateAddress(
			$input: UpdateAddressInput!
			$condition: ModelAddressConditionInput
		) {
			updateAddress(input: $input, condition: $condition) {
				id
				clubID
				tenantAdminGroup
				clubAdminGroup
				name
				street
				houseNumber
				zipCode
				city
				geoCoordinates {
					longitude
					latitude
				}
				createdAt
				updatedAt
			}
		}
	`;
export const deleteAddress =
	/* GraphQL */
	`
		mutation DeleteAddress(
			$input: DeleteAddressInput!
			$condition: ModelAddressConditionInput
		) {
			deleteAddress(input: $input, condition: $condition) {
				id
				clubID
				tenantAdminGroup
				clubAdminGroup
				name
				street
				houseNumber
				zipCode
				city
				createdAt
				updatedAt
			}
		}
	`;
export const createContact =
	/* GraphQL */
	`
		mutation CreateContact(
			$input: CreateContactInput!
			$condition: ModelContactConditionInput
		) {
			createContact(input: $input, condition: $condition) {
				id
				clubID
				tenantAdminGroup
				clubAdminGroup
				firstName
				lastName
				email
				phone
				createdAt
				updatedAt
			}
		}
	`;
export const updateContact =
	/* GraphQL */
	`
		mutation UpdateContact(
			$input: UpdateContactInput!
			$condition: ModelContactConditionInput
		) {
			updateContact(input: $input, condition: $condition) {
				id
				clubID
				tenantAdminGroup
				clubAdminGroup
				firstName
				lastName
				email
				phone
				createdAt
				updatedAt
			}
		}
	`;
export const deleteContact =
	/* GraphQL */
	`
		mutation DeleteContact(
			$input: DeleteContactInput!
			$condition: ModelContactConditionInput
		) {
			deleteContact(input: $input, condition: $condition) {
				id
				clubID
				tenantAdminGroup
				clubAdminGroup
				firstName
				lastName
				email
				phone
				createdAt
				updatedAt
			}
		}
	`;
