import React from "react";
import { observer } from "mobx-react";
import ScheduleListItem from "./ScheduleListItem";

const ScheduleList = observer(
	({ schedules, onSetSchedule, onRemoveSchedule }) => {
		const findSchedule = (day, schedules) =>
			schedules ? schedules.find((schedule) => schedule.day === day) : null;

		return (
			<div>
				<ScheduleListItem
					day={"MONDAY"}
					onSetSchedule={onSetSchedule}
					onRemoveSchedule={onRemoveSchedule}
					schedule={findSchedule("MONDAY", schedules)}
				/>
				<ScheduleListItem
					day={"TUESDAY"}
					onSetSchedule={onSetSchedule}
					onRemoveSchedule={onRemoveSchedule}
					schedule={findSchedule("TUESDAY", schedules)}
				/>
				<ScheduleListItem
					day={"WEDNESDAY"}
					onSetSchedule={onSetSchedule}
					onRemoveSchedule={onRemoveSchedule}
					schedule={findSchedule("WEDNESDAY", schedules)}
				/>
				<ScheduleListItem
					day={"THURSDAY"}
					onSetSchedule={onSetSchedule}
					onRemoveSchedule={onRemoveSchedule}
					schedule={findSchedule("THURSDAY", schedules)}
				/>
				<ScheduleListItem
					day={"FRIDAY"}
					onSetSchedule={onSetSchedule}
					onRemoveSchedule={onRemoveSchedule}
					schedule={findSchedule("FRIDAY", schedules)}
				/>
				<ScheduleListItem
					day={"SATURDAY"}
					onSetSchedule={onSetSchedule}
					onRemoveSchedule={onRemoveSchedule}
					schedule={findSchedule("SATURDAY", schedules)}
				/>
				<ScheduleListItem
					day={"SUNDAY"}
					onSetSchedule={onSetSchedule}
					onRemoveSchedule={onRemoveSchedule}
					schedule={findSchedule("SUNDAY", schedules)}
				/>
			</div>
		);
	},
);

export default ScheduleList;
