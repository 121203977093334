import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
// @mui/icons-material
import Clear from "@mui/icons-material/Clear";
import Check from "@mui/icons-material/Check";
// core components

import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
	const {
		formControlProps,
		labelText,
		id,
		labelProps,
		inputProps,
		error,
		white,
		inputRootCustomClasses,
		success,
	} = props;
	const classes = useStyles();
	const labelClasses = classNames({
		[" " + classes.labelRootError]: error,
		[" " + classes.labelRootSuccess]: success && !error,
	});
	const underlineClasses = classNames({
		[classes.underlineError]: error,
		[classes.underlineSuccess]: success && !error,
		[classes.underline]: true,
		[classes.whiteUnderline]: white,
	});
	const marginTop = classNames({
		[inputRootCustomClasses]: inputRootCustomClasses !== undefined,
	});
	const inputClasses = classNames({
		[classes.input]: true,
		[classes.whiteInput]: white,
	});
	var formControlClasses;
	if (formControlProps !== undefined) {
		formControlClasses = classNames(
			formControlProps.className,
			classes.formControl,
		);
	} else {
		formControlClasses = classes.formControl;
	}
	let newInputProps = {
		maxLength: inputProps ? inputProps.maxLength : undefined,
		minLength: inputProps ? inputProps.minLength : undefined,
	};
	return (
		<FormControl
			variant="standard"
			{...formControlProps}
			className={formControlClasses}
		>
			{labelText !== undefined ? (
				<InputLabel
					className={classes.labelRoot + " " + labelClasses}
					htmlFor={id}
					{...labelProps}
				>
					{labelText}
				</InputLabel>
			) : null}
			<Input
				classes={{
					input: inputClasses,
					root: marginTop,
					disabled: classes.disabled,
					underline: underlineClasses,
				}}
				id={id}
				{...inputProps}
				inputProps={newInputProps}
			/>
			{error ? (
				<Clear className={classes.feedback + " " + classes.labelRootError} />
			) : success ? (
				<Check className={classes.feedback + " " + classes.labelRootSuccess} />
			) : null}
		</FormControl>
	);
}

CustomInput.propTypes = {
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
};
