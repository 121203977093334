import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Table from "components/Table/Table";

import {
	getCategoryImageURI,
	getSchedulesToDisplay,
	getAgeGroupToDisplay,
} from "../../../utils/Utils";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles";
import Button from "../../../components/CustomButtons/Button";
import placeholder from "../../../customAssets/img/image-placeholder.png";

import ClubLogoImg from "customComponents/ClubLogoImg/ClubLogoImg";
import PropTypes from "prop-types";
import { RootStoreContext } from "mobX/RootStore";

const useStyles = makeStyles(() => ({
	...teamsStyle,
	...teamStyle,
	...imagesStyles,
	customImgStyles: {
		width: 160,
		height: 110,
		objectFit: "cover",
		"@media (max-width: 470px)": {
			width: 80,
			height: 60,
			minWidth: 80,
			minHeight: 60,
		},
	},
	imgWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	categoryTitle: {
		marginLeft: 20,
		marginTop: "auto",
		marginBottom: "auto",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
		overflow: "hidden",
		"@media (max-width: 470px)": {
			fontSize: 18,
		},
	},
	clubIcon: {
		width: 70,
	},
	activityTitle: {
		minWidth: 60,
	},
	activityType: {
		width: 75,
	},
	schedule: {
		width: "20%",
		minWidth: 90,
		"@media (max-width: 500px)": {
			display: "none",
		},
	},
	targetGroup: {
		width: 100,
		"@media (max-width: 770px)": {
			display: "none",
		},
	},
	detailsButton: {
		width: 50,
	},
	img: {
		width: 45,
		height: 45,
	},
	expanded: {
		margin: "0 !important",
	},
	summaryExpanded: {
		margin: "12px 0px !important",
	},
	root: {
		borderBottom: "solid",
		borderBottomWidth: "1px",
		color: "lightgrey",
	},
	detailsRoot: {
		padding: 0,
	},
	offeringTitleWrapper: {
		display: "flex",
		flexDirection: "row",
	},
}));

const OfferingLink = ({ offering, buttonClassName, tenantPath }) => (
	<Link
		to={`/${tenantPath}/offering/${encodeURIComponent(offering.title)}?id=${offering.id}`}
	>
		<Button color="primary" className={buttonClassName}>
			<ArrowForwardIcon />
		</Button>
	</Link>
);

OfferingLink.propTypes = {
	offering: PropTypes.object,
	buttonClassName: PropTypes.string,
};

const CourseLink = ({ course, buttonClassName, tenantPath }) => (
	<Link
		to={`/${tenantPath}/course/${encodeURIComponent(course.title)}?id=${course.id}`}
	>
		<Button color="primary" className={buttonClassName}>
			<ArrowForwardIcon />
		</Button>
	</Link>
);

CourseLink.propTypes = {
	course: PropTypes.object,
	buttonClassName: PropTypes.string,
};

const CategoryAccordion = observer(({ category, offerings, expand }) => {
	const [expanded, setExpanded] = useState(false);

	const store = useContext(RootStoreContext);
	const { tenantStore } = store;
	const tenant = tenantStore.data;

	useEffect(() => {
		setExpanded(expand);
	}, []);

	const classes = useStyles();
	const imageClasses = classNames(
		classes.imgRounded,
		classes.imgFluid,
		classes.customImgStyles,
	);

	const getActivityType = (type) => {
		switch (type) {
			case "COURSE":
				return "Kurs";
			case "TRAINING":
				return "Training";
			default:
				return "-";
		}
	};

	return (
		<Accordion
			classes={{ root: classes.root, expanded: classes.expanded }}
			TransitionProps={{ unmountOnExit: true }}
			elevation={0}
			expanded={expanded}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				classes={{ content: classes.summaryExpanded }}
				onClick={() => setExpanded(!expanded)}
			>
				<div className={classes.imgWrapper}>
					<img
						src={getCategoryImageURI(category)}
						alt={category}
						className={imageClasses}
						onError={(error) => {
							error.target.src = placeholder;
						}}
					/>
				</div>
				<Typography
					variant="h5"
					className={classNames(classes.title, classes.categoryTitle)}
				>
					{category}
				</Typography>
			</AccordionSummary>
			<AccordionDetails classes={{ root: classes.detailsRoot }}>
				<div style={{ width: "100%" }}>
					<Table
						striped
						tableData={offerings
							.sort((a, b) => a.title.localeCompare(b.title))
							.map((offering, index) => {
								const creationDate = Date.parse(offering.createdAt);
								const currentDate = Date.now();
								const isNew =
									currentDate - creationDate <= 1000 * 3600 * 24 * 14;

								return offering.type === "COURSE"
									? [
											<ClubLogoImg
												key={`nextButton-${index}`}
												clubLogo={offering.clubLogo}
												alt={offering.title}
												imgClasses={classes.img}
											/>,
											<div
												key={`title-${index}`}
												className={classes.offeringTitleWrapper}
											>
												{isNew && <p style={{ color: "red" }}>NEU&nbsp;</p>}{" "}
												<p>{offering.title}</p>
											</div>,
											getActivityType(offering.type),
											offering.schedule
												? getSchedulesToDisplay(offering.schedule.schedules)
												: "-",
											offering.targetGroup
												? getAgeGroupToDisplay(offering.targetGroup)
												: "-",
											<CourseLink
												key={`nextButton-${index}`}
												course={offering}
												buttonClassName={classes.detailsButton}
												tenantPath={tenant.path}
											/>,
										]
									: [
											<ClubLogoImg
												key={`nextButton-${index}`}
												clubLogo={offering.clubLogo}
												alt={offering.title}
												imgClasses={classes.img}
											/>,
											<div
												key={`title-${index}`}
												className={classes.offeringTitleWrapper}
											>
												{isNew && <p style={{ color: "red" }}>NEU&nbsp;</p>}{" "}
												<p>{offering.title}</p>
											</div>,
											getActivityType(offering.type),
											offering.schedules
												? getSchedulesToDisplay(offering.schedules)
												: "-",
											offering.targetGroup
												? getAgeGroupToDisplay(offering.targetGroup)
												: "-",
											<OfferingLink
												key={`nextButton-${index}`}
												offering={offering}
												buttonClassName={classes.detailsButton}
												tenantPath={tenant.path}
											/>,
										];
							})}
						customCellClasses={[
							classes.clubIcon,
							classes.activityTitle,
							classes.activityType,
							classes.schedule,
							classes.targetGroup,
							classes.detailsButton,
						]}
						customClassesForCells={[0, 1, 2, 3, 4, 5]}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
});

export default CategoryAccordion;
