import { makeAutoObservable, runInAction } from "mobx";
import messages from "../utils/messages";
import { getUrl, uploadData, remove } from "aws-amplify/storage";
import { buildImageKey, getFilteredCourses } from "../utils/Utils";

export class CourseStore {
	transportLayer;
	rootStore;
	courses = [];
	isLoading = null;

	constructor(rootStore, transportLayer) {
		makeAutoObservable(this);
		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	updateCourseFromServer(json, event) {
		const course = this.courses.find(
			(courseInStore) => courseInStore.id === json.id,
		);
		if (course) {
			return course.updateCourseFromServer(json);
		} else {
			const newCourse = new Course(json, this.rootStore, event);
			this.courses.push(newCourse);
			return newCourse;
		}
	}

	fetchCourse(id) {
		this.isLoading = true;
		this.transportLayer.fetchCourse(id).then((fetchedCourse) => {
			runInAction(() => {
				if (fetchedCourse) {
					this.updateCourseFromServer(fetchedCourse);
				}
				this.isLoading = false;
			});
		});
	}

	fetchCourses() {
		this.isLoading = true;

		const filter = {
			tenantAdminGroup: { beginsWith: this.rootStore.tenantStore.tenantKey },
		};

		this.transportLayer
			.fetchAllCourses(undefined, undefined, filter)
			.then((res) => {
				runInAction(() => {
					if (res)
						res.forEach((course) => {
							this.updateCourseFromServer(course);
						});
					this.isLoading = false;
				});
			});
	}

	fetchCategories() {
		this.isLoading = true;

		const filter = {
			tenantAdminGroup: { beginsWith: this.rootStore.tenantStore.tenantKey },
		};

		this.transportLayer
			.fetchCourseCategories(undefined, undefined, filter)
			.then((res) => {
				runInAction(() => {
					if (res)
						res.forEach((course) => {
							this.updateCourseFromServer(course);
						});
					this.isLoading = false;
				});
			});
	}

	async updateCourse(course, imageFile) {
		try {
			if (imageFile) {
				if (course.image) {
					const imageKey = course.image.slice(
						course.image.indexOf("courseImages"),
					);
					await remove({
						key: imageKey,
						options: {
							accessLevel: "protected",
						},
					});
				}

				const ext = imageFile.name.split(".").pop();
				const path = `courseImages/${buildImageKey(imageFile)}.${ext}`;

				const result = await uploadData({
					key: path,
					data: imageFile,
					options: {
						accessLevel: "protected",
					},
				}).result;

				if (result.key) {
					const urlResult = await getUrl({
						key: result.key,
						options: {
							accessLevel: "protected",
						},
					});
					const fullUrl = urlResult.url.toString();
					course.image = fullUrl.split("?")[0];
				}
			}

			const response = await this.transportLayer.updateCourse(course);
			if (response.data.updateCourse) {
				const updatedCourse = response.data.updateCourse;
				this.updateCourseFromServer(updatedCourse);

				this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	}

	async createCourse(
		course,
		clubId,
		tenantAdminGroup,
		clubAdminGroup,
		imageFile,
	) {
		try {
			if (imageFile) {
				const ext = imageFile.name.split(".").pop();
				const path = `courseImages/${buildImageKey(imageFile)}.${ext}`;

				const result = await uploadData({
					key: path,
					data: imageFile,
					options: {
						accessLevel: "protected",
					},
				}).result;

				if (result.key) {
					const urlResult = await getUrl({
						key: result.key,
						options: {
							accessLevel: "protected",
						},
					});
					const fullUrl = urlResult.url.toString();
					course.image = fullUrl.split("?")[0];
				}
			}

			const response = await this.transportLayer.createCourse(
				course,
				clubId,
				tenantAdminGroup,
				clubAdminGroup,
			);
			if (response.data.createCourse) {
				const newCourse = this.updateCourseFromServer(
					response.data.createCourse,
				);
				//this.courses.push(newCourse);
				const club = this.rootStore.clubStore.getClubById(clubId);
				club.addCourse(newCourse);
				this.rootStore.UIStore.setSuccessMessage(
					messages.CREATE_COURSE_SUCCESS,
				);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_COURSE_FAILURE);
		}
	}

	async deleteCourse(clubId, courseId) {
		try {
			const response = await this.transportLayer.deleteCourse(courseId);

			if (response.data.deleteCourse) {
				const courseInStore = this.getCourseById(courseId);
				courseInStore.removeFromStore();
				this.rootStore.UIStore.setSuccessMessage(messages.DELETE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.DELETE_FAILURE);
		}
	}

	getCourseById = (id) => {
		return this.courses.find((course) => course.id === id);
	};

	removeCourseFromStore(id) {
		const index = this.courses.findIndex(
			(courseInStore) => courseInStore.id === id,
		);
		if (index > -1) this.courses.splice(index, 1);
	}

	filteredCourses(filter) {
		return getFilteredCourses(filter, this.validCourses);
	}

	get validCourses() {
		return this.courses.filter((course) => {
			return course.title && course.category && course.club.logo;
		});
	}

	get validCategories() {
		const categorySet = new Set();
		this.validCourses.forEach(({ category }) => categorySet.add(category));
		const categories = [];
		categorySet.forEach((category) => categories.push(category));
		return categories.sort();
	}
}

export class Course {
	rootStore;
	id;
	category = null;
	district = null;
	title = null;
	description = null;
	descriptionAsHtml = null;
	club = null;
	contact = null;
	event = null;
	hints = null;
	location = null;
	registration = null;
	schedule = null;
	targetGroup = null;
	costs = null;
	clubLogo = null;
	type = "COURSE";
	eventID = null;
	createdAt = null;

	constructor(course, rootStore, event) {
		makeAutoObservable(this);

		this.id = course.id;
		this.rootStore = rootStore;
		this.updateCourseFromServer(course, event);
	}

	get asJson() {
		return {
			id: this.id,
			category: this.category,
			district: this.district,
			title: this.title,
			description: this.description,
			descriptionAsHtml: this.descriptionAsHtml,
			club: this.club,
			contact: this.contact ? this.contact.asJson : null,
			location: this.location ? this.location.asJson : null,
			event: this.event,
			hints: this.hints,
			registration: this.registration,
			schedule: this.schedule,
			targetGroup: this.targetGroup,
			costs: this.costs,
			image: this.image,
			clubLogo: this.clubLogo,
			type: this.type,
			eventID: this.eventID,
			createdAt: this.createdAt,
		};
	}

	updateCourseFromServer(course, event) {
		if (course.category) this.category = course.category;
		if (course.district) this.district = course.district;
		if (course.title) this.title = course.title;
		if (course.description) this.description = course.description;
		if (course.descriptionAsHtml)
			this.descriptionAsHtml = course.descriptionAsHtml;
		if (course.club) this.club = course.club;
		if (course.hints) this.hints = course.hints;
		if (course.registration) this.registration = course.registration;
		if (course.schedule) this.schedule = course.schedule;
		if (course.targetGroup) this.targetGroup = course.targetGroup;
		if (course.costs) this.costs = course.costs;
		if (course.image) this.image = course.image;
		if (course.club) this.clubLogo = course.club.logo;
		if (course.eventID) this.eventID = course.eventID;
		if (course.createdAt) this.createdAt = course.createdAt;

		if (event) this.event = event;

		if (course.contact) {
			this.contact = this.rootStore.contactStore.updateContactFromServer(
				course.contact,
			);
		}

		if (course.location) {
			this.location = this.rootStore.locationStore.updateLocationFromServer(
				course.location,
			);
		}

		return this;
	}

	removeFromStore() {
		if (this.club && this.club.id) {
			const club = this.rootStore.clubStore.getClubById(this.club.id);
			if (club) club.removeCourseFromClub(this.id);
		}
		this.rootStore.courseStore.removeCourseFromStore(this.id);
	}
}
