const categories = [
	"Aerobic",
	"Aikido",
	"Akrobatik",
	"Alpinklettern",
	"American Football",
	"Angelsport",
	"Apnoetauchen",
	"Aquaball",
	"Aquathlon",
	"Arena Football",
	"Automobilsport",
	"BMX",
	"Badminton",
	"Bahnengolf",
	"Bahnradsport",
	"Baseball",
	"Basketball",
	"Beachbasketball",
	"Beachhandball",
	"Beachminton",
	"Beachrugby",
	"Beachsoccer",
	"Beachvolleyball",
	"Billard",
	"Blindenfußball",
	"Boccia",
	"Bogenschießen",
	"Bouldern",
	"Bowling",
	"Boxen",
	"Boßeln",
	"Brazilian Jiu-Jitsu",
	"Breakdance",
	"Callanetics",
	"Capoeira",
	"Carambolage",
	"Cheerleading",
	"Cricket",
	"Functional Fitness",
	"Crossgolf",
	"Darts",
	"Diskuswurf",
	"Disc Golf",
	"Downhill",
	"Drachenboot",
	"Dreisprung",
	"Dressurreiten",
	"E-Sport",
	"Einradbasketball",
	"Einradhockey",
	"Eishockey",
	"Eiskunstlauf",
	"Eisschnelllauf",
	"Eisspeedway",
	"Eissport",
	"Eisstockschiessen",
	"Faustball",
	"Fechten",
	"Feldhandball",
	"Feldkorbball",
	"Fitness",
	"Flag Football",
	"Floorball",
	"Flugmodellsport",
	"Footbag (HackySack)",
	"Futsal",
	"Fußball",
	"Fußballtennis",
	"Garde- und Schautanz",
	"Geocaching",
	"Gerätturnen",
	"Gewichtheben",
	"Gesundheitssport",
	"Golf",
	"Gravel",
	"Gymnastik",
	"Hammerwurf",
	"Handball",
	"Hochsprung",
	"Hockey",
	"Hürdenlauf",
	"Indiaca",
	"Indoorcycling",
	"Inline-Skaterhockey",
	"Inline-Speedskating",
	"Inlinehockey",
	"Inlineskating",
	"Integration durch Sport",
	"Jazz Dance",
	"Ju-Jutsu",
	"Judo",
	"Kali",
	"Kanupolo",
	"Kanurennsport",
	"Kanuslalom",
	"Karate",
	"Kartsport",
	"Kegelbillard",
	"Kegeln",
	"Kendo",
	"Kickboxen",
	"Kin-Ball",
	"Kitelandboarding",
	"Kitesurfen",
	"Klettern",
	"Klippenspringen",
	"Korbball",
	"Korfball",
	"Kraftdreikampf",
	"Kraftsport",
	"Kreuzheben",
	"Kugelstoßen",
	"Kung Fu",
	"Kunst- und Turmspringen",
	"Kunstflug (Flugakrobatik)",
	"Kunstradfahren",
	"Lacrosse",
	"Langstreckenlauf",
	"Lateinamerikanische Tänze",
	"Leichtathletik",
	"LineDance",
	"Mesoamerikanisches Ballspiel",
	"Minigolf",
	"Mittelstreckenlauf",
	"Moderner Fünfkampf",
	"Motorbootsport",
	"Motorradsport",
	"Mountainbike",
	"Nordic Walking",
	"Orientalischer Tanz",
	"Paintball",
	"Parkour",
	"Pferderennen",
	"Pilates",
	"Platzgen",
	"Polo",
	"Polocrosse",
	"Poolbillard",
	"Pound",
	"Prellball",
	"Pétanque",
	"Racketlon",
	"Radpolo",
	"Radsport",
	"Rasenkraftsport",
	"Rehasport",
	"Reiten",
	"Rennrad",
	"Rettungssport",
	"Rhythmische Sportgymnastik",
	"Rhönradturnen",
	"Ringen",
	"Rock’n’Roll (Tanz)",
	"Rollhockey",
	"Rollkunstlauf",
	"Rollstuhlbasketball",
	"Rollstuhlrugby",
	"Rollstuhltanz",
	"Round Dance",
	"Rudern",
	"Rugby",
	"Savate",
	"Schach",
	"Schachboxen",
	"Schießen",
	"Schlagball",
	"Schleuderball",
	"Schnorcheln",
	"Schwimmen",
	"Schwingen",
	"Segelfliegen",
	"Segeln",
	"Segwaypolo",
	"Seilspringen",
	"Shorttrack",
	"Siebenkampf",
	"Sitzball",
	"Sitzfußball",
	"Sitzvolleyball",
	"Skateboarding",
	"Skeleton",
	"Ski Nordisch",
	"Ski und Snowboard",
	"Skilanglauf",
	"Slackline",
	"Snooker",
	"Snowboard",
	"Soccergolf",
	"Softball",
	"Speed Badminton",
	"Speerwurf",
	"Sport Stacking",
	//"Sport im Park",
	"Springreiten",
	"Sprint",
	"Square Dance",
	"Squash",
	"Stabhochsprung",
	"Staffellauf",
	"Standardtanz",
	"Stehpaddeln",
	"Steinstoßen",
	"Steinwerfen",
	"Stepptanz",
	"Stockschießen",
	"Synchroneiskunstlauf",
	"Synchronschwimmen",
	"Tae Bo",
	"Taekwondo",
	"Tamburello",
	"Tanzen",
	"Tanzsport",
	"Tauchen",
	"Tchoukball",
	"Tennis",
	"Tischtennis",
	"Theatersport",
	"Trampolinturnen",
	"Trekking",
	"Tretrollersport",
	"Tretze",
	"Trials",
	"Triathlon",
	"Tumbling",
	"Turnen",
	"Unihockey",
	"Unterwasserhockey",
	"Unterwasserrugby",
	"Volleyball",
	"Voltigieren",
	"Völkerball",
	"Wakeboarding",
	"Walking",
	"Wandern",
	"Wasserball",
	"Wasserski",
	"Weitsprung",
	"Wellenreiten",
	"Westernreiten",
	"Windsurfen",
	"Wing Tsun",
	"Yoga",
	"Zehnkampf",
	"Zirkus",
	"Zumba",
];

export default categories;
