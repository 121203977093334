import React from "react";
import { observer } from "mobx-react";
// @mui/material
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";

// @mui/icons-material
import Check from "@mui/icons-material/Check";

import styles from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle";

const useStyles = makeStyles(styles);

const AgeCheckBoxes = observer(({ ageGroups = [], toggleAgeGroup }) => {
	const handleToggle = (ageGroup) => {
		toggleAgeGroup(ageGroup);
	};

	const classes = useStyles();
	return (
		<div className={classes.customExpandPanel}>
			<div
				className={
					classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							onClick={() => handleToggle("EVERYONE")}
							checked={ageGroups.length === 0}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot,
							}}
						/>
					}
					classes={{ label: classes.label }}
					label="Alle"
				/>
			</div>
			<div
				className={
					classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							onClick={() => handleToggle("BABYS")}
							checked={ageGroups.indexOf("BABYS") !== -1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot,
							}}
						/>
					}
					classes={{ label: classes.label }}
					label="Babys"
				/>
			</div>
			<div
				className={
					classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							onClick={() => handleToggle("KINDERGARTEN")}
							checked={ageGroups.indexOf("KINDERGARTEN") !== -1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot,
							}}
						/>
					}
					classes={{
						label: classes.label,
					}}
					label="Kindergarten"
				/>
			</div>
			<div
				className={
					classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							onClick={() => handleToggle("CHILDREN")}
							checked={ageGroups.indexOf("CHILDREN") !== -1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot,
							}}
						/>
					}
					classes={{
						label: classes.label,
					}}
					label="Kinder"
				/>
			</div>
			<div
				className={
					classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							onClick={() => handleToggle("TEENS")}
							checked={ageGroups.indexOf("TEENS") !== -1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot,
							}}
						/>
					}
					classes={{
						label: classes.label,
					}}
					label="Jugendliche"
				/>
			</div>
			<div
				className={
					classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							onClick={() => handleToggle("ADULTS")}
							checked={ageGroups.indexOf("ADULTS") !== -1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot,
							}}
						/>
					}
					classes={{
						label: classes.label,
					}}
					label="Erwachsene"
				/>
			</div>
			<div
				className={
					classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
				}
			>
				<FormControlLabel
					control={
						<Checkbox
							tabIndex={-1}
							onClick={() => handleToggle("ELDERLY")}
							checked={ageGroups.indexOf("ELDERLY") !== -1}
							checkedIcon={<Check className={classes.checkedIcon} />}
							icon={<Check className={classes.uncheckedIcon} />}
							classes={{
								checked: classes.checked,
								root: classes.checkRoot,
							}}
						/>
					}
					classes={{
						label: classes.label,
					}}
					label="Senioren"
				/>
			</div>
		</div>
	);
});

export default AgeCheckBoxes;
