// TODO: consider to set values for the config inside the environment variables
export function awsConfig() {
	switch (process.env.NODE_ENV) {
		case "development":
			return {
				Auth: {
					identityPoolId: "eu-central-1:c5fa34b7-ab20-4bf5-af1a-84157807e02b",
					region: "eu-central-1",
					userPoolId: "eu-central-1_3mcGKKuXj",
					userPoolWebClientId: "4kk2kbhjugk6obgihqq0jqqmua",
				},
				Storage: {
					AWSS3: {
						bucket: "solinger-sportbund-images-bucket-dev",
						region: "eu-central-1",
					},
				},
				Analytics: {
					disabled: false,
					appId: "59a77cb866024a34b3ac68d295192339",
					region: "eu-central-1",
				},
				aws_appsync_graphqlEndpoint:
					"https://rkgz2skolzahhgzd37yirrgvf4.appsync-api.eu-central-1.amazonaws.com/graphql",
				aws_appsync_region: "eu-central-1",
				aws_appsync_authenticationType: "AWS_LAMBDA",
				aws_appsync_authToken: "5_}vKFogcz1XpK.x29a#EFat~}j5j2",
				geo: {
					amazon_location_service: {
						region: "eu-central-1",
						search_indices: {
							items: ["SportappPlaceIndex-dev"],
							default: "SportappPlaceIndex-dev",
						},
						maps: {
							items: {
								"SportappMap-dev": {
									style: "VectorEsriStreets",
								},
							},
							default: "SportappMap-dev",
						},
					},
				},
			};
		case "production":
			return {
				Auth: {
					identityPoolId: "eu-central-1:43666e6e-debe-4538-be15-99ee2cd801ae",
					region: "eu-central-1",
					userPoolId: "eu-central-1_qdED3J68Y",
					userPoolWebClientId: "4n6ud6spnk1eve5et1ck20mk3c",
				},
				Storage: {
					AWSS3: {
						bucket: "solinger-sportbund-images-bucket-prod",
						region: "eu-central-1",
					},
				},
				Analytics: {
					disabled: false,
					appId: "7bd71fe2b6774ce18aa610c46ad0325e",
					region: "eu-central-1",
				},
				aws_appsync_graphqlEndpoint:
					"https://rmpysk2kgbfrzbz2oxzzitwhpe.appsync-api.eu-central-1.amazonaws.com/graphql",
				aws_appsync_region: "eu-central-1",
				aws_appsync_authenticationType: "AWS_LAMBDA",
				aws_appsync_authToken: "kfwmxR5d#noKL_=#rxM9dDte=G=D]9",
				geo: {
					amazon_location_service: {
						region: "eu-central-1",
						search_indices: {
							items: ["SportappPlaceIndex-prod"],
							default: "SportappPlaceIndex-prod",
						},
						maps: {
							items: {
								"SportappMap-prod": {
									style: "VectorEsriStreets",
								},
							},
							default: "SportappMap-prod",
						},
					},
				},
			};
		default:
			return {};
	}
}
