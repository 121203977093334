/*eslint-disable*/ import React, { useContext, useEffect, Suspense} from "react";
import { Helmet } from "react-helmet";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

// core components
import Footer from "components/Footer/Footer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SportappHeader from "customComponents/SportappHeader/Header";
import Parallax from "components/Parallax/Parallax";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

import ClubList from "./ClubList";

import ssbBackground from "customAssets/img/ssb_background.png";
import ssbLogo from "customAssets/img/ssb_logo.png";
import codecentricLogo from "customAssets/img/codecentric_logo.png";
import { RootStoreContext } from "mobX/RootStore";

const styles = () => {
	return {
		...landingPageStyle,
		logoImg: {
			width: "100%",
			height: "auto",
		},
		container: {
			...landingPageStyle.container,
			paddingTop: 10,
		},
		textCenter: {
			textAlign: "center",
		},
	};
};


const useStyles = makeStyles(styles);
export default function ClubsPage() {

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	}, []);

	const store = useContext(RootStoreContext);

	const {
		tenantStore: { data: tenant },
	} = store;
	console.log(process.env.PUBLIC_URL)
	const classes = useStyles();
	return (
		<div>
			<Helmet>
			</Helmet>
			<SportappHeader />
			<Parallax image={ssbBackground} filter="dark">
				<div className={classes.container}>
					<GridContainer justifyContent="center">
						<GridItem xs={10} sm={8} md={6}>
							<img src={require(`../../customAssets/${tenant.key}/img/logo.png`)} className={classes.logoImg} />
						</GridItem>
						<GridItem
							xs={10}
							sm={10}
							md={10}
							className={classNames(classes.textCenter)}
						>
							<br />
							<h3>
								Die <b>Ich trainiere in {tenant.title}</b> App bietet Dir eine Übersicht über alle 
								{` ${tenant.titleGenitive}`} Vereine und deren Aktivitäten.
							</h3>
							<br />
						</GridItem>
						<GridItem
							xs={8}
							sm={4}
							md={4}
							className={classNames(classes.textCenter)}
						>
							<div>
								Mit freundlicher Unterstützung der{" "}
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.codecentric.de"
								>
									<img src={codecentricLogo} className={classes.logoImg} />
								</a>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					<ClubList />
				</div>
			</div>
			<Footer parentClasses={classes}/>
		</div>
	);
}

ClubsPage.propTypes = {
	classes: PropTypes.object,
};
