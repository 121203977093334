import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

const InfiniteSectionScroll = observer(
	({
		data,
		loadNext,
		numOfItemsPerLoad,
		renderSection,
		loadingIndicator,
		sectionKeyExtractor,
	}) => {
		const [page, setPage] = useState(1);
		const [atBottom, setAtBottom] = useState(false);
		const [currentData, setCurrentData] = useState([]);
		const loader = useRef(null);

		useEffect(() => {
			const options = {
				root: null,
				rootMargin: "20px",
				threshold: 1.0,
			};
			const observer = new IntersectionObserver(handleObserver, options);
			if (loader.current) {
				observer.observe(loader.current);
			}
		}, []);

		useEffect(() => {
			loadNext();
			setCurrentData(data.slice(0, page * numOfItemsPerLoad));
			if (currentData.length === data.length) setAtBottom(true);
		}, [page]);

		const handleObserver = (entities) => {
			const target = entities[0];
			if (target.isIntersecting) {
				setPage((page) => page + 1);
			}
		};

		return (
			<div>
				{currentData.map((section) =>
					renderSection(section, sectionKeyExtractor(section)),
				)}
				{!atBottom && <div style={{ paddingTop: 20 }}>{loadingIndicator}</div>}
				<div style={{ height: 20 }} ref={loader} />
			</div>
		);
	},
);

InfiniteSectionScroll.propTypes = {
	data: PropTypes.array,
	loadNext: PropTypes.func,
	renderSection: PropTypes.func,
	loadingIndicator: PropTypes.element,
};

export default InfiniteSectionScroll;
