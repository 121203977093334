import { makeAutoObservable } from "mobx";
import messages from "../utils/messages";

export class ContactStore {
	rootStore;
	transportLayer;
	contacts = [];

	constructor(rootStore, transportLayer) {
		makeAutoObservable(this);

		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	updateContactFromServer(json, club) {
		const contact = this.contacts.find(
			(contactInStore) => contactInStore.id === json.id,
		);
		if (contact) {
			return contact.updateContact(json, club);
		} else {
			const newContact = new Contact(this, json, club);
			this.contacts.push(newContact);
			return newContact;
		}
	}

	removeContactFromStore(id) {
		const index = this.contacts.findIndex(
			(contactInStore) => contactInStore.id === id,
		);
		if (index > -1) this.contacts.splice(index, 1);
	}

	async createContact(contactData, clubID, tenantAdminGroup, clubAdminGroup) {
		try {
			const createContactResult = await this.transportLayer.createContact(
				contactData,
				clubID,
				tenantAdminGroup,
				clubAdminGroup,
			);
			const createdContact = createContactResult.data.createContact;
			if (createdContact) {
				const club = this.rootStore.clubStore.getClubById(clubID);
				this.updateContactFromServer(createdContact, club);
				club.addContact(createdContact);
				this.rootStore.UIStore.setSuccessMessage(
					messages.CREATE_CONTACT_SUCCESS,
				);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_CONTACT_FAILURE);
		}
	}

	async updateContact(contactData) {
		try {
			const updateContactResult =
				await this.transportLayer.updateContact(contactData);
			const updatedContact = updateContactResult.data.updateContact;
			if (updatedContact) {
				this.updateContactFromServer(updatedContact);
				this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	}

	async deleteContact(id) {
		try {
			const deleteContactResult = await this.transportLayer.deleteContact(id);

			if (deleteContactResult.data.deleteContact) {
				const contactInStore = this.getContactById(id);
				contactInStore.removeFromStore();
				this.rootStore.UIStore.setSuccessMessage(messages.DELETE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.DELETE_FAILURE);
		}
	}

	getContactById = (id) => {
		return this.contacts.find((contact) => contact.id === id);
	};
}

class Contact {
	id;
	club;
	clubID = null;
	tenantAdminGroup = null;
	clubAdminGroup = null;
	firstName = null;
	lastName = null;
	email = null;
	phone = null;

	constructor(store, contact, club) {
		makeAutoObservable(this);

		this.store = store;
		this.id = contact.id;
		this.updateContact(contact, club);
	}

	get asJson() {
		return {
			id: this.id,
			clubID: this.clubID,
			tenantAdminGroup: this.tenantAdminGroup,
			clubAdminGroup: this.clubAdminGroup,
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email,
			phone: this.phone,
		};
	}

	updateContact(contact, club) {
		if (club) this.club = club;
		this.clubID = contact.clubID;
		this.tenantAdminGroup = contact.tenantAdminGroup;
		this.clubAdminGroup = contact.clubAdminGroup;
		this.firstName = contact.firstName;
		this.lastName = contact.lastName;
		this.email = contact.email;
		this.phone = contact.phone;

		return this;
	}

	removeFromStore() {
		if (this.club) this.club.removeContactFromClub(this.id);
		this.store.removeContactFromStore(this.id);
	}
}
