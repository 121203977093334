import { makeAutoObservable } from "mobx";
import messages from "../utils/messages";

export class LocationStore {
	rootStore;
	transportLayer;
	locations = [];

	constructor(rootStore, transportLayer) {
		makeAutoObservable(this);

		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	updateLocationFromServer(json, club) {
		const location = this.locations.find(
			(locationInStore) => locationInStore.id === json.id,
		);
		if (location) {
			return location.updateLocation(json, club);
		} else {
			const newLocation = new Location(this, json, club);
			this.locations.push(newLocation);
			return newLocation;
		}
	}

	removeLocationFromStore(id) {
		const index = this.locations.findIndex(
			(locationInStore) => locationInStore.id === id,
		);
		if (index > -1) this.locations.splice(index, 1);
	}

	async createLocation(locationData, clubID, tenantAdminGroup, clubAdminGroup) {
		try {
			const createLocationResult = await this.transportLayer.createLocation(
				locationData,
				clubID,
				tenantAdminGroup,
				clubAdminGroup,
			);
			const createdLocation = createLocationResult.data.createAddress;
			if (createdLocation) {
				const club = this.rootStore.clubStore.getClubById(clubID);
				this.updateLocationFromServer(createdLocation, club);
				club.addLocation(createdLocation);
				this.rootStore.UIStore.setSuccessMessage(
					messages.CREATE_LOCATION_SUCCESS,
				);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_LOCATION_FAILURE);
		}
	}

	async updateLocation(locationData) {
		try {
			const updateLocationResult =
				await this.transportLayer.updateLocation(locationData);
			const updatedLocation = updateLocationResult.data.updateAddress;
			if (updatedLocation) {
				this.updateLocationFromServer(updatedLocation);
				this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	}

	async deleteLocation(id) {
		try {
			const deleteLocationResult = await this.transportLayer.deleteLocation(id);

			if (deleteLocationResult.data.deleteAddress) {
				const locationInStore = this.getLocationById(id);
				locationInStore.removeFromStore();
				this.rootStore.UIStore.setSuccessMessage(messages.DELETE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.DELETE_FAILURE);
		}
	}

	getLocationById = (id) => {
		return this.locations.find((location) => location.id === id);
	};
}

class Location {
	id;
	club;
	clubID = null;
	tenantAdminGroup = null;
	clubAdminGroup = null;
	name = null;
	street = null;
	houseNumber = null;
	zipCode = null;
	city = null;
	geoCoordinates = null;

	constructor(store, location, club) {
		makeAutoObservable(this);

		this.store = store;
		this.id = location.id;
		this.updateLocation(location, club);
	}

	setStreet(street) {
		this.street = street;
	}

	setHouseNumber(houseNumber) {
		this.houseNumber = houseNumber;
	}

	setZipCode(zipCode) {
		this.zipCode = zipCode;
	}

	setCity(city) {
		this.city = city;
	}

	setGeoCoordinates(geoCoordinates) {
		this.geoCoordinates = geoCoordinates;
	}

	get asJson() {
		return {
			id: this.id,
			clubID: this.clubID,
			tenantAdminGroup: this.tenantAdminGroup,
			clubAdminGroup: this.clubAdminGroup,
			name: this.name,
			street: this.street,
			houseNumber: this.houseNumber,
			zipCode: this.zipCode,
			city: this.city,
			geoCoordinates: this.geoCoordinates,
		};
	}

	updateLocation(location, club) {
		if (club) this.club = club;
		this.clubID = location.clubID;
		this.tenantAdminGroup = location.tenantAdminGroup;
		this.clubAdminGroup = location.clubAdminGroup;
		this.name = location.name;
		this.street = location.street;
		this.houseNumber = location.houseNumber;
		this.zipCode = location.zipCode;
		this.city = location.city;
		this.setGeoCoordinates(location.geoCoordinates);

		return this;
	}

	removeFromStore() {
		if (this.club) this.club.removeLocationFromClub(this.id);
		this.store.removeLocationFromStore(this.id);
	}
}
