import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle";

const useStyles = makeStyles(basicsStyle);

const SwitchAdapter = ({ input, label }) => {
	const classes = useStyles();
	return (
		<FormControlLabel
			control={
				<Switch
					name={input.name}
					checked={input.value}
					onChange={input.onChange}
					classes={{
						switchBase: classes.switchBase,
						checked: classes.switchChecked,
						thumb: classes.switchIcon,
						track: classes.switchBar,
					}}
				/>
			}
			label={label}
		/>
	);
};

SwitchAdapter.propTypes = {
	input: PropTypes.object,
	label: PropTypes.string,
};

export default SwitchAdapter;
