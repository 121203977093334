import React, { useEffect, useState } from "react";
import { getLogoURI } from "../../utils/Utils";
import placeholder from "../../customAssets/img/image-placeholder.png";
import PropTypes from "prop-types";

const ClubLogoImg = ({ clubLogo, alt, imgClasses }) => {
	const [clubLogoURI, setClubLogoURI] = useState(null);

	useEffect(() => {
		const getLogo = async () => {
			const uri = await getLogoURI(clubLogo);
			setClubLogoURI(uri.url.href);
		};
		getLogo();
	}, [clubLogo]);

	return (
		<img
			src={clubLogoURI ? clubLogoURI : placeholder}
			alt={alt}
			className={imgClasses}
		/>
	);
};

ClubLogoImg.propTypes = {
	clubLogo: PropTypes.string,
	alt: PropTypes.string,
	imgClasses: PropTypes.string,
};

export default ClubLogoImg;
