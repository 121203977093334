import React from "react";

import { ClubStore } from "./ClubStore";
import { OfferingStore } from "./OfferingStore";
import { CourseStore } from "./CourseStore";
import { LocationStore } from "./LocationStore";
import { ContactStore } from "./ContactStore";
import { UIStore } from "./UIStore";
import { EventStore } from "./EventStore";
import { TenantStore } from "./TenantStore";

export default class RootStore {
	clubStore;
	eventStore;
	offeringStore;
	courseStore;
	locationStore;
	contactStore;
	tenantStore;

	UIStore;
	transportLayer;

	constructor(transportLayer, tenantPath) {
		this.transportLayer = transportLayer;
		this.UIStore = new UIStore();

		this.courseStore = new CourseStore(this, transportLayer);
		this.eventStore = new EventStore(this, transportLayer);
		this.locationStore = new LocationStore(this, transportLayer);
		this.contactStore = new ContactStore(this, transportLayer);
		this.offeringStore = new OfferingStore(
			this,
			transportLayer,
			this.locationStore,
			this.contactStore,
		);
		this.clubStore = new ClubStore(this, transportLayer);
		this.tenantStore = new TenantStore(this, tenantPath, transportLayer);
	}
}

const RootStoreContext = React.createContext({});
export { RootStoreContext };
