import React, { useState } from "react";

import withStyles from "@mui/styles/withStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// custom components
import CustomDropdownAdapter from "customComponents/CustomDropdownAdapter/CustomDropdownAdapter";
import CustomDatetimeAdapter from "../components/CustomDatetimeAdapter";
import CustomInputAdapter from "../components/CustomInputAdapter";
import SwitchAdapter from "../components/SwitchAdapter";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import PropTypes from "prop-types";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import createDecorator from "final-form-calculate";
import FieldAdapter from "../components/FieldAdapter";

import categories from "../../../utils/categories";

import {
	getCategoryImageURI,
	getImageURL,
	getSafeFullName,
	mapAbbrevToDayEnums,
	mapAgeGroupEnums,
	mapAgeGroupToEnums,
	mapBillingIntervalEnums,
	mapBillingIntervalToEnums,
	mapDayEnumsToAbbrev,
} from "../../../utils/Utils";
import LocationModal from "../LocationModal";
import ContactModal from "../ContactModal";
import ImageSelectAdapter from "../components/ImageSelectAdapter";
import EditorAdapter from "../components/EditorAdapter";

const genderDecorator = createDecorator(
	{
		field: "targetGroup.male",
		updates: {
			"targetGroup.female": (male, all) =>
				male ? all.targetGroup.female : true,
		},
	},
	{
		field: "targetGroup.female",
		updates: {
			"targetGroup.male": (female, all) =>
				female ? all.targetGroup.male : true,
		},
	},
);

const style = (theme) => ({
	...modalStyle(theme),
	modalRootExample: {
		"& > div:first-child": {
			display: "none",
		},
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	removeButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
});

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

function mapEnumsToDisplay(offering) {
	const schedules = offering.schedules.map((schedule) => ({
		...schedule,
		day: mapDayEnumsToAbbrev(schedule.day),
	}));
	const costs = offering.costs.map((cost) => ({
		ageGroup: mapAgeGroupEnums(cost.ageGroup),
		cost: cost.cost,
		billingInterval: mapBillingIntervalEnums(cost.billingInterval),
	}));
	return {
		...offering,
		schedules,
		costs,
	};
}

function mapDisplayToEnums(offering) {
	const schedules = offering.schedules.map((schedule) => ({
		...schedule,
		day: mapAbbrevToDayEnums(schedule.day),
	}));
	const costs = offering.costs.map((cost) => ({
		ageGroup: mapAgeGroupToEnums(cost.ageGroup),
		cost: cost.cost,
		billingInterval: mapBillingIntervalToEnums(cost.billingInterval),
	}));
	return {
		...offering,
		schedules,
		costs,
	};
}

const OfferingModal = (props) => {
	const [open, setOpen] = useState(false);
	const [offering, setOffering] = useState();
	const [imageURI, setImageURI] = useState();

	const preProcessOffering = async (offering) => {
		const categoryImageURI = getCategoryImageURI(offering.category);
		setImageURI(offering.image ? offering.image : categoryImageURI);

		return {
			...mapEnumsToDisplay(offering),
			description: offering.descriptionAsHtml,
			imageFile: null,
		};
	};

	const onSubmit = async (values) => {
		const { imageFile, ...offering } = mapDisplayToEnums(values);

		props.onSubmit(offering, imageFile);
		handleClose();
	};

	const handleClickOpen = async () => {
		const preProcessedOffering = await preProcessOffering(props.offering);
		setOffering(preProcessedOffering);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const {
		classes,
		contacts,
		locations,
		createContact,
		createLocation,
		button,
	} = props;

	return (
		<div>
			<div onClick={() => handleClickOpen()}>{button}</div>
			{open && offering && (
				<Dialog
					classes={{
						root: `${classes.modalRoot} ${classes.modalRootExample}`,
						paper: `${classes.modal} ${classes.modalLarge}`,
					}}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => handleClose("scrollingModal")}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<Form
						onSubmit={onSubmit}
						decorators={[genderDecorator]}
						initialValues={offering}
						mutators={{ ...arrayMutators }}
						render={({
							handleSubmit,
							form,
							submitting,
							pristine,
							values,
							form: {
								mutators: { push },
							},
						}) => (
							<form onSubmit={handleSubmit}>
								<FormSpy
									subscription={{ modified: true, values: true }}
									onChange={({ modified, values }) => {
										if (modified.category) {
											const categoryImageURI = getCategoryImageURI(
												values.category,
											);
											setImageURI(
												values.image ? values.image : categoryImageURI,
											);
										}
									}}
								/>

								<DialogTitle
									id="classic-modal-slide-title"
									className={classes.modalHeader}
								>
									<Button
										simple
										className={classes.modalCloseButton}
										key="close"
										aria-label="Close"
										onClick={() => handleClose("scrollingModal")}
									>
										{" "}
										<Close className={classes.modalClose} />
									</Button>
									<h4 className={classes.modalTitle}>
										{values.title ? values.title : "Neues Angebot"}
									</h4>
								</DialogTitle>
								<DialogContent
									id="classic-modal-slide-description"
									className={classes.modalBody}
								>
									<GridContainer>
										<GridItem>
											<FieldAdapter
												name="imageFile"
												component={ImageSelectAdapter}
												type="file"
												id="imageFile"
												imagePath={imageURI}
											/>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="category"
												component={CustomDropdownAdapter}
												type="text"
												placeholder="Sportart"
												label="Sportart"
												dropDownList={categories}
												id="category"
												validate={(value) => !value && "Eingabe erforderlich"}
											/>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="title"
												component={CustomInputAdapter}
												type="text"
												placeholder="Titel"
												label="Titel"
												validate={(value) => !value && "Eingabe erforderlich"}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={12} lg={12}>
											<FieldAdapter
												name="description"
												component={EditorAdapter}
												id="description"
												label="Beschreibung"
											/>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<h4>Trainingszeiten</h4>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<Button
												color="primary"
												simple
												onClick={() => push("schedules", undefined)}
											>
												<i className="fas fa-plus" /> Neue Trainingszeit
											</Button>
										</GridItem>
										<GridItem xs={12}>
											<FieldArray name="schedules">
												{({ fields }) =>
													fields.map((name, index) => (
														<GridContainer key={name}>
															<GridItem xs={3} sm={3} md={3} lg={3}>
																<FieldAdapter
																	name={`${name}.day`}
																	placeholder="Tag"
																	component={CustomDropdownAdapter}
																	type="text"
																	label="Tag"
																	dropDownList={[
																		"Mo",
																		"Di",
																		"Mi",
																		"Do",
																		"Fr",
																		"Sa",
																		"So",
																		"Jeden Tag",
																	]}
																	id={`${name}.day`}
																	validate={(value) =>
																		!value && "Eingabe erforderlich"
																	}
																/>
															</GridItem>
															<GridItem xs={3} sm={3} md={3} lg={3}>
																<FieldAdapter
																	name={`${name}.from`}
																	component={CustomDatetimeAdapter}
																	label="Von"
																	dateFormat={false}
																	timeFormat={"HH:mm"}
																	validate={(value) => {
																		const match =
																			/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
																		const matchesPattern = match.test(value);
																		if (!value) {
																			return "Eingabe erforderlich";
																		} else if (!matchesPattern) {
																			return "Zeit muss von Format HH:MM sein";
																		}
																	}}
																/>
															</GridItem>
															<GridItem xs={3} sm={3} md={3} lg={3}>
																<FieldAdapter
																	name={`${name}.to`}
																	component={CustomDatetimeAdapter}
																	label="Bis"
																	dateFormat={false}
																	timeFormat={"HH:mm"}
																	validate={(value) => {
																		const match =
																			/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
																		const matchesPattern = match.test(value);
																		if (!value) {
																			return "Eingabe erforderlich";
																		} else if (!matchesPattern) {
																			return "Zeit muss von Format HH:MM sein";
																		}
																	}}
																/>
															</GridItem>
															<GridItem
																xs={3}
																sm={3}
																md={3}
																lg={3}
																className={classes.removeButton}
															>
																{fields.length !== 1 && (
																	<Button
																		color="danger"
																		simple
																		onClick={() => fields.remove(index)}
																	>
																		<i className="fas fa-minus-circle" />
																	</Button>
																)}
															</GridItem>
														</GridContainer>
													))
												}
											</FieldArray>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<h4>Zielgruppe</h4>
										</GridItem>
										<GridItem xs={12}>
											<GridContainer key="targetGroup">
												<GridItem xs={6} sm={6} md={6} lg={6}>
													<FieldAdapter
														name="targetGroup.from"
														component={CustomInputAdapter}
														inputProps={{
															type: "number",
														}}
														placeholder="Von"
														label="Mindestalter"
														validate={(value) => {
															if (value === null || value < 0)
																return "Ungültiges Alter";
														}}
													/>
												</GridItem>

												<GridItem xs={6} sm={6} md={6} lg={6}>
													<FieldAdapter
														name="targetGroup.to"
														component={CustomInputAdapter}
														inputProps={{
															type: "number",
														}}
														placeholder="Bis"
														label="Höchstalter"
														validate={(value) => {
															if (value === null || value < 0)
																return "Ungültiges Alter";
														}}
													/>
												</GridItem>

												<GridItem xs={6} sm={3} md={3} lg={3}>
													<FieldAdapter
														name="targetGroup.female"
														component={SwitchAdapter}
														type="text"
														label="Weiblich"
													/>
												</GridItem>
												<GridItem xs={6} sm={3} md={3} lg={3}>
													<FieldAdapter
														name="targetGroup.male"
														component={SwitchAdapter}
														type="text"
														label="Männlich"
													/>
												</GridItem>
											</GridContainer>
										</GridItem>

										<GridItem xs={12} sm={6} md={6} lg={6}>
											<h4>Gebühren</h4>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<Button
												color="primary"
												simple
												onClick={() => push("costs", undefined)}
											>
												<i className="fas fa-plus" /> Gebühren hinzufügen
											</Button>
										</GridItem>
										<GridItem xs={12}>
											<FieldArray name="costs">
												{({ fields }) =>
													fields.map((name, index) => (
														<GridContainer key={name}>
															<GridItem xs={3} sm={3} md={3} lg={3}>
																<FieldAdapter
																	name={`${name}.billingInterval`}
																	placeholder="Zeitraum"
																	component={CustomDropdownAdapter}
																	type="text"
																	label="Zeitraum"
																	dropDownList={["Monat", "Halbjahr", "Jahr"]}
																	id={`${name}.billingInterval`}
																	validate={(value) =>
																		!value && "Eingabe erforderlich"
																	}
																/>
															</GridItem>
															<GridItem xs={3} sm={3} md={3} lg={3}>
																<FieldAdapter
																	name={`${name}.cost`}
																	component={CustomInputAdapter}
																	type="text"
																	placeholder="Gebühren"
																	label="Gebühren"
																/>
															</GridItem>
															<GridItem xs={3} sm={3} md={3} lg={3}>
																<FieldAdapter
																	name={`${name}.ageGroup`}
																	placeholder="Altersklasse"
																	component={CustomDropdownAdapter}
																	type="text"
																	label="Altersklasse"
																	dropDownList={[
																		"Erwachsene",
																		"Kinder",
																		"Senioren",
																		"Studenten",
																		"Jeden",
																	]}
																	id={`${name}.ageGroup`}
																	validate={(value) =>
																		!value && "Eingabe erforderlich"
																	}
																/>
															</GridItem>
															<GridItem
																xs={3}
																sm={3}
																md={3}
																lg={3}
																className={classes.removeButton}
															>
																{fields.length !== 1 && (
																	<Button
																		color="danger"
																		simple
																		onClick={() => fields.remove(index)}
																	>
																		<i className="fas fa-minus-circle" />
																	</Button>
																)}
															</GridItem>
														</GridContainer>
													))
												}
											</FieldArray>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="hints"
												component={CustomInputAdapter}
												type="text"
												placeholder="Zusatzhinweise"
												label="Zusatzhinweise"
												inputProps={{ multiline: true, maxRows: 10 }}
											/>
										</GridItem>

										<GridItem xs={12} sm={6} md={6} lg={6}>
											<h4>Kontakt</h4>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<ContactModal
												newContact
												createContact={createContact}
												button={(onClick) => {
													return (
														<Button
															color="primary"
															simple
															onClick={() => onClick()}
														>
															<i className="fas fa-plus" /> Neuer Kontakt
														</Button>
													);
												}}
											/>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="contact"
												component={CustomDropdownAdapter}
												type="text"
												dropDownList={contacts
													.filter((item) => getSafeFullName(item))
													.sort((a, b) =>
														getSafeFullName(a).localeCompare(
															getSafeFullName(b),
														),
													)}
												id="contact"
												getKey={(item) => item.id}
												getValue={(item) => item}
												getName={(item) => `${item.firstName} ${item.lastName}`}
											/>
										</GridItem>

										<GridItem xs={12} sm={6} md={6} lg={6}>
											<h4>Sportstätte</h4>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<LocationModal
												onSubmit={createLocation}
												button={(onClick) => {
													return (
														<Button
															color="primary"
															simple
															onClick={() => onClick()}
														>
															<i className="fas fa-plus" /> Neue Sportstätte
														</Button>
													);
												}}
											/>
										</GridItem>
										<GridItem>
											<FieldAdapter
												name="location"
												component={CustomDropdownAdapter}
												type="text"
												dropDownList={locations
													.filter((item) => item.name)
													.sort((a, b) => a.name.localeCompare(b.name))}
												id="location"
												getKey={(item) => item.id}
												getValue={(item) => item}
												getName={(item) => item.name}
											/>
										</GridItem>
									</GridContainer>
								</DialogContent>
								<DialogActions className={classes.modalFooter}>
									<Button
										onClick={form.reset}
										disabled={submitting || pristine}
										color="secondary"
									>
										Zurücksetzen
									</Button>
									<Button color="primary" type="submit" disabled={submitting}>
										Speichern
									</Button>
								</DialogActions>
							</form>
						)}
					/>
				</Dialog>
			)}
		</div>
	);
};

OfferingModal.defaultProps = {
	offering: {
		schedules: [{}],
		costs: [{}],
		title: null,
		category: null,
		district: null,
		targetGroup: {
			male: true,
			female: true,
			from: 0,
			to: 99,
		},
	},
};

OfferingModal.propTypes = {
	classes: PropTypes.object,
	offering: PropTypes.object,
	onSubmit: PropTypes.func,
	createContact: PropTypes.func,
	createLocation: PropTypes.func,
	contacts: PropTypes.array,
	locations: PropTypes.array,
	button: PropTypes.node,
};

const districts = [
	"Höhscheid",
	"Mitte",
	"Ohligs",
	"Merscheid",
	"Wald",
	"Burg",
	"Gräfrath",
	"Aufderhöhe",
	"Außerhalb von SG",
];

export default withStyles(style)(OfferingModal);
