/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom";
import { configureAutoTrack } from "aws-amplify/analytics";

import "@aws-amplify/ui-react/styles.css";

// Import root app
import App from "containers/App";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";

//import {amplifyconfig} from './amplifyconfiguration.json';

import "assets/scss/material-kit-pro-react.scss?v=1.3.0";
import RootStore from "./mobX/RootStore";
import { GraphqlApi } from "./graphql/api";
import { RootStoreContext } from "./mobX/RootStore";
import { BrowserRouter } from "react-router-dom";
import { amplifyconfiguration } from "amplifyconfiguration";

const MOUNT_NODE = document.getElementById("app");

Amplify.configure(amplifyconfiguration(), {
	API: {
		GraphQL: {
			headers: async () => ({
				Authorization: (await fetchAuthSession()).tokens?.idToken?.toString(),
			}),
		},
	},
});

configureAutoTrack({
	enable: true,
	type: "pageView",
	eventName: "pageView",
	urlProvider: () => window.location.origin + window.location.pathname,
	options: {
		attributes: {
			customizableField: "attr",
		},
	},
});

const tenantPath = new URL(location.href).pathname.split("/")[1];
const transportLayer = new GraphqlApi();
const rootStore = new RootStore(transportLayer, tenantPath);

ReactDOM.render(
	<BrowserRouter>
		<RootStoreContext.Provider value={rootStore}>
			<App />
		</RootStoreContext.Provider>
	</BrowserRouter>,
	MOUNT_NODE,
);
