import { makeAutoObservable, runInAction } from "mobx";
import jsonData from "../tenants.json";

export class TenantStore {
	data = {};
	support = {};
	tier = "";
	limits = {};
	email;
	isLoading = false;
	transportLayer;
	rootStore;

	constructor(rootStore, tenantPath, transportLayer) {
		makeAutoObservable(this);

		if (tenantPath && jsonData.tenants[tenantPath]) {
			this.data = jsonData.tenants[tenantPath];
		} else {
			this.data = jsonData.tenants[jsonData.default];
		}

		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	async fetchTenantSupport() {
		try {
			this.isLoading = true;
			this.transportLayer.fetchTenantSupport(this.data.key).then((fetched) => {
				runInAction(() => {
					this.support = fetched?.support;
					this.tier = fetched?.license.tier;
					try {
						this.limits = JSON.parse(fetched?.license.licenseLimits);
					} catch (error) {
						console.error(error);
					}
					this.isLoading = false;
				});
			});
		} catch (error) {
			console.error(error);
			this.isLoading = false;
		}
	}

	get supportEmail() {
		return this.support?.email;
	}

	get tenantKey() {
		return this.data.key;
	}

	get licenseTier() {
		return this.tier;
	}

	get clubLimit() {
		return this.limits?.clubLimit;
	}

	get primary1Color() {
		return this.data.primary1Color;
	}

	get primary2Color() {
		return this.data.primary2Color;
	}
}
