import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
// core components
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import Schedule from "@mui/icons-material/Schedule";
import People from "@mui/icons-material/People";
import Launch from "@mui/icons-material/Launch";

import {
	getCategoryImageURI,
	getLinkToDetailsForOffering,
	mapDayEnumsToAbbrev,
} from "../../utils/Utils";
import { CardContent } from "@mui/material";
import { RootStoreContext } from "mobX/RootStore";

const useStyles = makeStyles({
	root: {
		height: 340,
		borderRadius: "6px",
		overflow: "hidden",
		flex: "0 0 33.333333%",
	},
	content: {
		height: "100%",
		width: "100%",
		minHeight: "inherit",
	},
	title: {
		color: "rgba(255, 255, 255, 0.9)",
		textShadow: "2px 2px 2px rgba(0, 0, 0, 0.7)",
		fontWeight: "bold",
		textAlign: "center",
		fontSize: "1.3rem",
		minHeight: "3.0rem",
		maxHeight: "3.0rem",
		overflow: "hidden",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
	},
	descriptionHeader: {
		paddingBottom: "0.5rem",
		textAlign: "center",
		fontWeight: "bold",
		display: "flex",
		flexWrap: "wrap",
		columnGap: "0.6rem",
		justifyContent: "center",
	},
	description: {
		display: "flex",
		flexDirection: "column",
		color: "black",
		maxHeight: 180,
		marginTop: "1em",
		background: "rgba(255, 255, 255, 0.5)",
		padding: "0.5em",
		borderRadius: "3px",
		fontSize: "1rem",
		fontWeight: 400,
		backdropFilter: "blur(10px)",
	},
	descriptionHeaderContent: {
		whiteSpace: "nowrap",
		fontSize: "0.8rem",
	},
	descriptionHeaderContentIcon: {
		verticalAlign: "sub",
		marginRight: "0.1rem",
		position: "relative",
		top: "0.05rem",
	},
	footer: {
		display: "flex",
		justifyContent: "center",
		marginTop: "auto",
	},
	descriptionTextContainer: {
		flex: 2,
		minHeight: 0,
	},
	descriptionText: {
		overflow: "hidden",
		fontSize: "0.8rem",
		lineHeight: "1.2rem",
		height: "100%",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 7,
		textOverflow: "ellipsis",
	},
});

const OfferingCard = ({ offering, target }) => {
	const classes = useStyles();
	const { targetGroup } = offering;

	const image = offering.image
		? offering.image
		: getCategoryImageURI(offering.category);

	const store = useContext(RootStoreContext);
	const {
		tenantStore: { data: tenant },
	} = store;

	return (
		<Card className={classes.root}>
			<CardContent
				className={classes.content}
				style={{
					background: `linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(${image})`,
					backgroundPosition: "center",
					backgroundSize: "cover",
					display: "flex",
					flexDirection: "column",
					paddingBottom: "0.7rem",
				}}
			>
				<div className={classes.title}>{offering.title}</div>
				<div className={classes.description}>
					{offering.schedules && (
						<div className={classes.descriptionHeader}>
							<div className={classes.descriptionHeaderContent}>
								<Schedule
									fontSize="small"
									className={classes.descriptionHeaderContentIcon}
								/>
								{offering.schedules
									.map(
										(schedule) =>
											`${mapDayEnumsToAbbrev(schedule.day)}: ${schedule.from}`,
									)
									.join(", ")}
							</div>
							<div className={classes.descriptionHeaderContent}>
								<People
									fontSize="small"
									className={classes.descriptionHeaderContentIcon}
								/>{" "}
								Alter: {targetGroup.from}-{targetGroup.to}
							</div>
						</div>
					)}
					<div className={classes.descriptionTextContainer}>
						<div className={classes.descriptionText}>
							{offering.description}
						</div>
					</div>
				</div>
				<div className={classes.footer}>
					<Link
						to={getLinkToDetailsForOffering(offering, tenant.path)}
						target={target || "_self"}
					>
						<Button color="primary" endIcon={<Launch />} variant="contained">
							DETAILS
						</Button>
					</Link>
				</div>
			</CardContent>
		</Card>
	);
};

OfferingCard.propTypes = {
	offering: PropTypes.object,
};

export default OfferingCard;
