import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import makeStyles from "@mui/styles/makeStyles";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import FormControl from "@mui/material/FormControl";
import Datetime from "react-datetime";
import moment from "moment";

import switchStyles from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle";
import { mapDayEnumsToFullName } from "../../../utils/Utils";

const style = () => ({
	...switchStyles,
	scheduleDetails: {
		color: "black",
	},
	closed: {
		height: 0,
		overflow: "hidden",
		transition: "height 0.2s",
	},
	opened: {
		height: 80,
		transition: "height 0.2s",
	},
});

const useStyles = makeStyles(style);

const ScheduleListItem = observer(
	({ schedule, onSetSchedule, onRemoveSchedule, day }) => {
		const [checked, setChecked] = useState(false);

		const [from, setFrom] = useState("08:00");
		const [to, setTo] = useState("20:00");

		const classes = useStyles();

		const onChangeSchedule = () => {
			onSetSchedule({
				day,
				from: from,
				to: to,
			});
		};

		const toggleSwitch = () => {
			if (!checked) {
				onSetSchedule({
					day,
					from: from,
					to: to,
				});
			} else {
				onRemoveSchedule({
					day,
					from: from,
					to: to,
				});
			}
			setChecked(!checked);
		};

		useEffect(() => {
			if (checked) onChangeSchedule();
		}, [from, to]);

		useEffect(() => {
			if (schedule) {
				if (from !== schedule.from || to !== schedule.to) {
					setFrom(schedule.from);
					setTo(schedule.to);
				}
				setChecked(true);
			}
		}, [schedule]);

		return (
			<div style={{ marginLeft: 5 }}>
				<FormControlLabel
					control={
						<Switch
							checked={checked}
							onChange={toggleSwitch}
							value="checked"
							classes={{
								switchBase: classes.switchBase,
								checked: classes.switchChecked,
								thumb: classes.switchIcon,
								track: classes.switchBar,
							}}
						/>
					}
					classes={{
						label: classes.label,
					}}
					label={mapDayEnumsToFullName(day)}
				/>
				<div className={checked ? classes.opened : classes.closed}>
					<GridContainer className={classes.scheduleDetails}>
						<GridItem xs={6}>
							<InputLabel className={classes.label}>Von</InputLabel>
							<FormControl variant="standard" fullWidth>
								<Datetime
									dateFormat={false}
									timeFormat="HH:mm"
									value={from}
									onChange={(data) => {
										if (moment.isMoment(data)) {
											setFrom(data.format("HH:mm"));
										} else {
											setFrom(data);
										}
									}}
									timeConstraints={{ minutes: { step: 5 } }}
								/>
							</FormControl>
						</GridItem>
						<GridItem xs={6}>
							<InputLabel className={classes.label}>Bis</InputLabel>
							<FormControl variant="standard" fullWidth>
								<Datetime
									dateFormat={false}
									timeFormat="HH:mm"
									value={to}
									onChange={(data) => {
										if (moment.isMoment(data)) {
											setTo(data.format("HH:mm"));
										} else {
											setTo(data);
										}
									}}
									timeConstraints={{ minutes: { step: 5 } }}
								/>
							</FormControl>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		);
	},
);

export default ScheduleListItem;
