import React from "react";

import withStyles from "@mui/styles/withStyles";
import AddIcon from "@mui/icons-material/Add";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// custom components
import CustomDropdownAdapter from "customComponents/CustomDropdownAdapter/CustomDropdownAdapter";
import CustomInputAdapter from "../components/CustomInputAdapter";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Field } from "react-final-form-html5-validation";
import arrayMutators from "final-form-arrays";
import { validateEmail } from "../../../utils/Utils";

const style = (theme) => ({
	...modalStyle(theme),
	modalRootExample: {
		"& > div:first-child": {
			display: "none",
		},
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	removeButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
});

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

class AdminModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scrollingModal: false,
		};
	}

	onSubmit = async (values) => {
		const { onSubmit, initialValues } = this.props;
		const prevAdminData = initialValues
			? {
					username: initialValues.username,
					email: initialValues.email,
				}
			: null;
		const prevClubAdminGroup = initialValues
			? initialValues.club.clubAdminGroup
			: null;
		const newAdminData = { username: values.username, email: values.email };
		const newClubAdminGroup = values.club.clubAdminGroup;
		onSubmit(
			newAdminData,
			newClubAdminGroup,
			prevAdminData,
			prevClubAdminGroup,
		);
		this.handleClose("scrollingModal");
	};

	handleClickOpen(modal) {
		const x = [];
		x[modal] = true;
		this.setState(x);
	}

	handleClose(modal) {
		const x = [];
		x[modal] = false;
		this.setState(x);
	}

	render() {
		const { classes, initialValues, clubs } = this.props;

		return (
			<div>
				{initialValues ? (
					<Button
						simple
						color="primary"
						onClick={() => this.handleClickOpen("scrollingModal")}
					>
						<i className="fas fa-edit" />
					</Button>
				) : (
					<Button
						color="primary"
						fullWidth
						onClick={() => this.handleClickOpen("scrollingModal")}
					>
						<AddIcon /> Neuen Admin erstellen
					</Button>
				)}
				{this.state.scrollingModal && (
					<Dialog
						classes={{
							root: `${classes.modalRoot} ${classes.modalRootExample}`,
							paper: `${classes.modal} ${classes.modalLarge}`,
						}}
						open={this.state.scrollingModal}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleClose("scrollingModal")}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<Form
							onSubmit={this.onSubmit}
							initialValues={initialValues}
							mutators={{ ...arrayMutators }}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								values,
							}) => (
								<form onSubmit={handleSubmit}>
									<DialogTitle
										id="classic-modal-slide-title"
										className={classes.modalHeader}
									>
										<Button
											simple
											className={classes.modalCloseButton}
											key="close"
											aria-label="Close"
											onClick={() => this.handleClose("scrollingModal")}
										>
											{" "}
											<Close className={classes.modalClose} />
										</Button>
										<h4 className={classes.modalTitle}>
											{values.username ? values.username : "Neuer Admin"}
										</h4>
									</DialogTitle>
									<DialogContent
										id="classic-modal-slide-description"
										className={classes.modalBody}
									>
										<GridContainer>
											<GridItem xs={12} sm={12} md={12} lg={12}>
												<h4>Admin</h4>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<Field
													name="username"
													component={CustomInputAdapter}
													type="text"
													placeholder="Benutzername"
													label="Benutzername"
													validate={(value) => !value && "Eingabe erforderlich"}
													inputProps={initialValues ? { readOnly: true } : {}}
												/>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<Field
													name="email"
													component={CustomInputAdapter}
													type="text"
													placeholder="E-Mail"
													label="E-Mail"
													validate={(value) => {
														if (!value) {
															return "Eingabe erforderlich";
														} else if (!validateEmail(value)) {
															return "Ungültige E-Mail";
														}
													}}
													inputProps={initialValues ? { readOnly: true } : {}}
												/>
											</GridItem>
											<GridItem>
												<Field
													name="club"
													component={CustomDropdownAdapter}
													type="text"
													placeholder="Club"
													label="Club"
													dropDownList={clubs}
													id="club"
													getKey={(item) => item.id}
													getValue={(item) => item}
													getName={(item) => item.name}
												/>
											</GridItem>
										</GridContainer>
									</DialogContent>
									<DialogActions className={classes.modalFooter}>
										<Button
											onClick={form.reset}
											disabled={submitting || pristine}
											color="secondary"
										>
											Zurücksetzen
										</Button>
										<Button color="primary" type="submit" disabled={submitting}>
											Speichern
										</Button>
									</DialogActions>
								</form>
							)}
						/>
					</Dialog>
				)}
			</div>
		);
	}
}

AdminModal.propTypes = {
	classes: PropTypes.object,
	onSubmit: PropTypes.func,
	initialValues: PropTypes.object,
	clubs: PropTypes.array,
};

export default withStyles(style)(AdminModal);
