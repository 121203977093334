import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { observer } from "mobx-react";
import { RootStoreContext } from "../../mobX/RootStore";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/icons-material
import LocationOn from "@mui/icons-material/LocationOn";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import TodayIcon from "@mui/icons-material/Today";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import FlagIcon from "@mui/icons-material/Flag";
// core components
import Footer from "components/Footer/Footer";
import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
// custom components
import SportappHeader from "customComponents/SportappHeader/Header";
import ClubLogoImg from "customComponents/ClubLogoImg/ClubLogoImg";
import GroupsIcon from "@mui/icons-material/Groups";

import backgroundImage from "customAssets/img/ssb_background.png";
import style from "./styles";
import {
	displayTargetGroup,
	getCategoryImageURI,
	getQueryParam,
	getSafeFullName,
	mapAgeGroupEnums,
	mapBillingIntervalEnums,
	mapDayEnumsToFullName,
} from "../../utils/Utils";
import Button from "../../components/CustomButtons/Button";
import { IconButton, Tooltip } from "@mui/material";
import MapComponent from "customComponents/Map/Map";
//import MapComponent from "customComponents/Map/Map";

// eslint-disable-next-line react/prop-types
const MailTo = ({ email, subject, body, children }) => {
	return (
		<a
			href={`mailto:${email}?subject=${
				encodeURIComponent(subject) || ""
			}&body=${encodeURIComponent(body) || ""}`}
		>
			{children}
		</a>
	);
};

// eslint-disable-next-line react/prop-types
const PhoneCall = ({ number, children }) => {
	return <a href={`tel:${number}`}>{children}</a>;
};

// eslint-disable-next-line react/prop-types
const ActionChip = ({ children }) => {
	return (
		<Button
			round
			style={{
				backgroundColor: "#EEEEEE",
				paddingTop: 5,
				paddingLeft: 9,
				paddingBottom: 5,
				paddingRight: 14,
				margin: 4,
			}}
		>
			{children}
		</Button>
	);
};

const OfferingDetails = observer(() => {
	const store = useContext(RootStoreContext);
	const offeringStore = store.offeringStore;

	const {
		tenantStore: { data: tenant, supportEmail },
	} = store;

	// Get the value of the 'id' query parameter
	const offeringId = getQueryParam("id");

	const [offering, setOffering] = useState();
	const [backgroundImageURI, setBackgroundImageURI] = useState(backgroundImage);
	const [lng, setLng] = useState()
	const [lat, setLat] = useState()

	useEffect(() => {
		offeringStore.fetchOffering(offeringId);
	}, []);

	useEffect( ( )=> {
		setLng(offering?.location?.geoCoordinates?.longitude)
		setLat(offering?.location?.geoCoordinates?.latitude)
	}, [offering])

	useEffect(() => {
		const init = async () => {
			if (!offeringStore.isLoading) {
				const offering = offeringStore.getOfferingById(offeringId);
				setOffering(offering);
				if (offering.image) {
					setBackgroundImageURI(offering.image);
				} else {
					const categoryImageURI = getCategoryImageURI(offering.category);
					setBackgroundImageURI(categoryImageURI);
				}
			}
		};
		init();
	}, [offeringStore.isLoading]);

	const classes = style();
	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgFluid,
	);
	const [hover, setHover] = useState(false);
	const sendMailToAdmin = () => {
		const body =
			`Kommentar: Beschreibung der fehlerhaften Angaben… (bitte ergänzen!)\n\n\n\n\n` +
			`Verein: ${offering.clubName}\n` +
			`Kategorie: ${offering.category}\n` +
			`Angebot: ${offering.title}\n` +
			`Link: ${window.location.href}\n`;
		window.location.href = `
			mailto:${encodeURIComponent(supportEmail)}
			?subject=${encodeURIComponent(
				"Fehlerhafte Angaben im Angebot: " + offering.title,
			)}
			&body=${encodeURIComponent(body)}
		`;
	};

	return (
		<div>
			{offering?.title && offering?.clubName && (
				<Helmet>
					<title>
						{`${offering.title} - ${offering.clubName} - Ich trainiere in ${tenant.title}`}
					</title>
				</Helmet>
			)}
			<SportappHeader />
			<Parallax
				image={backgroundImageURI}
				filter="dark"
				className={classes.parallax}
			/>
			{offering && (
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={12}>
								<div className={classes.profile}>
									<div className={classes.imgWrapper}>
										<ClubLogoImg
											clubLogo={offering.clubLogo}
											alt={offering.title}
											imgClasses={imageClasses}
										/>
									</div>
									<div className={classes.name}>
										<h3
											className={classes.title}
											style={{ marginBottom: 0, textAlign: "initial" }}
										>
											{offering.title}
										</h3>
										<h5 style={{ textAlign: "initial", marginTop: 0 }}>
											{offering.clubName}
										</h5>
									</div>
									<div
										dangerouslySetInnerHTML={{
											__html: offering.descriptionAsHtml,
										}}
										style={{ textAlign: "initial", marginTop: 30 }}
									/>
									<p
										className={classes.description}
										style={{ textAlign: "initial", marginTop: 30 }}
									>
										{offering.targetGroup ? (
											<>
												<GroupsIcon
													style={{
														verticalAlign: "middle",
														position: "relative",
														bottom: 2,
														marginRight: 5,
													}}
												/>
												{`${displayTargetGroup(offering.targetGroup)}`}
											</>
										) : (
											""
										)}
									</p>
									<div>
										<button
											onClick={() => sendMailToAdmin()}
											onMouseEnter={() => setHover(true)}
											onMouseLeave={() => setHover(false)}
											style={{
												position: "fixed",
												right: "30px",
												button: "90px",
												zIndex: "1000",
												backgroundColor: "transparent",
												border: "none",
												cursor: "pointer",
											}}
										>
											<Tooltip title="Melden">
												<IconButton size="large">
													<FlagIcon
														style={{ color: hover ? "#f44336" : "#3C4858" }}
													/>
												</IconButton>
											</Tooltip>
										</button>
									</div>

									<GridContainer>
										{offering.schedules && (
											<GridItem sm={12} md={6}>
												<GridContainer>
													<GridItem sm={1} md={1} />
													<GridItem sm={11} md={11}>
														<GridContainer style={{ textAlign: "left" }}>
															<GridItem xs={1}>
																<TodayIcon className={classes.subHeaderIcon} />
															</GridItem>
															<GridItem
																xs={10}
																className={classes.subSectionContent}
															>
																<h4
																	className={classNames(
																		classes.left,
																		classes.title,
																	)}
																>
																	Termine
																</h4>
																{offering.schedules.map((schedule, index) => {
																	return (
																		<div
																			key={index}
																			className={classes.listItem}
																		>
																			<p className={classes.listItemLeft}>
																				{mapDayEnumsToFullName(schedule.day)}:
																			</p>
																			<p>
																				{schedule.from} - {schedule.to}
																			</p>
																		</div>
																	);
																})}
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</GridItem>
										)}
										{offering.costs && (
											<GridItem sm={12} md={6}>
												<GridContainer>
													<GridItem sm={1} md={1} />
													<GridItem sm={11} md={11}>
														<GridContainer style={{ textAlign: "left" }}>
															<GridItem xs={1}>
																<EuroSymbolIcon
																	className={classes.subHeaderIcon}
																/>
															</GridItem>
															<GridItem
																xs={10}
																className={classes.subSectionContent}
															>
																<h4
																	className={classNames(
																		classes.left,
																		classes.title,
																	)}
																>
																	Kosten
																</h4>
																{offering.costs.map((singleCost, index) => {
																	if (
																		!("ageGroup" in singleCost) &&
																		!("cost" in singleCost) &&
																		!("billingInterval" in singleCost)
																	)
																		return null;
																	const { ageGroup, cost, billingInterval } =
																		singleCost;
																	const costWithoutEuro = cost?.replace(
																		"€",
																		"",
																	);
																	return (
																		<div
																			key={index}
																			className={classes.listItem}
																		>
																			{ageGroup && (
																				<p className={classes.listItemLeft}>
																					{mapAgeGroupEnums(ageGroup)}:
																				</p>
																			)}
																			{costWithoutEuro?.match(/^[., 0-9]+$/) ? (
																				costWithoutEuro !== "0" ? (
																					<p>
																						{cost} € im{" "}
																						{mapBillingIntervalEnums(
																							billingInterval,
																						)}
																					</p>
																				) : (
																					<p>kostenlos</p>
																				)
																			) : (
																				<p>{cost}</p>
																			)}
																		</div>
																	);
																})}
																<p>{offering.hints}</p>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</GridItem>
										)}
										{offering.contact && (
											<GridItem sm={12} md={6}>
												<GridContainer>
													<GridItem sm={1} md={1} />
													<GridItem sm={11} md={11}>
														<GridContainer style={{ textAlign: "left" }}>
															<GridItem xs={1}>
																<MailOutlineIcon
																	className={classes.subHeaderIcon}
																/>
															</GridItem>
															<GridItem
																xs={10}
																className={classes.subSectionContent}
															>
																<h4
																	className={classNames(
																		classes.left,
																		classes.title,
																	)}
																>
																	{getSafeFullName(offering.contact)}
																</h4>
																{offering.contact.email &&
																	offering.contact.email !== "" && (
																		<p>E-Mail: {offering.contact.email}</p>
																	)}
																{offering.contact.phone &&
																	offering.contact.phone !== "" && (
																		<p>Telefon: {offering.contact.phone}</p>
																	)}
																<div className={classes.actionChipsWrapper}>
																	<MailTo
																		email={offering.contact.email}
																		subject={offering.title}
																		body={""}
																	>
																		<ActionChip>
																			<div
																				className={classes.actionChipContent}
																			>
																				<MailOutlineIcon />
																				<p>Anschreiben</p>
																			</div>
																		</ActionChip>
																	</MailTo>
																	{offering.contact.phone &&
																		offering.contact.phone !== "" && (
																			<PhoneCall
																				number={offering.contact.phone}
																			>
																				<ActionChip>
																					<div
																						className={
																							classes.actionChipContent
																						}
																					>
																						<PhoneIcon />
																						<p>Anrufen</p>
																					</div>
																				</ActionChip>
																			</PhoneCall>
																		)}
																</div>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</GridItem>
										)}

										{((lng && lat) || (offering.location &&
											offering.location.street &&
											offering.location.houseNumber &&
											offering.location.zipCode &&
											offering.location.city)) && (
												<GridItem sm={12} md={6}>
													<GridContainer>
														<GridItem sm={1} md={1} />
														<GridItem sm={11} md={11}>
															<GridContainer style={{ textAlign: "left" }}>
																<GridItem xs={1}>
																	<LocationOn
																		className={classes.subHeaderIcon}
																	/>
																</GridItem>
																<GridItem
																	xs={10}
																	className={classes.subSectionContent}
																>
																	<h4
																		className={classNames(
																			classes.left,
																			classes.title,
																		)}
																	>
																		Adresse: {offering.location.name}
																	</h4>
																	<p>
																		{offering.location.street}{" "}
																		{offering.location.houseNumber}
																	</p>
																	<p>
																		{offering.location.zipCode}{" "}
																		{offering.location.city}
																	</p>
																	{lng && lat && (
																		<p>
																			<b>
																				<span>Karte</span>
																			</b>

																			<MapComponent
																				lng={lng}
																				lat={lat}
																				setLng={setLng}
																				setLat={setLat}
																				fixedMarker={true}
																			/>
																		</p>
																	)}
																</GridItem>
															</GridContainer>
														</GridItem>
													</GridContainer>
												</GridItem>
											)}
									</GridContainer>
								</div>
							</GridItem>
						</GridContainer>
						<div style={{ height: 20 }} />
					</div>
				</div>
			)}
			<Footer parentClasses={classes} />
		</div>
	);
});

export default OfferingDetails;
