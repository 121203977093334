import React, { useContext } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import { RootStoreContext } from "mobX/RootStore";

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

const ClubIncompleteDialog = ({ classes, open, onClose }) => {
	//const [open, setOpen] = useState(false);

	const handleClose = () => {
		onClose();
	};

	//const { classes } = props;

	const store = useContext(RootStoreContext);

	const {
		tenantStore: { data: tenant },
	} = store;

	return (
		<div>
			{open && (
				<Dialog
					classes={{
						root: classes.modalRoot,
						paper: classes.modal,
					}}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => handleClose()}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle
						id="classic-modal-slide-title"
						className={classes.modalHeader}
					>
						<Button
							simple
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							onClick={() => handleClose()}
						>
							{" "}
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>Verein nicht vollständig</h4>
					</DialogTitle>
					<DialogContent
						id="classic-modal-slide-description"
						className={classes.modalBody}
					>
						Dein Verein ist aktuell nicht vollständig. Damit ein Verein in der
						{` ${tenant.title}`} Sportapp angezeigt wird müssen ein Logo, eine
						Beschreibung und eine vollständige Adresse vorhanden sein.
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<Button
							color="primary"
							onClick={() => {
								handleClose();
							}}
						>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

ClubIncompleteDialog.propTypes = {
	classes: PropTypes.object,
	objectId: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

export default withStyles(modalStyle)(ClubIncompleteDialog);
