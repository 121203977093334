/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
// @mui/material components
import FlagIcon from "@mui/icons-material/Flag";
// @mui/icons-material
// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import { eventPageStyles } from "./styles";
// nodejs library that concatenates classes
import classNames from "classnames";

import SportappHeader from "customComponents/SportappHeader/Header";
import Table from "components/Table/Table";
import ClubLogoImg from "customComponents/ClubLogoImg/ClubLogoImg.js";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import {
	getAgeGroupToDisplay,
	getQueryParam,
	getSchedulesToDisplay,
	mapDayEnumsToFullName,
} from "utils/Utils.js";
import Button from "components/CustomButtons/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { observer } from "mobx-react";
import { RootStoreContext } from "mobX/RootStore.js";
import backgroundImage from "customAssets/img/ssb_background.png";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Badge from "../../components/Badge/Badge";

const EventDetails = observer(() => {
	const store = useContext(RootStoreContext);
	const eventStore = store.eventStore;

	const [event, setEvent] = useState({ image: null });
	const [backgroundImageURI, setBackgroundImageURI] = useState(backgroundImage);
	const eventId = getQueryParam("id");

	useEffect(() => {
		eventStore.fetchEvents();
	}, []);

	useEffect(() => {
		if (!eventStore.isLoading) {
			const event = eventStore.getEventById(eventId);
			if (event) {
				setEvent(event);
			}
		}
	}, [eventStore.isLoading]);

	useEffect(() => {
		if (event.image) setBackgroundImageURI(event.image);
	}, [event.image]);

	const coursesForDay = (courses, day) => {
		return courses.filter((course) =>
			course.schedule.schedules.some((schedule) => schedule.day === day),
		);
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = eventPageStyles();

	const imageClasses = classNames(
		classes.eventClubLogo,
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgFluid,
	);

	const [hover, setHover] = useState(false);
	const sendMailToAdmin = () => {
		const body =
			`Kommentar: Beschreibung der fehlerhaften Angaben… (bitte ergänzen!)\n\n\n\n\n` +
			`Verein: ${event.name}\n` +
			`Kategorie: ${event.club.name}\n` +
			`Angebot: ${"Veranstaltung"}\n` +
			`Link: ${window.location.href}\n`;
		window.location.href = `
			mailto:${encodeURIComponent(supportEmail)}
			?subject=${encodeURIComponent("Fehlerhafte Angaben im Angebot: " + event.name)}
			&body=${encodeURIComponent(body)}
		`;
	};
	const {
		tenantStore: { data: tenant, supportEmail },
	} = store;
	console.log(event.courses);

	return (
		<div>
			<SportappHeader />

			<Parallax image={backgroundImageURI} filter="dark" small>
				{event && event.club && (
					<div className={classes.container}>
						<GridContainer
							justifyContent="center"
							className={classes.parallaxTitle}
						>
							<GridItem xs={4} sm={4} md={2} className={classes.titleClubLogo}>
								<ClubLogoImg
									clubLogo={event.club.logo}
									alt={event.club.name}
									imgClasses={imageClasses}
								/>
							</GridItem>
							<GridItem xs={12} sm={8} md={8} className={classes.textCenter}>
								<h1 className={classes.title}>{event.name}</h1>
								<h4 className={classes.title}>
									Veranstalter: {event.club.name}
								</h4>
								<h3 className={classes.title}>
									{new Date(event.schedule.from).toLocaleDateString()} -{" "}
									{new Date(event.schedule.to).toLocaleDateString()}
								</h3>
							</GridItem>
						</GridContainer>
					</div>
				)}
			</Parallax>
			{event && (
				<div className={classes.main}>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
							<div className={classNames(classes.container, classes.section)}>
								<h3 className={classes.tagline}>{event.tagline}</h3>
								<div>
									<button
										onClick={() => sendMailToAdmin(event)}
										onMouseEnter={() => setHover(true)}
										onMouseLeave={() => setHover(false)}
										style={{
											position: "fixed",
											right: "30px",
											button: "90px",
											zIndex: "1000",
											backgroundColor: "transparent",
											border: "none",
											cursor: "pointer",
										}}
									>
										<Tooltip title="Melden">
											<IconButton size="large">
												<FlagIcon
													style={{ color: hover ? "#f44336" : "#3C4858" }}
												/>
											</IconButton>
										</Tooltip>
									</button>
								</div>
								<div
									dangerouslySetInnerHTML={{ __html: event.descriptionAsHtml }}
									style={{ textAlign: "initial" }}
								/>
							</div>
						</GridItem>
						<GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
							<div>
								<div className={classes.details}>{event.details}</div>
							</div>
						</GridItem>
						{[
							"MONDAY",
							"TUESDAY",
							"WEDNESDAY",
							"THURSDAY",
							"FRIDAY",
							"SATURDAY",
							"SUNDAY",
						].map((day) => {
							const courses = event.courses
								? coursesForDay(event.courses, day)
								: [];

							if (courses.length > 0)
								return (
									<GridItem
										xs={10}
										sm={10}
										md={8}
										className={classes.textCenter}
										key={day}
									>
										<h3 className={classes.weekdayHeader}>
											Jeden {mapDayEnumsToFullName(day)}
										</h3>

										<Table
											striped
											tableData={courses.map((course, index) => {
												const eventStartDate = new Date(event.schedule.from);
												const startDate = new Date(
													course.schedule.startingDate || null,
												);
												const isStartDateLater = startDate > eventStartDate;
												const isStartDateInFuture = startDate > new Date();
												const startsLater =
													isStartDateLater && isStartDateInFuture;

												return [
													<ClubLogoImg
														key={`nextButton-${index}`}
														clubLogo={course.club.logo}
														alt={course.club.name}
														imgClasses={classes.img}
													/>,
													<div
														style={{
															display: "flex",
															alignItems: "flex-start",
															flexDirection: "column",
														}}
													>
														{startsLater && (
															<Badge color={"danger"}>
																Ab{" "}
																{new Date(
																	course.schedule.startingDate,
																).toLocaleDateString(undefined, {
																	day: "numeric",
																	month: "short",
																})}
															</Badge>
														)}
														{course.title}
													</div>,
													course.schedule.schedules
														? getSchedulesToDisplay(course.schedule.schedules)
														: "-",
													course.targetGroup
														? getAgeGroupToDisplay(course.targetGroup)
														: "-",
													<Link
														key={`nextButton-${index}`}
														to={`/${tenant.path}/course/${encodeURIComponent(
															course.title,
														)}?id=${course.id}`}
													>
														<Button
															color="primary"
															className={classes.detailsButton}
														>
															<ArrowForwardIcon />
														</Button>
													</Link>,
												];
											})}
											customCellClasses={[
												classes.clubIcon,
												classes.activityTitle,
												classes.schedule,
												classes.targetGroup,
												classes.detailsButton,
											]}
											customClassesForCells={[0, 1, 2, 3, 4]}
										/>
									</GridItem>
								);
						})}
						{event.sponsors && event.sponsors.length > 0 && (
							<GridContainer className={classes.sponsorWrapper}>
								<GridItem
									xs={12}
									sm={12}
									md={12}
									className={classes.textCenter}
								>
									<h4>
										<strong>{event.name}</strong> wird unterstützt von
									</h4>
								</GridItem>

								{event.sponsors.map((sponsor) => (
									<GridItem xs={12} sm={4} key={sponsor.name}>
										<Card profile>
											<CardHeader image>
												<a
													href={sponsor.url}
													onClick={(e) => e.preventDefault()}
												>
													<img
														style={{ background: "white" }}
														src={sponsor.logo}
														alt={sponsor.name}
													/>
												</a>
												<div
													className={classes.coloredShadow}
													style={{
														backgroundImage: `url(${sponsor.logo})`,
														opacity: "1",
													}}
												/>
											</CardHeader>
											<CardBody>
												<h4 className={classes.cardTitle}>{sponsor.name}</h4>
											</CardBody>
											<CardFooter
												profile
												className={classes.justifyContentCenter}
											>
												<Button round color="info" href={sponsor.url}>
													{sponsor.callToAction}
												</Button>
											</CardFooter>
										</Card>
									</GridItem>
								))}
							</GridContainer>
						)}
					</GridContainer>
				</div>
			)}
			<Footer parentClasses={classes} />
		</div>
	);
});

export default EventDetails;
