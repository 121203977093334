import React from "react";
import { observer } from "mobx-react";
// @mui/material
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";

// @mui/icons-material
import Check from "@mui/icons-material/Check";

import styles from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle";

const useStyles = makeStyles(styles);

const TypeCheckBoxes = observer(
	({ offeringTypes = [], toggleOfferingType }) => {
		const handleToggle = (offeringType) => {
			toggleOfferingType(offeringType);
		};

		const classes = useStyles();
		return (
			<div className={classes.customExpandPanel}>
				<div
					className={
						classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
					}
				>
					<FormControlLabel
						control={
							<Checkbox
								tabIndex={-1}
								onClick={() => handleToggle("EVERYTHING")}
								checked={offeringTypes.length === 0}
								checkedIcon={<Check className={classes.checkedIcon} />}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot,
								}}
							/>
						}
						classes={{ label: classes.label }}
						label="Alles"
					/>
				</div>
				<div
					className={
						classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
					}
				>
					<FormControlLabel
						control={
							<Checkbox
								tabIndex={-1}
								onClick={() => handleToggle("TRAINING")}
								checked={offeringTypes.indexOf("TRAINING") !== -1}
								checkedIcon={<Check className={classes.checkedIcon} />}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot,
								}}
							/>
						}
						classes={{ label: classes.label }}
						label="Trainings"
					/>
				</div>
				<div
					className={
						classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
					}
				>
					<FormControlLabel
						control={
							<Checkbox
								tabIndex={-1}
								onClick={() => handleToggle("COURSE")}
								checked={offeringTypes.indexOf("COURSE") !== -1}
								checkedIcon={<Check className={classes.checkedIcon} />}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot,
								}}
							/>
						}
						classes={{
							label: classes.label,
						}}
						label="Kurse"
					/>
				</div>
			</div>
		);
	},
);

export default TypeCheckBoxes;
