import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
// core components
import Card from "components/Card/Card";
import CardAvatar from "components/Card/CardAvatar";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import OpenInNew from "@mui/icons-material/OpenInNew";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

import { getClubURI } from "../../utils/Utils";
import { RootStoreContext } from "mobX/RootStore";

const style = {
	...teamsStyle,
	...teamStyle,
	justifyContentRight: {
		justifyContent: "right",
	},
	clamp4Lines: {
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 4,
		overflow: "hidden",
	},
	clubCard: {
		marginTop: "72px",
	},
};

const useStyles = makeStyles(style);

const ClubCard = observer(({ club }) => {
	const classes = useStyles();

	const store = useContext(RootStoreContext);

	const {
		tenantStore: { data: tenant },
	} = store;

	return (
		<GridItem className={classes.clubCard} xs={12} sm={4} md={4}>
			<Card plain profile>
				<Link
					to={getClubURI(club.name, club.id, tenant.path)}
					style={{ alignSelf: "flex-end" }}
				>
					<Button justIcon color="primary" target="_blank">
						<OpenInNew />
					</Button>
				</Link>
				<Link to={getClubURI(club.name, club.id, tenant.path)}>
					<CardAvatar profile>
						<img src={club.logoURI} alt="club logo" />
					</CardAvatar>
				</Link>

				<CardBody plain>
					<h4 className={classes.cardTitle}>{club.name}</h4>
					<p
						className={classNames([classes.cardCategory, classes.clamp4Lines])}
					>
						{club.description}
					</p>
				</CardBody>
			</Card>
		</GridItem>
	);
});

export default ClubCard;
