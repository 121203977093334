/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

// core components
import Footer from "components/Footer/Footer";
import SportappHeader from "customComponents/SportappHeader/Header";
import Parallax from "components/Parallax/Parallax";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";

import ssbBackground from "customAssets/img/ssb_background.png";
import EventList from "./components/EventList";

const styles = () => {
	return {
		...landingPageStyle,
		...profilePageStyle,
		logoImg: {
			width: "100%",
			height: "auto",
		},
		container: {
			...landingPageStyle.container,
			paddingTop: 10,
		},
		textCenter: {
			textAlign: "center",
		},
	};
};

const useStyles = makeStyles(styles);
export default function EventsPage() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	}, []);

	const classes = useStyles();
	return (
		<div>
			<SportappHeader />
			<Parallax
				image={ssbBackground}
				filter="dark"
				className={classes.parallax}
			/>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					<EventList />
				</div>
			</div>
			<Footer parentClasses={classes}/>
		</div>
	);
}

EventsPage.propTypes = {
	classes: PropTypes.object,
};
