import { fetchAuthSession } from "aws-amplify/auth";
import { Redirect } from "react-router-dom";

/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";

// core components
import Parallax from "components/Parallax/Parallax";
import Footer from "components/Footer/Footer";
import CustomTabs from "components/CustomTabs/CustomTabs";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
// custom components
import AdminModal from "customComponents/Modals/AdminModal";
import ClubModal from "customComponents/Modals/ClubModal";
import CustomizedSnackbar from "customComponents/CustomizedSnackbar/CustomizedSnackbar";
import SportappHeader from "customComponents/SportappHeader/Header";

import backgroundImage from "customAssets/img/ssb_background.png";
import { observer } from "mobx-react";
import { RootStoreContext } from "../../mobX/RootStore";
import styles from "./styles";

const Dashboard = observer(({ classes }) => {
	const store = useContext(RootStoreContext);
	const tenant = store.tenantStore.data;

	const [isTenant, setIsTenant] = useState(false);
	const [isValidatingUser, setIsValidatingUser] = useState(true);

	useEffect(async () => {
		try {
			const authSession = await fetchAuthSession();

			if (
				authSession?.tokens?.accessToken?.payload["cognito:groups"]?.find(
					(group) => group === `${tenant.key}-Admins`,
				)
			) {
				setIsTenant(true);
				store.clubStore
					.fetchClubsAsTenant()
					.then(() => setIsValidatingUser(false));
			} else {
				store.clubStore
					.fetchClubsAsAdmin()
					.then(() => setIsValidatingUser(false));
			}
		} catch (error) {
			console.error(error);
		}
	}, []);

	const createAdmin = (adminData, clubAdminGroup) => {
		store.clubStore.createAdmin(adminData, clubAdminGroup);
	};

	const updateAdmin = (
		newAdminData,
		newClubAdminGroup,
		prevAdminData,
		prevClubAdminGroup,
	) => {
		store.clubStore.updateAdmin(
			newAdminData,
			newClubAdminGroup,
			prevAdminData,
			prevClubAdminGroup,
		);
	};

	const resendInvitation = (admin) => {
		store.clubStore.resendAdminInvitation(admin);
	};

	const createClub = (clubData) => {
		store.clubStore.createClubWithAdmin(clubData);
	};

	const setIdInSessionStorage = (id) => {
		sessionStorage.setItem("clubId", id);
	};

	const redirect = () => {
		const club = store.clubStore.selectedClub;
		setIdInSessionStorage(club.id);

		return (
			<Redirect
				to={{
					pathname: `/${tenant.path}/admin/club`,
				}}
			/>
		);
	};

	const mapUserStatus = (status) => {
		switch (status) {
			case "UNCONFIRMED":
				return "Nicht Bestätigt";
			case "CONFIRMED":
				return "Aktiv";
			case "FORCE_CHANGE_PASSWORD":
				return "Nicht aktiviert";
			case "EXPIRED":
				return "Abgelaufen";
			case "COMPROMISED":
				return "Deaktiviert";
			case "RESET_REQUIRED":
				return "Zurücksetzung erforderlich";
			case "ARCHIVED":
				return "Archiviert";
			case "UNKNOWN":
			default:
				return "keine Information";
		}
	};
	const clubs = store.clubStore.sortedClubs;
	const admins = store.clubStore.admins;

	return (
		<div>
			{!isTenant && !isValidatingUser && redirect()}
			{isTenant && !isValidatingUser && (
				<div>
					<SportappHeader />
					<Parallax
						image={backgroundImage}
						filter="dark"
						className={classes.parallax}
					/>
					<CustomizedSnackbar
						open={!!store.UIStore.successMessage}
						variant={"success"}
						message={store.UIStore.successMessage}
						onClose={store.UIStore.resetSuccessMessage}
					/>
					<CustomizedSnackbar
						open={!!store.UIStore.errorMessage}
						variant={"error"}
						message={store.UIStore.errorMessage}
						onClose={store.UIStore.resetErrorMessage}
					/>
					{clubs && (
						<div className={classNames(classes.main, classes.mainRaised)}>
							<CustomTabs
								headerColor="primary"
								tabs={[
									{
										tabName: "Vereine",
										tabContent: (
											<div>
												<Table
													tableHead={[
														"Vereinsname",
														"Straße",
														"Postleitzahl",
														"Ort",
													]}
													tableData={clubs.map((club) => [
														club.name,
														`${
															club.address && club.address.street
																? club.address.street
																: "-"
														} ${
															club.address && club.address.houseNumber
																? club.address.houseNumber
																: ""
														}`,
														club.address && club.address.zipCode
															? club.address.zipCode
															: "-",
														club.address && club.address.city
															? club.address.city
															: "-",
														<Button
															simple
															href={`/${tenant.path}/admin/club`}
															onClick={() => {
																setIdInSessionStorage(club.id);
															}}
															color="primary"
															size="lg"
														>
															<i className="fas fa-edit" />
														</Button>,
													])}
													customHeadClassesForCells={[0, 4, 5]}
												/>
												<ClubModal
													createClub={createClub}
													clubLimit={store.tenantStore.clubLimit}
													tier={store.tenantStore.licenseTier}
													clubCount={clubs.length}
												/>
											</div>
										),
									},
									{
										tabName: "Admins",
										tabContent: (
											<div>
												<Table
													tableHead={[
														"Benutzername",
														"E-Mail",
														"Status",
														"Verein",
													]}
													tableData={admins
														.sort((a, b) =>
															(a.club.name || "").localeCompare(b.club.name),
														)
														.map((admin) => [
															admin.username,
															admin.email,
															mapUserStatus(admin.status),
															admin.club.name,
															<AdminModal
																onSubmit={updateAdmin}
																initialValues={admin}
																clubs={clubs}
															/>,
															(admin.status === "FORCE_CHANGE_PASSWORD" ||
																admin.status === "EXPIRED") && (
																<Button
																	color="primary"
																	onClick={() =>
																		resendInvitation({
																			username: admin.username,
																			email: admin.email,
																		})
																	}
																>
																	Einladung schicken
																</Button>
															),
														])}
													customHeadClassesForCells={[0, 4, 5]}
												/>
												<AdminModal onSubmit={createAdmin} clubs={clubs} />
											</div>
										),
									},
								]}
							/>
						</div>
					)}
					<Footer parentClasses={classes} />
				</div>
			)}
		</div>
	);
});

const withPageStyles = withStyles(styles);

export default withPageStyles(Dashboard);
