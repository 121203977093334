import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
// core components
import GridContainer from "components/Grid/GridContainer.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";

import ClubCard from "customComponents/ClubCard/ClubCard";

import { useContext } from "react";
import { observer } from "mobx-react";
import { RootStoreContext } from "../../mobX/RootStore";

const style = {
	...teamsStyle,
	...teamStyle,
	justifyContentCenter: {
		justifyContent: "center",
	},
};

const useStyles = makeStyles(style);

const ClubList = observer(() => {
	const rootStore = useContext(RootStoreContext);
	const clubStore = rootStore.clubStore;

	useEffect(() => {
		clubStore.fetchClubs();
	}, []);

	const clubs = clubStore.filteredClubs;
	const classes = useStyles();
	return (
		<div className={classes.section}>
			<h2 className={classes.title}>Vereine</h2>
			<div>
				<GridContainer>
					{!clubStore.isLoading &&
						clubs.map((club) => <ClubCard club={club} key={club.id} />)}
				</GridContainer>
			</div>
		</div>
	);
});

export default ClubList;
