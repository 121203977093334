import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { RootStoreContext } from "../../../mobX/RootStore";

import Filter from "./Filter";
import makeStyles from "@mui/styles/makeStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.js";
import CategoryAccordion from "./CategoryAccordion";
import { decodeFilter, encodeFilter } from "utils/Utils";
import { useHistory } from "react-router-dom";

const style = {
	...teamsStyle,
	...teamStyle,
	root: {
		padding: 0,
	},
	filterRoot: {
		minWidth: 170,
	},
};

const useStyles = makeStyles(style);

const ActivitiesList = observer(({ queryParamsFilter }) => {
	const store = useContext(RootStoreContext);
	const offeringStore = store.offeringStore;
	const courseStore = store.courseStore;
	const history = useHistory();
	const tenantStore = store.tenantStore;

	const [categorySections, setCategorySections] = useState([]);
	const [filter, setFilter] = useState({
		searchText: "",
		offeringTypes: [],
		ageGroups: [],
		schedules: [],
	});

	const updateFilter = (filter) => {
		const urlEncodedFilter = encodeFilter(filter);
		history.replace(
			`/${tenantStore.data.path}/activities/filter&${urlEncodedFilter}`,
		);
		setFilter(filter);
	};

	useEffect(() => {
		setFilter(decodeFilter(queryParamsFilter));
	}, [queryParamsFilter]);

	useEffect(() => {
		offeringStore.fetchOfferings();
		courseStore.fetchCourses();
	}, []);

	useEffect(() => {
		const trainings = offeringStore.filteredOfferings(filter);
		const courses = courseStore.filteredCourses(filter);

		const grouped = groupBy([...trainings, ...courses], "category");
		const formatted = formatForSectionList(grouped);

		setCategorySections(formatted);
	}, [offeringStore.isLoading, courseStore.isLoading, filter]);

	const groupBy = (list, key) => {
		return list.reduce((groups, element) => {
			(groups[element[key]] = groups[element[key]] || []).push(element);
			return groups;
		}, {});
	};

	const formatForSectionList = (list) => {
		return Object.keys(list)
			.sort()
			.map((key) => ({ title: key, data: list[key] }));
	};

	const classes = useStyles();
	return (
		<div className={classes.section}>
			<div className={classes.container}>
				<GridContainer>
					<GridItem xs={12} md={12} sm={12}>
						<h2 className={classes.title}>Aktivitäten</h2>
					</GridItem>
					<GridItem md={3} sm={3} classes={{ root: classes.filterRoot }}>
						<Filter filter={filter} setFilter={updateFilter} />
					</GridItem>
					<GridItem md={9} sm={9} classes={{ root: classes.root }}>
						{categorySections.map((section) => (
							<CategoryAccordion
								expand={categorySections.length === 1}
								key={section.title}
								category={section.title}
								offerings={section.data}
							/>
						))}
					</GridItem>
				</GridContainer>
			</div>
		</div>
	);
});

export default ActivitiesList;
