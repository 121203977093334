// @mui/material customComponents
import makeStyles from "@mui/styles/makeStyles";
import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

export const eventPageStyles = makeStyles({
	...blogPostsPageStyle,
	parallaxTitle: {
		display: "flex",
		alignItems: "center",
	},
	titleClubLogo: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	tagline: {
		textAlign: "center",
	},

	section: {
		padding: "70px",
		textAlign: "center",
	},
	weekdayHeader: {
		textAlign: "left",
		marginTop: "24px",
	},
	description: {
		//		color: grayColor[0],
	},
	details: {
		marginTop: 20,
		//		color: grayColor[0],
	},
	img: {
		width: 45,
		height: 45,
	},
	clubIcon: {
		width: 70,
	},
	eventClubLogo: {
		width: "100%",
		aspectRatio: 1,
	},
	activityTitle: {
		minWidth: 60,
	},
	schedule: {
		width: "20%",
		minWidth: 60,
		"@media (max-width: 500px)": {
			display: "none",
		},
	},
	targetGroup: {
		width: 100,
		"@media (max-width: 600px)": {
			display: "none",
		},
	},
	detailsButton: {
		width: 50,
	},
	main: {
		...blogPostsPageStyle.main,
		paddingBottom: "40px",
	},
	sponsorWrapper: {
		backgroundColor: primaryColor[4],
		marginLeft: 15,
		marginRight: 15,
		paddingLeft: 15,
		paddingRight: 15,
		marginTop: 30,
	},
	justifyContentCenter: {
		justifyContent: "center",
	},
});
