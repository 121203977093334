/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// @mui/icons-material
import Favorite from "@mui/icons-material/Favorite";
import { Link } from 'react-router-dom';

import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { children, content, theme, big, className, parentClasses } = props;
  const classes = useStyles();
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined,
  });
  const aClasses = classNames({
    [classes.a]: true,
  });

  return (
		<footer className={footerClasses}>
			<div className={classes.container}>
				{children !== undefined ? (
					<div>
						<div className={classes.content}>{children}</div>
						<hr />
					</div>
				) : (
					" "
				)}
				{content || (
					<div>
						<div className={parentClasses.left}>
							<List className={parentClasses.list}>
								<ListItem className={parentClasses.inlineBlock}>
									<Link to="/impressum/" className={parentClasses.block}>
										Impressum
									</Link>
								</ListItem>
								<ListItem className={parentClasses.inlineBlock}>
									<Link to="/datenschutz/" className={parentClasses.block}>
										Datenschutzerklärung
									</Link>
								</ListItem>
							</List>
						</div>
						<div className={parentClasses.right}>
							&copy; {1900 + new Date().getYear()} Solinger Sportbund e.V.
						</div>
					</div>
				)}
				<div className={parentClasses.clearFix} />
			</div>
		</footer>
	);
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
  content: PropTypes.node,
};
