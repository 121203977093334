export function amplifyconfiguration() {
	switch (process.env.NODE_ENV) {
		case "development":
			return {
				aws_project_region: "eu-central-1",
				aws_mobile_analytics_app_id: "59a77cb866024a34b3ac68d295192339",
				aws_mobile_analytics_app_region: "eu-central-1",
				Analytics: {
					AWSPinpoint: {
						appId: "59a77cb866024a34b3ac68d295192339",
						region: "eu-central-1",
					},
				},
				Notifications: {
					Push: {
						AWSPinpoint: {
							appId: "59a77cb866024a34b3ac68d295192339",
							region: "eu-central-1",
						},
					},
				},
				aws_appsync_graphqlEndpoint:
					"https://rkgz2skolzahhgzd37yirrgvf4.appsync-api.eu-central-1.amazonaws.com/graphql",
				aws_appsync_region: "eu-central-1",
				aws_appsync_authenticationType: "AWS_LAMBDA",
				aws_cognito_identity_pool_id:
					"eu-central-1:c5fa34b7-ab20-4bf5-af1a-84157807e02b",
				aws_cognito_region: "eu-central-1",
				aws_user_pools_id: "eu-central-1_3mcGKKuXj",
				aws_user_pools_web_client_id: "4kk2kbhjugk6obgihqq0jqqmua",
				oauth: {},
				aws_cognito_username_attributes: [],
				aws_cognito_social_providers: [],
				aws_cognito_signup_attributes: ["EMAIL"],
				aws_cognito_mfa_configuration: "OPTIONAL",
				aws_cognito_mfa_types: ["SMS"],
				aws_cognito_password_protection_settings: {
					passwordPolicyMinLength: 8,
					passwordPolicyCharacters: [
						"REQUIRES_LOWERCASE",
						"REQUIRES_UPPERCASE",
						"REQUIRES_NUMBERS",
						"REQUIRES_SYMBOLS",
					],
				},
				aws_cognito_verification_mechanisms: ["EMAIL"],
				aws_user_files_s3_bucket: "solinger-sportbund-images-bucket-dev",
				aws_user_files_s3_bucket_region: "eu-central-1",
				geo: {
					amazon_location_service: {
						region: "eu-central-1",
						maps: {
							items: {
								"SportappMap-dev": {
									style: "VectorEsriStreets",
								},
							},
							default: "SportappMap-dev",
						},
						search_indices: {
							items: ["SportappPlaceIndex-dev"],
							default: "SportappPlaceIndex-dev",
						},
					},
				},
			};
		case "production":
			return {
				aws_project_region: "eu-central-1",
				aws_mobile_analytics_app_id: "7bd71fe2b6774ce18aa610c46ad0325e",
				aws_mobile_analytics_app_region: "eu-central-1",
				Analytics: {
					AWSPinpoint: {
						appId: "7bd71fe2b6774ce18aa610c46ad0325e",
						region: "eu-central-1",
					},
				},
				Notifications: {
					Push: {
						AWSPinpoint: {
							appId: "7bd71fe2b6774ce18aa610c46ad0325e",
							region: "eu-central-1",
						},
					},
				},
				aws_appsync_graphqlEndpoint:
					"https://rmpysk2kgbfrzbz2oxzzitwhpe.appsync-api.eu-central-1.amazonaws.com/graphql",
				aws_appsync_region: "eu-central-1",
				aws_appsync_authenticationType: "AWS_LAMBDA",
				aws_cognito_identity_pool_id:
					"eu-central-1:43666e6e-debe-4538-be15-99ee2cd801ae",
				aws_cognito_region: "eu-central-1",
				aws_user_pools_id: "eu-central-1_qdED3J68Y",
				aws_user_pools_web_client_id: "4n6ud6spnk1eve5et1ck20mk3c",
				oauth: {},
				aws_cognito_username_attributes: [],
				aws_cognito_social_providers: [],
				aws_cognito_signup_attributes: ["EMAIL"],
				aws_cognito_mfa_configuration: "OPTIONAL",
				aws_cognito_mfa_types: ["SMS"],
				aws_cognito_password_protection_settings: {
					passwordPolicyMinLength: 8,
					passwordPolicyCharacters: [
						"REQUIRES_LOWERCASE",
						"REQUIRES_UPPERCASE",
						"REQUIRES_NUMBERS",
						"REQUIRES_SYMBOLS",
					],
				},
				aws_cognito_verification_mechanisms: ["EMAIL"],
				aws_user_files_s3_bucket: "solinger-sportbund-images-bucket-prod",
				aws_user_files_s3_bucket_region: "eu-central-1",
				geo: {
					amazon_location_service: {
						region: "eu-central-1",
						maps: {
							items: {
								"SportappMap-prod": {
									style: "VectorEsriStreets",
								},
							},
							default: "SportappMap-prod",
						},
						search_indices: {
							items: ["SportappPlaceIndex-prod"],
							default: "SportappPlaceIndex-prod",
						},
					},
				},
			};
		default:
			return {};
	}
}
