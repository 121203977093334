import React, { useEffect, useState, useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
// core components
import Button from "components/CustomButtons/Button.js";
import { RootStoreContext } from "mobX/RootStore";

import styles from "assets/jss/material-kit-pro-react/components/customDropdownStyle.js";

import { Link } from "react-router-dom";

const split = (array = [], numCols) => {
	if (numCols === 1 || array.length === 0) return [array];
	const size = Math.ceil(array.length / numCols);
	return [array.slice(0, size), ...split(array.slice(size), numCols - 1)];
};

const truncate = (text, length) => text.substring(0, length).trim() + "...";

export default function MultiColumnDropdown(props) {
	const store = useContext(RootStoreContext);
	const { tenantStore } = store;

	const useStyles = makeStyles(
		styles({
			customPrimaryColor: tenantStore.primary1Color,
			customSecondaryColor: tenantStore.primary2Color,
			dropdownItem: {
				borderRadius: "2px",
				clear: "both",
				color: "#333",
				display: "block",
				fontFamily: '"Fira Sans", "Helvetica", "Arial", sans-serif',
				fontSize: "13px",
				fontWeight: "400",
				lineHeight: "1.5em",
				margin: "0 5px",
				minHeight: "unset",
				padding: "10px 20px",
				position: "relative",
				transition: "all 150ms linear",
				whiteSpace: "nowrap",
			},
			column: {
				width: 200,
				"@media (max-width: 960px)": {
					width: 190,
				},
			},
			columnWrapper: {
				display: "flex",
			},
			oneLine: {
				"@media (max-width: 960px)": {
					display: "block",
				},
			},
			highlighted: {
				backgroundColor: tenantStore.primary1Color || "#fbc02d",
				color: "white",
			},
		}),
	);

	const [anchorEl, setAnchorEl] = useState(null);
	const [numColumns, setNumColumns] = useState(2);

	useEffect(() => {
		if (props.data) {
			const numCol = Math.ceil(Math.sqrt(data.length * ratio));
			setNumColumns(numCol);
		}
	}, [props.ratio, props.data]);

	const handleClick = (event) => {
		if (anchorEl && anchorEl.contains(event.target)) {
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = (event) => {
		if (anchorEl.contains(event.target)) {
			return;
		}
		setAnchorEl(null);
	};

	const handleCloseMenu = (param) => {
		setAnchorEl(null);
		if (props && props.onClick) {
			props.onClick(param);
		}
	};

	const {
		buttonText,
		buttonIcon,
		data,
		buttonProps,
		dropup,
		dropdownHeader,
		caret,
		hoverColor,
		dropPlacement,
		rtlActive,
		noLiPadding,
		innerDropDown,
		navDropdown,
		ratio,
		itemToDisplay,
		keyExtractor,
		linkTo,
	} = props;

	const classes = useStyles();
	const caretClasses = classNames({
		[classes.caret]: true,
		[classes.caretDropup]: dropup && !anchorEl,
		[classes.caretActive]: Boolean(anchorEl) && !dropup,
		[classes.caretRTL]: rtlActive,
	});
	const dropdownItem = classNames({
		[classes.dropdownItem]: true,
		[classes[hoverColor + "Hover"]]: true,
		[classes.noLiPadding]: noLiPadding,
		[classes.dropdownItemRTL]: rtlActive,
	});

	const dropDownMenu = (
		<MenuList role="menu" className={classes.menuList}>
			{dropdownHeader !== undefined ? (
				<MenuItem
					onClick={() => handleCloseMenu(dropdownHeader)}
					className={classes.dropdownHeader}
				>
					{dropdownHeader}
				</MenuItem>
			) : null}
			<div
				className={classNames({
					[classes.columnWrapper]: true,
					[classes.oneLine]: Boolean(anchorEl) && navDropdown,
				})}
			>
				{split(data, numColumns).map((column, index) => {
					return (
						<div key={index} className={classes.column}>
							{column.map((item) => {
								let text = itemToDisplay(item);
								text = text.length > 23 ? truncate(text, 23) : text;
								const link = linkTo ? linkTo(item) : "";
								return (
									<>
										<Link to={link} key={keyExtractor(item)}>
											<MenuItem
												key={keyExtractor(item)}
												onClick={() => handleCloseMenu(item)}
												className={classNames(
													dropdownItem,
													item.highlighted ? classes.highlighted : "",
												)}
											>
												{text}
											</MenuItem>
										</Link>
									</>
								);
							})}
						</div>
					);
				})}
			</div>
		</MenuList>
	);

	return (
		<div className={innerDropDown ? classes.innerManager : classes.manager}>
			<div className={buttonText !== undefined ? "" : classes.target}>
				<Button
					aria-label="Notifications"
					aria-owns={anchorEl ? "menu-list" : null}
					aria-haspopup="true"
					{...buttonProps}
					onClick={handleClick}
				>
					{buttonIcon !== undefined ? (
						<props.buttonIcon className={classes.buttonIcon} />
					) : null}
					{buttonText !== undefined ? buttonText : null}
					{caret ? <b className={caretClasses} /> : null}
				</Button>
			</div>
			<Popper
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				disablePortal
				placement={dropPlacement}
				className={classNames({
					[classes.popperClose]: !anchorEl,
					[classes.pooperResponsive]: true,
					[classes.pooperNav]: Boolean(anchorEl) && navDropdown,
				})}
			>
				{() => (
					<Grow
						in={Boolean(anchorEl)}
						id="menu-list"
						style={
							dropup
								? { transformOrigin: "0 100% 0" }
								: { transformOrigin: "0 0 0" }
						}
					>
						<Paper className={classes.dropdown}>
							{innerDropDown ? (
								dropDownMenu
							) : (
								<ClickAwayListener onClickAway={handleClose}>
									{dropDownMenu}
								</ClickAwayListener>
							)}
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}

MultiColumnDropdown.defaultProps = {
	caret: true,
	dropup: false,
	hoverColor: "primary",
	ratio: 3 / 10,
};

MultiColumnDropdown.propTypes = {
	hoverColor: PropTypes.oneOf([
		"dark",
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
	]),
	buttonText: PropTypes.node,
	buttonIcon: PropTypes.object,
	data: PropTypes.array,
	buttonProps: PropTypes.object,
	dropup: PropTypes.bool,
	dropdownHeader: PropTypes.node,
	rtlActive: PropTypes.bool,
	caret: PropTypes.bool,
	dropPlacement: PropTypes.oneOf([
		"bottom",
		"top",
		"right",
		"left",
		"bottom-start",
		"bottom-end",
		"top-start",
		"top-end",
		"right-start",
		"right-end",
		"left-start",
		"left-end",
	]),
	noLiPadding: PropTypes.bool,
	innerDropDown: PropTypes.bool,
	navDropdown: PropTypes.bool,
	// This is a function that returns the clicked menu item
	onClick: PropTypes.func,
	ratio: PropTypes.number,
	itemToDisplay: PropTypes.func,
	keyExtractor: PropTypes.func,
	linkTo: PropTypes.func,
};
