// @mui/material customComponents
import makeStyles from "@mui/styles/makeStyles";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";

export const clubDetailsStyles = makeStyles({
	...profilePageStyle,
	container: {
		...profilePageStyle.container,
		color: "black",
	},
	imgWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	icon: {
		margin: "auto",
		marginTop: 10,
		fontSize: 35,
	},
	offeringList: {
		display: "flex",
		flexWrap: "wrap",
	},
	description: {
		...profilePageStyle.description,
		color: "black",
	},
	homepageUrl: {
		...profilePageStyle.block,
		paddingLeft: 0,
	},
});
