import React, { useContext } from "react";

// @mui/material
import Switch from "@mui/material/Switch";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// custom components
import CustomDropdownAdapter from "customComponents/CustomDropdownAdapter/CustomDropdownAdapter";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Field } from "react-final-form-html5-validation";
import arrayMutators from "final-form-arrays";
import FormControlLabel from "@mui/material/FormControlLabel";
import createDecorator from "final-form-calculate";

import categories from "../../../utils/categories";

import makeStyles from "@mui/styles/makeStyles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { encodeFilter } from "../../../utils/Utils";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CSS_COLOR_NAMES from "../../../utils/css_colors";
import { RootStoreContext } from "mobX/RootStore";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";

const Input = styled(MuiInput)`
	width: 80px;
`;

const genderDecorator = createDecorator(
	{
		field: "targetGroup.male",
		updates: {
			"targetGroup.female": (male, all) =>
				male ? all.targetGroup.female : true,
		},
	},
	{
		field: "targetGroup.female",
		updates: {
			"targetGroup.male": (female, all) =>
				female ? all.targetGroup.male : true,
		},
	},
);

const style = (theme) => ({
	...modalStyle(theme),
	modalRootExample: {
		"& > div:first-child": {
			display: "none",
		},
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	removeButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
	widgetCode: {
		backgroundColor: "lightgrey",
		padding: 10,
		fontFamily: "monospace",
		borderRadius: 5,
		height: 120,
		margin: 5,
	},
});

const useStyles = makeStyles(style);

const useSwitchAdapterStyles = makeStyles(basicsStyle);

// eslint-disable-next-line react/prop-types
const CustomInputAdapter = ({ input, meta, inputProps, ...rest }) => (
	<CustomInput
		labelText={
			meta.error && meta.touched ? `${rest.label} (${meta.error})` : rest.label
		}
		inputProps={{ ...inputProps, ...input }}
		formControlProps={{
			fullWidth: true,
		}}
		{...rest}
		error={meta.touched && !!meta.error}
	/>
);

CustomInputAdapter.propTypes = {
	meta: PropTypes.object,
};

// eslint-disable-next-line react/prop-types
const SwitchAdapter = ({ input, label }) => {
	const classes = useSwitchAdapterStyles();
	return (
		<FormControlLabel
			control={
				<Switch
					name={input.name}
					checked={input.value}
					onChange={input.onChange}
					classes={{
						switchBase: classes.switchBase,
						checked: classes.switchChecked,
						thumb: classes.switchIcon,
						track: classes.switchBar,
					}}
				/>
			}
			label={label}
		/>
	);
};

SwitchAdapter.propTypes = {
	input: PropTypes.object,
};

const WidgetGenerator = ({ clubID }) => {
	const filter = {
		from: 0,
		to: 99,
		male: true,
		female: true,
		schedules: [],
		clubID,
		category: "",
		color: "",
		fontFamily: "",
		doUseComplexDesign: false,
	};

	const generateWidgetURL = (filter, tenantPath, height = 0) => {
		return (
			//for local testing
			//`http://localhost:3000/widget/offerings?${encodeFilter(filter)}`
			`<iframe allowTransparency="true" style="border:none" height="${height}px" width="100%" src="${process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://ich-trainiere.in"}/${tenantPath}/widget/offerings?${encodeFilter(
				filter,
			)}"></iframe>`
		);
	};

	const classes = useStyles();
	const store = useContext(RootStoreContext);
	const {
		tenantStore: { data: tenant },
	} = store;

	const [height, setHeight] = React.useState(1000);

	const handleHeightSliderChange = (event, newValue) => {
		setHeight(newValue);
	};
	const handleHeightInputChange = (event) => {
		setHeight(event.target.value === "" ? 0 : Number(event.target.value));
	};

	return (
		<div>
			<Form
				onSubmit={() => {}}
				decorators={[genderDecorator]}
				initialValues={filter}
				mutators={{ ...arrayMutators }}
				render={({ handleSubmit, values }) => (
					<form onSubmit={handleSubmit}>
						<GridContainer>
							<GridItem>
								<h2>Homepage-Widgets</h2>
								<p>
									Um Angebote, welche in der Sportapp hinterlegt sind auf der
									Vereinseigenen Seite anzuzeigen ist es möglich, ein
									sogenanntes widget einzubinden.
								</p>
								<p>{`Base-URL: https://ich-trainiere.in/${tenant.path}/widget/`}</p>
							</GridItem>
							<GridItem xs={10} sm={10} md={10} lg={10}>
								<div className={classes.widgetCode}>
									<p>{values ? generateWidgetURL(values, tenant.path) : ""}</p>
								</div>
							</GridItem>
							<GridItem xs={2} sm={2} md={2} lg={2}>
								<Button
									fullWidth
									color="primary"
									onClick={() => {
										navigator.clipboard.writeText(
											values
												? generateWidgetURL(values, tenant.path, height)
												: "",
										);
									}}
								>
									<i className="fas fa-copy" />
								</Button>
							</GridItem>
							<GridItem>
								<p>
									Bei der Erstellung eines Widgets ist es möglich einige Filter
									anzuwenden, um nicht alle, sondern nur spezifische Angebote
									anzuzeigen. Die Filter können weiter unten direkt eingestellt
									werden. Der obige Quelltext ist dann an geeigneter Stelle auf
									der eigenen Vereinshomepage zu integrieren.
								</p>
							</GridItem>
							<GridItem>
								<h3>Design</h3>
								<Box sx={{ width: 500 }}>
									<Typography id="input-slider" gutterBottom>
										Benutze aufwendiges Design mit Hintergrundbild.
									</Typography>
									<GridItem xs={6} sm={3} md={3} lg={3}>
										<Field
											name="doUseComplexDesign"
											component={SwitchAdapter}
											type="text"
											label="aufwendiges Design"
										/>
									</GridItem>
								</Box>
							</GridItem>
							<GridItem>
								<h3>Größe</h3>
								<Box sx={{ width: 500 }}>
									<Typography id="input-slider" gutterBottom>
										Höhe in Pixeln
									</Typography>
									<Grid container spacing={2} alignItems="center">
										<Grid item xs>
											<Slider
												min={300}
												max={2000}
												step={1}
												value={typeof height === "number" ? height : 0}
												onChange={handleHeightSliderChange}
												aria-labelledby="input-slider"
											/>
										</Grid>
										<Grid item>
											<Input
												value={height}
												size="small"
												onChange={handleHeightInputChange}
												inputProps={{
													step: 10,
													min: 0,
													max: 2000,
													type: "number",
													"aria-labelledby": "input-slider",
												}}
											/>
										</Grid>
									</Grid>
								</Box>
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<h3>Sportart</h3>
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<ul>
									<li>
										Name: <code>category</code>
									</li>
									<li>Typ: Text</li>
									<li>
										Zulässige Werte: Einzelner Name der gewünschten Sportart
									</li>
								</ul>
							</GridItem>
							<GridItem>
								<Field
									name="category"
									component={CustomDropdownAdapter}
									type="text"
									placeholder="Sportart"
									label="Sportart"
									dropDownList={categories}
									id="category"
									nonReactInputProps={{
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<h3>Zielgruppe</h3>
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<ul>
									<li>
										Name: <code>from, to</code>
									</li>
									<li>Typ: Integer</li>
									<li>
										Zulässige Werte: 0-99, <code>from</code> muss kleiner sein
										als <code>to</code>.
									</li>
								</ul>
							</GridItem>
							<GridItem xs={12}>
								<p>
									Hier können Alter und Geschlecht gewählt werden. Das Alter ist
									dabei &quot;überschneidend&quot;, also falls sich das
									Altersintervall eines Angebotes mit dem angegebenen
									überschneidet wird das Angebot ausgewählt.
								</p>
								<GridContainer key="targetGroup">
									<GridItem xs={6} sm={6} md={6} lg={6}>
										<Field
											name="from"
											component={CustomInputAdapter}
											inputProps={{
												type: "number",
											}}
											nonReactInputProps={{
												required: true,
												min: "0",
												max: "100",
											}}
											rangeUnderflow="Mind. 0 Jahre"
											rangeOverflow="Höchstens 100 Jahre"
											valueMissing="Eingabe erforderlich"
											stepMismatch="Nur ganze Zahlen"
											placeholder="Von"
											label="Mindestalter"
											validate={(value) => {
												if (value === null || value < 0)
													return "Ungültiges Alter";
											}}
										/>
									</GridItem>

									<GridItem xs={6} sm={6} md={6} lg={6}>
										<Field
											name="to"
											component={CustomInputAdapter}
											inputProps={{
												type: "number",
											}}
											nonReactInputProps={{
												required: true,
												min: "0",
												max: "100",
											}}
											rangeUnderflow="Mind. 0 Jahre"
											rangeOverflow="Höchstens 100 Jahre"
											valueMissing="Eingabe erforderlich"
											stepMismatch="Nur ganze Zahlen"
											placeholder="Bis"
											label="Höchstalter"
											validate={(value) => {
												if (value === null || value < 0)
													return "Ungültiges Alter";
											}}
										/>
									</GridItem>

									<GridItem xs={12} sm={12} md={12} lg={12}>
										<ul>
											<li>
												Name: <code>female, male</code>
											</li>
											<li>Typ: Boolean</li>
											<li>
												Zulässige Werte: <code>true</code> / <code>false</code>
											</li>
										</ul>
									</GridItem>
									<GridItem xs={6} sm={3} md={3} lg={3}>
										<Field
											name="female"
											component={SwitchAdapter}
											type="text"
											label="Weiblich"
										/>
									</GridItem>
									<GridItem xs={6} sm={3} md={3} lg={3}>
										<Field
											name="male"
											component={SwitchAdapter}
											type="text"
											label="Männlich"
										/>
									</GridItem>
									<GridItem>
										<h3>Textfarbe:</h3>
										<ul>
											<li>
												Name: <code>color</code>
											</li>
											<li>Typ: Text</li>
											<li>
												Zulässige Werte:{" "}
												<a
													href="https://www.w3schools.com/cssref/css_colors_legal.asp"
													target="_blank"
													rel="noreferrer"
												>
													CSS Farbwert
												</a>
											</li>
										</ul>

										<p>
											Hier kann die Textfarbe eingestellt werden. Zulässig sind
											hex-Werte (z.B. #ff0000) oder namentliche css Farbwerte
											(red, white, brown, etc.)
										</p>
										<Field
											name="color"
											component={CustomInputAdapter}
											type="text"
											placeholder="Textfarbe"
											label="Textfarbe"
											inputProps={{ multiline: true, maxRows: 10 }}
											validate={(value) => {
												const reg = /^#([0-9a-f]{3}){1,2}$/i;
												if (
													value &&
													!(
														reg.test(value) ||
														CSS_COLOR_NAMES.find(
															(css_color) =>
																css_color.toLowerCase() === value.toLowerCase(),
														)
													)
												)
													return "unzulässiger Farbwert!";
												return false;
											}}
										/>
									</GridItem>
									<GridItem>
										<h3>Schriftart:</h3>
										<ul>
											<li>
												Name: <code>fontFamily</code>
											</li>
											<li>Typ: Text</li>
											<li>
												Zulässige Werte: Alle css font-family Werte zulässig
												(Arial, Helvetica, sans-serif, etc.). Achtung: ob ein
												font unterstützt wird hängt vom jeweiligen Browser ab,
												es kann deshalb sein, dass ein font nicht immer
												angewendet werden kann (siehe:{" "}
												<a
													href="https://www.w3schools.com/css/css_font_websafe.asp"
													target="_blank"
													rel="noreferrer"
												>
													https://www.w3schools.com/css/css_font_websafe.asp
												</a>
												)
											</li>
										</ul>
										<p>.</p>
										<Field
											name="fontFamily"
											component={CustomInputAdapter}
											type="text"
											placeholder="Schriftart"
											label="Schriftart"
											inputProps={{ multiline: true, maxRows: 10 }}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</form>
				)}
			/>
		</div>
	);
};

WidgetGenerator.propTypes = {
	clubID: PropTypes.string,
};

export default WidgetGenerator;
