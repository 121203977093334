import React from "react";
import Datetime from "react-datetime";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";
import PropTypes from "prop-types";
import customInputStyle from "assets/jss/material-kit-pro-react/components/customInputStyle";

const datetimeStyle = {
	...customInputStyle,
	labelDatetime: {
		top: "9px",
	},
	formControl: {
		paddingTop: "24px",
	},
};

const CustomDatetimeAdapter = ({
	input,
	timeFormat,
	dateFormat,
	classes,
	label,
	labelProps,
}) => (
	<FormControl variant="standard" className={classes.formControl}>
		<InputLabel
			shrink={input.value !== ""}
			htmlFor={input.name}
			className={`${classes.labelDatetime} ${classes.labelRoot}`}
			{...labelProps}
		>
			{label}
		</InputLabel>
		<Datetime
			dateFormat={dateFormat}
			timeFormat={timeFormat}
			value={input.value}
			onChange={(data) => {
				if (moment.isMoment(data)) {
					input.onChange(data.format(dateFormat || timeFormat));
				} else {
					input.onChange(data);
				}
			}}
			timeConstraints={{ minutes: { step: 5 } }}
			inputProps={{
				id: input.name,
				name: input.name,
			}}
		/>
	</FormControl>
);

CustomDatetimeAdapter.propTypes = {
	input: PropTypes.object,
	classes: PropTypes.object,
	label: PropTypes.string,
	labelProps: PropTypes.object,
	timeFormat: PropTypes.any,
	dateFormat: PropTypes.any,
};
export default withStyles(datetimeStyle)(CustomDatetimeAdapter);
