import { makeAutoObservable, runInAction } from "mobx";
import messages from "../utils/messages";
import { remove, uploadData, getUrl } from "aws-amplify/storage";
import { buildImageKey } from "utils/Utils";

export class EventStore {
	transportLayer;
	rootStore;
	events = [];
	isLoading = true;

	constructor(rootStore, transportLayer) {
		makeAutoObservable(this);
		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	async fetchEvents() {
		const filter = {
			tenantAdminGroup: { beginsWith: this.rootStore.tenantStore.tenantKey },
		};
		try {
			this.isLoading = true;
			this.transportLayer
				.fetchEvents(undefined, undefined, filter)
				.then((fetchedEvents) => {
					runInAction(() => {
						fetchedEvents.forEach((event) => {
							this.updateEventFromServer(event);
						});
						this.isLoading = false;
					});
				})
				.catch((error) => {
					console.error(error);
				});
		} catch (error) {
			console.error(error);
		}
	}

	fetchHighlightedEvents() {
		const filter = {
			tenantAdminGroup: { beginsWith: this.rootStore.tenantStore.tenantKey },
		};

		try {
			this.isLoading = true;
			this.transportLayer
				.fetchHighlightedEvents(undefined, undefined, filter)
				.then((fetchedEvents) => {
					runInAction(() => {
						fetchedEvents.forEach((event) => {
							this.updateEventFromServer(event);
						});
						this.isLoading = false;
					});
				})
				.catch((error) => {
					console.error(error);
				});
		} catch (error) {
			console.error(error);
		}
	}

	get highlightedEvents() {
		return this.events.filter((event) => event.highlight);
	}

	fetchEventNames() {
		this.isLoading = true;

		const filter = {
			tenantAdminGroup: { beginsWith: this.rootStore.tenantStore.tenantKey },
		};

		this.transportLayer
			.fetchEventNames(undefined, undefined, filter)
			.then((res) => {
				runInAction(() => {
					if (res)
						res.forEach((event) => {
							this.updateEventFromServer(event);
						});
					this.isLoading = false;
				});
			});
	}

	updateEventFromServer(json) {
		const event = this.getEventById(json.id);
		if (event) {
			return event.updateEventFromServer(json);
		} else {
			const newEvent = new Event(this.rootStore, json);
			this.events.push(newEvent);
			return newEvent;
		}
	}

	removeEventFromStore(id) {
		const index = this.events.findIndex(
			(eventInStore) => eventInStore.id === id,
		);
		if (index > -1) this.events.splice(index, 1);
	}

	async createEvent(
		event,
		clubId,
		tenantAdminGroup,
		clubAdminGroup,
		imageFile,
	) {
		try {
			if (imageFile) {
				const ext = imageFile.name.split(".").pop();
				const path = `eventImages/${buildImageKey(imageFile)}.${ext}`;

				const result = await uploadData({
					key: path,
					data: imageFile,
					options: {
						accessLevel: "protected",
					},
				}).result;

				if (result.key) {
					const urlResult = await getUrl({
						key: result.key,
						options: {
							accessLevel: "protected",
						},
					});
					const fullUrl = urlResult.url.toString();
					event.image = fullUrl.split("?")[0];
				}
			}

			const response = await this.transportLayer.createEvent(
				event,
				clubId,
				tenantAdminGroup,
				clubAdminGroup,
			);
			if (response.data.createEvent) {
				const newEvent = this.updateEventFromServer(response.data.createEvent);
				this.events.push(newEvent);
				const club = this.rootStore.clubStore.getClubById(clubId);
				club.addEvent(newEvent);
				this.rootStore.UIStore.setSuccessMessage(messages.CREATE_EVENT_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.CREATE_EVENT_FAILURE);
		}
	}

	async updateEvent(event, imageFile) {
		try {
			if (imageFile) {
				if (event.image) {
					const imageKey = event.image.slice(
						event.image.indexOf("eventImages"),
					);
					await remove({
						key: imageKey,
						options: {
							accessLevel: "protected",
						},
					});
				}

				const ext = imageFile.name.split(".").pop();
				const path = `eventImages/${buildImageKey(imageFile)}.${ext}`;

				const result = await uploadData({
					key: path,
					data: imageFile,
					options: {
						accessLevel: "protected",
					},
				}).result;

				if (result.key) {
					const urlResult = await getUrl({
						key: result.key,
						options: {
							accessLevel: "protected",
						},
					});
					const fullUrl = urlResult.url.toString();
					event.image = fullUrl.split("?")[0];
				}
			}

			const response = await this.transportLayer.updateEvent(event);
			if (response.data.updateEvent) {
				const updatedEvent = response.data.updateEvent;
				this.updateEventFromServer(updatedEvent);

				this.rootStore.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.UPDATE_FAILURE);
		}
	}

	async deleteEvent(clubId, eventId) {
		try {
			const response = await this.transportLayer.deleteEvent(eventId);

			if (response.data.deleteEvent) {
				const eventInStore = this.getEventById(eventId);
				eventInStore.removeFromStore();
				this.rootStore.UIStore.setSuccessMessage(messages.DELETE_SUCCESS);
			}
		} catch (error) {
			console.error(error);
			this.rootStore.UIStore.setErrorMessage(messages.DELETE_FAILURE);
		}
	}

	getEventByName = (eventName) => {
		return this.events.find(
			(event) => eventName === event.name.replaceAll(" ", "-").toLowerCase(),
		);
	};

	getEventById = (id) => {
		return this.events.find((event) => event.id === id);
	};
}

export class Event {
	rootStore;

	id;
	name = null;
	clubAdminGroup = null;
	tenantAdminGroup = null;
	schedule = null;
	tagline = null;
	description = null;
	descriptionAsHtml = null;
	details = null;
	image = null;
	icon = null;
	highlight = false;
	courses = [];
	sponsors = [];

	constructor(rootStore, event) {
		makeAutoObservable(this);

		this.rootStore = rootStore;
		this.id = event.id;
		this.updateEventFromServer(event);
	}

	get asJson() {
		return {
			id: this.id,
			name: this.name,
			schedule: this.schedule,
			tagline: this.tagline,
			description: this.description,
			descriptionAsHtml: this.descriptionAsHtml,
			details: this.details,
			image: this.image,
			icon: this.icon,
		};
	}

	updateEventFromServer(event) {
		if (event.name) this.name = event.name;
		if (event.clubAdminGroup) this.clubAdminGroup = event.clubAdminGroup;
		if (event.tenantAdminGroup) this.tenantAdminGroup = event.tenantAdminGroup;
		if (event.schedule) this.schedule = event.schedule;
		if (event.tagline) this.tagline = event.tagline;
		if (event.description) this.description = event.description;
		if (event.descriptionAsHtml)
			this.descriptionAsHtml = event.descriptionAsHtml;
		if (event.details) this.details = event.details;
		if (event.image) this.image = event.image;
		if (event.icon) this.icon = event.icon;
		if (event.highlight) this.highlight = event.highlight;

		if (event.club) this.club = event.club;

		if (event.courses && event.courses.items) {
			event.courses.items.forEach((course) => {
				const updatedCourse = this.rootStore.courseStore.updateCourseFromServer(
					course,
					this,
				);
				const isNew = !this.getCourseById(course.id);
				if (isNew) this.courses.push(updatedCourse);
			});
		}

		if (event.sponsors) this.sponsors = event.sponsors;

		return this;
	}

	getCourseById = (id) => this.courses.find((course) => course.id === id);

	removeFromStore() {
		if (this.club && this.club.id) {
			const club = this.rootStore.clubStore.getClubById(this.club.id);
			if (club) club.removeEventFromClub(this.id);
		}
		this.rootStore.eventStore.removeEventFromStore(this.id);
	}
}
