import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../mobX/RootStore";
import { observer } from "mobx-react";
import makeStyles from "@mui/styles/makeStyles";
import {
	decodeWidgetURI,
	getFilteredOfferings,
	getSchedulesToDisplay,
	displayTargetGroup,
	getLinkToDetailsForOffering,
} from "../../utils/Utils";
import Button from "components/CustomButtons/Button";
import { Link } from "react-router-dom";
import OfferingCard from "customComponents/OfferingCard/OfferingCard";
import { Helmet } from "react-helmet";

const styles = () => ({
	transparent: {
		background: "transparent",
	},
	gridContainer: {
		display: "grid",
		gap: "20px",
		gridTemplateColumns: "repeat(auto-fit, minmax(210px, 1fr))",
	},
	title: {
		margin: 0,
		fontWeight: "500",
	},
	description: {
		margin: 0,
		whiteSpace: "pre-line",
	},
	schedules: {
		margin: 0,
	},
	targetGroup: {
		marginBottom: 5,
	},
	offeringWrapper: {
		marginBottom: 30,
	},
	detailsButton: {
		width: 90,
	},
});

const useStyles = makeStyles(styles);

const Widget = observer(() => {
	const store = useContext(RootStoreContext);
	const {
		tenantStore: { data: tenant },
	} = store;
	const clubStore = store.clubStore;
	const [filter, setFilter] = useState({
		targetGroup: {
			from: 0,
			to: 99,
			male: true,
			female: true,
		},
		schedules: [],
		clubID: "",
		category: "",
	});
	const [doUseComplexDesign, setDoUseComplexDesign] = useState(false);
	const [styles, setStyles] = useState({});

	const [club, setClub] = useState();

	useEffect(() => {
		const uriParams = decodeWidgetURI(window.location.href);
		setFilter({
			targetGroup: uriParams.targetGroup,
			schedules: uriParams.schedules,
			clubID: uriParams.clubID,
			category: uriParams.category,
		});
		setStyles({
			color: uriParams.color,
			fontFamily: uriParams.fontFamily,
		});
		if (uriParams.clubID) {
			clubStore.fetchClub(uriParams.clubID);
		}
		setDoUseComplexDesign(uriParams.doUseComplexDesign);
	}, []);

	useEffect(() => {
		if (!clubStore.isLoading && filter.clubID) {
			const club = clubStore.getClubById(filter.clubID);
			setClub(club);
		}
	}, [clubStore.isLoading]);

	const classes = useStyles();
	document.body.classList.add(classes.transparent);

	if (doUseComplexDesign) {
		return (
			<div
				style={{
					backgroundColor: "transparent",
					paddingLeft: 10,
					color: styles.color || "black",
					fontFamily: styles.fontFamily || "Fira Sans",
				}}
			>
				<div className={classes.gridContainer}>
					{club &&
						club.offerings &&
						getFilteredOfferings(filter, club.offerings)
							.sort((a, b) => a.title.localeCompare(b.title))
							.map((offering) => {
								return (
									<>
										<OfferingCard offering={offering} target="_blank" />
									</>
								);
							})}
				</div>
			</div>
		);
	}
	return (
		<div
			style={{
				paddingLeft: 10,
				color: "black",
				fontWeight: "normal",
				fontFamily: styles.fontFamily || "Fira Sans",
			}}
		>
			{styles.fontFamily && (
				<Helmet>
					<link
						href={`https://fonts.googleapis.com/css?family=${styles.fontFamily}`}
						rel="stylesheet"
					/>
				</Helmet>
			)}
			{club &&
				club.offerings &&
				getFilteredOfferings(filter, club.offerings)
					.sort((a, b) => a.title.localeCompare(b.title))
					.map((offering) => {
						return (
							<div key={offering.id} className={classes.offeringWrapper}>
								<h3
									className={classes.title}
									style={{
										fontFamily: styles.fontFamily || "Fira Sans",
										color: styles.color || "black",
									}}
								>
									{offering.title}
								</h3>
								<p className={classes.schedules}>
									{getSchedulesToDisplay(offering.schedules)}
								</p>
								<p className={classes.targetGroup}>
									{displayTargetGroup(offering.targetGroup)}
								</p>
								<div
									dangerouslySetInnerHTML={{
										__html: offering.descriptionAsHtml,
									}}
								/>
								<Link
									target="_blank"
									key={`nextButton-${offering.id}`}
									to={getLinkToDetailsForOffering(offering, tenant.path)}
								>
									<Button color="primary" className={classes.detailsButton}>
										Details
									</Button>
								</Link>
							</div>
						);
					})}
		</div>
	);
});

export default Widget;
