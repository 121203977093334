import React from "react";
import "./style.css";

const Imprint = () => (
	<div className="imprint">
		<h3>Impressum</h3>
		<p>Die nachstehenden Anbieterkennzeichnung ist gültig für:</p>
		<ul>
			<li>
				<p>Den Sportapp-Manager auf https://sportapp.solingersport.de</p>
			</li>
			<li>
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				<p>Die Android und iOS App "Solingen Sportapp"</p>
			</li>
		</ul>
		<h3>Angaben gemäß § 5 TMG</h3>
		<p>
			Solinger Sportbund e.V.
			<br />
			Am Neumarkt 27
			<br />
			42651 Solingen
		</p>
		<p>Vereinsregister: VR 25837</p>
		<p>Vertretungsberechtigt nach §26 BGB:</p>
		<ul>
			<li>
				<p>Karen Leidig</p>
			</li>
			<li>
				<p>Christopher Winter</p>
			</li>
		</ul>
		<h3 className="contact">Kontakt</h3>
		<p>
			Geschäftsstelle des Solinger Sportbund e.V.
			<br />
			Am Neumarkt 27
			<br />
			42651 Solingen
			<br />
			Telefon (0212) 202229
			<br />
			E-Mail: info@solingersport.de
		</p>
		<p>Redaktionelle Verantwortung nach § 18 Abs. 2 MstV</p>
		<ul>
			<li>
				<p>Karen Leiding</p>
			</li>
			<li>
				<p>Christopher Winter</p>
			</li>
		</ul>
	</div>
);

export default Imprint;
