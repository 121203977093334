import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// core components
import Button from "components/CustomButtons/Button";

import { ReactComponent as AppstoreLogo } from "../../assets/img/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle";

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => (
	<Slide direction="down" ref={ref} {...props} />
));

const dialogOpenDelay = 4000;

const InstallAppDialog = ({ classes }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const hideInstallDialog = localStorage.getItem("hideInstallDialog");
		const storedPostponeDateString = localStorage.getItem(
			"installDialogPostponeDate",
		);
		if (!hideInstallDialog) {
			setTimeout(() => {
				setOpen(true);
			}, dialogOpenDelay);
		} else if (storedPostponeDateString) {
			const currentDate = new Date();
			const storedPostponeDate = new Date(storedPostponeDateString);
			const timeDifference = currentDate - storedPostponeDate;
			const hoursDifference = timeDifference / (1000 * 60 * 60);
			if (hoursDifference >= 72) {
				setTimeout(() => {
					setOpen(true);
					localStorage.removeItem("hideInstallDialog");
					localStorage.removeItem("installDialogPostponeDate");
				}, dialogOpenDelay);
			}
		}
	}, []);

	const handleClose = () => {
		localStorage.setItem("hideInstallDialog", "true");
		setOpen(false);
	};

	const handlePostpone = () => {
		const currentDateString = new Date().toISOString();
		localStorage.setItem("installDialogPostponeDate", currentDateString);
		localStorage.setItem("hideInstallDialog", "true");
		setOpen(false);
	};

	return (
		<div>
			{open && (
				<Dialog
					classes={{
						root: classes.modalRoot,
						paper: classes.modal,
					}}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => handleClose()}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
					disableEscapeKeyDown
				>
					<DialogTitle
						id="classic-modal-slide-title"
						className={classes.modalHeader}
					>
						<IconButton
							aria-label="close"
							onClick={() => {
								handleClose();
							}}
							style={{ float: "right" }}
							size="large"
						>
							<CloseIcon />
						</IconButton>
						<h4 className={classes.modalTitle} style={{ fontWeight: 500 }}>
							Die App gibt es auch im Store!
						</h4>
					</DialogTitle>
					<DialogContent
						id="classic-modal-slide-description"
						className={classes.modalBody}
						style={{ paddingTop: 0 }}
					>
						Hol dir jetzt die Sportapp im App- oder Playstore!
					</DialogContent>
					<DialogActions
						className={classes.modalFooter}
						style={{ flexWrap: "wrap", justifyContent: "center" }}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								flexBasis: "100%",
								columnGap: 10,
							}}
						>
							<a href="http://play.google.com/store/apps/details?id=de.codecentric.labs.solingersportbund&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
								<img
									style={{
										width: 130,
									}}
									alt="Jetzt bei Google Play"
									src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
								/>
							</a>
							<a
								href="https://apps.apple.com/us/app/solingen-sportapp/id1459788664"
								style={{
									width: 130,
									marginTop: "auto",
									position: "relative",
									top: 1,
								}}
							>
								<AppstoreLogo />
							</a>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								flexBasis: "100%",
								marginLeft: 0,
							}}
						>
							<div style={{ marginTop: "1em" }}>
								<Button
									color="primary"
									onClick={() => {
										handlePostpone();
									}}
								>
									Erinnere mich später
								</Button>
							</div>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

InstallAppDialog.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(modalStyle)(InstallAppDialog);
