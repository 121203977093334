// @mui/material customComponents
import makeStyles from "@mui/styles/makeStyles";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";

export default makeStyles({
	...profilePageStyle,
	imgWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	locationIcon: {
		margin: "auto",
		marginTop: 10,
		fontSize: 35,
	},
	offeringList: {
		display: "flex",
		flexWrap: "wrap",
	},
	homepageUrl: {
		...profilePageStyle.block,
		padding: 0,
		marginBottom: 20,
	},
	subHeaderIcon: {
		marginTop: 30,
		fontSize: 30,
	},
	subSectionContent: {
		display: "flex",
		flexDirection: "column",
	},
	listItem: {
		display: "flex",
		flexDirection: "row",
	},
	listItemLeft: {
		width: 100,
	},
	actionChipContent: {
		color: "black",
		display: "flex",
		flexDirection: "row",
		"& p": {
			margin: 0,
		},
	},
	actionChipsWrapper: {
		display: "flex",
		flexDirection: "row",
	},
	description: {
		...profilePageStyle.description,
		whiteSpace: "pre-line",
		maxWidth: "auto",
	},
});
