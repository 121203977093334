import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import PropTypes from "prop-types";

const CustomInputAdapter = ({ input, meta, inputProps, ...rest }) => (
	<CustomInput
		labelText={
			meta.error && meta.touched ? `${rest.label} (${meta.error})` : rest.label
		}
		inputProps={{ ...inputProps, ...input }}
		formControlProps={{
			fullWidth: true,
		}}
		{...rest}
		error={meta.touched && !!meta.error}
	/>
);

CustomInputAdapter.propTypes = {
	input: PropTypes.object,
	inputProps: PropTypes.object,
	meta: PropTypes.object,
	rest: PropTypes.object,
	club: PropTypes.object,
};

export default CustomInputAdapter;
